import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TUserState = {
  id?: string;
  name?: string;
  isLoggedIn?: boolean;
  status?: string;
  firstName?: string;
  lastname?: string;
  motherTongue?: string;
  nationality?: string;
  height?: number;
  education?: string;
  gender?: string;
  dob?: string;
  stateName?: string;
  city?: string;
  photo?: string;
  bio?: string;
  interest?: string;
  job?: string;
};

const initialState: TUserState = {
  id: "",
  name: "",
  isLoggedIn: false,
  status: "",
  firstName: "",
  lastname: "",
  motherTongue: "",
  nationality: "",
  height: 0,
  education: "",
  gender: "",
  dob: "",
  stateName: "",
  city: "",
  photo: "",
  bio: "",
  interest: "",
  job: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TUserState>) => {
      state.isLoggedIn = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.status = action.payload.status;
      state.firstName = action.payload.firstName;
      state.lastname = action.payload.lastname;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      return { ...state, status: action.payload };
    },
    setProfileData: (state, action: PayloadAction<TUserState>) => {
      return {
        ...state,
        motherTongue: action.payload.motherTongue,
        nationality: action.payload.nationality,
        height: action.payload.height,
        education: action.payload.education,
        gender: action.payload.gender,
        dob: action.payload.dob,
        city: action.payload.city,
        stateName: action.payload.stateName,
        photo: action.payload.photo,
        job: action.payload.job,
        interest: action.payload.interest,
        bio: action.payload.bio,
      };
    },
    setPersonalData: (state, action: PayloadAction<TUserState>) => {
      console.log("login state", {
        ...state,
        motherTongue: action.payload.motherTongue,
        nationality: action.payload.nationality,
        height: action.payload.height,
        gender: action.payload.gender,
        dob: action.payload.dob,
      });
      return {
        ...state,
        motherTongue: action.payload.motherTongue,
        nationality: action.payload.nationality,
        height: action.payload.height,
        gender: action.payload.gender,
        dob: action.payload.dob,
      };
    },
    setAddressData: (state, action: PayloadAction<TUserState>) => {
      return {
        ...state,
        city: action.payload.city,
        stateName: action.payload.stateName,
      };
    },

    setBio: (state, action: PayloadAction<TUserState>) => {
      return {
        ...state,
        bio: action.payload.bio,
      };
    },

    setInterestData: (state, action: PayloadAction<TUserState>) => {
      return {
        ...state,
        interest: action.payload.interest,
      };
    },
    setJob: (state, action: PayloadAction<TUserState>) => {
      return {
        ...state,
        job: action.payload.job,
      };
    },
    Logout: (state: TUserState) => initialState,
  },
});

export const {
  setUser,
  setStatus,
  setBio,
  setInterestData,
  setJob,
  Logout,
  setProfileData,
  setPersonalData,
  setAddressData,
} = userSlice.actions;
export default userSlice.reducer;
