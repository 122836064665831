import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { format } from "timeago.js";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import { db } from "../../../services/firebase";
import Loader from "../../accounts/Loader";
import { useUserStore } from "../../../state/firebase/userFirehoseStore";
import { useChatStore } from "../../../state/firebase/chatFirehoseStore";
import avatar from "../../../assets/images/avatar.png";
import sendChat from "../../../../src/assets/images/SendSVG.svg";
import laughingEmoji from "../../../../src/assets/images/laughing.png";
import optionDotsSVG from "../../../../src/assets/images/optionDotsSVG.svg";
import recivedTickChatSVG from "../../../../src/assets/images/recivedTickChatSVG.svg";
import "./chat.css";

type TMessage = {
  senderId: string;
  text: string;
  createdAt: Date;
};

type TChatData = {
  messages: TMessage[];
};

interface ChildProps {
  setIsLoading: (value: boolean | ((prev: boolean) => boolean)) => void;
  isLoading: boolean;
}

const Chat: React.FC<ChildProps> = ({ setIsLoading, isLoading }) => {
  const { id } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.firebase);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const navigate = useNavigate();
  const [chat, setChat] = useState<TChatData>();
  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const { chatId, user, userInfo, changeBlock } = useChatStore();
  const [currentBlock, setCurrentBlock] = useState(false);
  const [receiverBlock, setReceiverBlock] = useState(false);
  const endRef = useRef<HTMLDivElement>(null);

  const fetchBlockUser = async () => {
    if (user?.username) {
      try {
        const res = await client.post(`/user/block/chat`, {
          UserId: id,
          OtherUserId: user.username,
        });
        // console.log("blocked result", res.data.result);
        if (res.data.result) {
          id === res.data.result.BlockUserId
            ? setCurrentBlock(true)
            : setReceiverBlock(true);
        } else {
          setCurrentBlock(false);
          setReceiverBlock(false);
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    fetchBlockUser();
  }, []);

  // useEffect(() => {
  //   endRef.current?.scrollIntoView({ behavior: "smooth" });
  //   setText("");
  // }, [chat?.messages]);

  useEffect(() => {
    if (chatId) {
      const chatDocRef = doc(db, "chats", chatId);
      const unSub = onSnapshot(chatDocRef, (snapshot) => {
        if (snapshot.exists()) {
          setChat(snapshot.data() as TChatData);
          setIsLoading(false);
        }
      });

      return () => {
        unSub();
      };
    }
  }, [chatId]);

  const handleEmoji = (e: { emoji: string }) => {
    setText((prev) => prev + e.emoji);
  };

  const handleSend = async () => {
    if (text === "") return;
    try {
      await updateDoc(doc(db, "chats", chatId ?? ""), {
        messages: arrayUnion({
          senderId: currentUser.user.uid,
          text,
          createdAt: new Date(),
        }),
      });

      const userIDs = [currentUser.user.uid, user?.id];
      userIDs.forEach(async (id) => {
        if (!id) return;
        const userChatsRef = doc(db, "userchats", id);
        const userChatsSnapshot = await getDoc(userChatsRef);

        if (userChatsSnapshot.exists()) {
          const userChatsData = userChatsSnapshot.data();

          const chatIndex = userChatsData.chats.findIndex(
            (c: { chatId: string }) => c.chatId === chatId,
          );

          userChatsData.chats[chatIndex].lastMessage = text;
          userChatsData.chats[chatIndex].isSeen =
            id === currentUser.user.uid ? true : false;
          userChatsData.chats[chatIndex].updatedAt = Date.now();

          await updateDoc(userChatsRef, {
            chats: userChatsData.chats,
          });
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setText("");
    }
  };

  const [formattedDates, setFormattedDates] = useState<string[]>([]);

  useEffect(() => {
    const formatMessageDate = (createdAt: any): string => {
      return format(createdAt.toDate().toISOString());
    };

    // Map through messages to format dates
    const interval = setInterval(() => {
      const formatted = chat?.messages?.map((message) =>
        formatMessageDate(message.createdAt),
      );
      setFormattedDates(formatted!);
    }, 10000); // Update every 10 seconds

    // Cleanup function to clear interval
    return () => clearInterval(interval);
  }, [chat?.messages]);

  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleToggleContent = () => {
    setIsContentVisible((prev) => !prev);
  };

  // Block/Unblock the User
  const handleBlock = async () => {
    setIsContentVisible(false);
    if (!user || currentBlock) return;
    try {
      const blockUrl = receiverBlock ? "/user/unblock" : "/user/block";
      const res = await client.post(blockUrl, {
        UserId: id,
        BlockUserId: user.username,
      });
      if (true) {
        changeBlock();
        receiverBlock ? setReceiverBlock(false) : setReceiverBlock(true);
      }
    } catch (err) {}
  };

  const openReport = () => {
    navigate("/profile/report-user", {
      state: {
        id: user?.username,
      },
    });
  };

  const openFullProfile = () => {
    navigate("/profile/profile-details", {
      state: {
        data: {
          _id: user?.username,
        },
      },
    });
  };

  if (isLoading)
    return (
      <div className="chatLoader">
        <Loader />
      </div>
    );

  return (
    <div className="chat">
      <div className="top">
        <div className="user">
          <img src={userInfo?.ProfilePic || avatar} alt="" />
          <div className="texts" onClick={openFullProfile}>
            <span>{userInfo?.FullName}</span>
            {/* <p> <span className="onlineIndicator"> </span> Online </p> */}
          </div>
        </div>
        <div className="optionDots">
          <img src={optionDotsSVG} alt="" onClick={handleToggleContent} />
          {isContentVisible && (
            <div className="optionBox">
              <ul>
                <li onClick={openReport}> Report </li>
                <li onClick={handleBlock}>
                  {" "}
                  {currentBlock
                    ? "You are Blocked!"
                    : receiverBlock
                      ? "Unblock"
                      : "Block"}{" "}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="center">
        {chat?.messages?.map((message, index) => (
          <div
            className={
              message.senderId === currentUser?.user.uid
                ? "message own"
                : "message"
            }
            key={`message_${index}_${Math.floor(Math.random() * 10000)}`}
          >
            <div className="texts">
              <p>{message.text}</p>
              {/* Display formatted date */}
              <div className="dflex">
                <span> {formattedDates[index]} </span>
                <img src={recivedTickChatSVG} alt="" />
              </div>
            </div>
          </div>
        ))}
        <div ref={endRef}></div>
      </div>
      <div className="bottom">
        <input
          type="text"
          placeholder={
            currentBlock || receiverBlock
              ? "You cannot send a message"
              : "Type a message..."
          }
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={currentBlock || receiverBlock}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSend();
            }
          }}
        />
        {!(currentBlock || receiverBlock) && (
          <>
            <div className="emoji">
              <img
                src={laughingEmoji}
                alt=""
                onClick={() => setOpen((prev) => !prev)}
              />
              <div className="picker">
                <EmojiPicker open={open} onEmojiClick={handleEmoji} />
              </div>
            </div>

            <img
              className="sendButton"
              onClick={handleSend}
              src={sendChat}
              alt=""
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
