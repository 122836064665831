import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import * as constant from "../../../utils/constants";
import { TPersonalProfile, TProfileData } from "../../../types/register";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import {
  TCommunity,
  TMaritalStatus,
  TNationality,
  TReligion,
} from "../../../types/base";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  TUserState,
  setPersonalData,
} from "../../../state/features/loginSlice";

export type TBasicProfileInfo = {
  data: TProfileData;
};

export type TProfileMessage = {
  message: string;
  isError?: boolean;
};

const ProfileBasicInfo = ({ data }: TBasicProfileInfo) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const { id } = useSelector((state: RootState) => state.user);
  const [basicInfoData, setBasicInfoData] = useState<TPersonalProfile>(
    {} as TPersonalProfile,
  );
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  useEffect(() => {
    if (data) setBasicInfoData(data.Personal);
  }, [data.Personal]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateBasicInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        Personal: basicInfoData,
      });
      if (true) {
        const personalData: TUserState = {
          motherTongue: basicInfoData.Mothertongue,
          nationality: basicInfoData.Nationality,
          gender: basicInfoData.Gender,
          dob: basicInfoData.Dob,
          height: basicInfoData.Height,
        };
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        dispatch(setPersonalData(personalData));
        setIsEdit(false);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const validateBasicInfo = (): boolean => {
    if (basicInfoData.Weight <= 0) {
      setMsg({ message: "Invalid weight", isError: true });
      return false;
    }
    if (basicInfoData.Height <= 0) {
      setMsg({ message: "Invalid height", isError: true });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Basic Info </p>
          <div className="dflex alignItemCenter">
            {msg && msg.message && (
              <div className={msg.isError ? "error-msg" : "success-msg"}>
                <p>{msg.message}</p>
              </div>
            )}
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Date of birth </label>
                    <input
                      disabled
                      type="text"
                      placeholder={new Date(
                        basicInfoData?.Dob,
                      ).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    />
                  </div>

                  <div>
                    <label> Mother tongue </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={basicInfoData?.Mothertongue}
                      onChange={(e) =>
                        setBasicInfoData((prev) => ({
                          ...prev,
                          Mothertongue: e.target.value,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Mother Tongue*
                      </option>
                      {constant.MOTHER_TONGUE?.map((mothertongue) => (
                        <option key={mothertongue.id}>
                          {mothertongue.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label> Nationality </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={basicInfoData?.Nationality}
                      onChange={(e) =>
                        setBasicInfoData((prev) => ({
                          ...prev,
                          Nationality: e.target.value as TNationality,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Nationality*
                      </option>
                      {constant.NATIONALITIES?.map((nationality) => (
                        <option key={nationality.id}>
                          {nationality.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Weight (KG) </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      value={basicInfoData?.Weight}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          "",
                        );
                        if (!isNaN(+sanitizedValue)) {
                          setBasicInfoData((prev) => ({
                            ...prev,
                            Weight: +sanitizedValue,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div>
                    <label> Height (Inch) </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      value={basicInfoData?.Height}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          "",
                        );
                        if (!isNaN(+sanitizedValue)) {
                          setBasicInfoData((prev) => ({
                            ...prev,
                            Height: +sanitizedValue,
                          }));
                        }
                      }}
                    />
                  </div>

                  <div>
                    <label> Marital status </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={basicInfoData?.MaritalStatus}
                      onChange={(e) =>
                        setBasicInfoData((prev) => ({
                          ...prev,
                          MaritalStatus: e.target.value as TMaritalStatus,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Marital Status*
                      </option>
                      {constant.MARITAL_STATUSES?.map((maritalStatus) => (
                        <option key={maritalStatus.id}>
                          {maritalStatus.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Religion </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={basicInfoData?.Religion}
                      onChange={(e) =>
                        setBasicInfoData((prev) => ({
                          ...prev,
                          Religion: e.target.value as TReligion,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Religion*
                      </option>
                      {constant.RELIGIONS?.map((religion) => (
                        <option key={religion.id}>{religion.value}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label> Community </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={basicInfoData?.Community}
                      onChange={(e) =>
                        setBasicInfoData((prev) => ({
                          ...prev,
                          Community: e.target.value as TCommunity,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Community*
                      </option>
                      {constant.COMMUNITIES?.map((community) => (
                        <option key={community.id}>{community.value}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileBasicInfo;
