import Accordion from "react-bootstrap/Accordion";
import { TBasicProfileInfo } from "./ProfileBasicInfo";
import checkOk from "../../../../src/assets/images/checkOk.svg";

const ProfileContactInfo = ({ data }: TBasicProfileInfo) => {
  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Contact Info </p>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <div className="flex">
                      <label> Email id </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input disabled type="email" placeholder={data?.Email} />
                  </div>

                  <div>
                    <div className="flex">
                      <label> Mobile number </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled
                      type="text"
                      placeholder={data?.Personal?.ContactNumber.toString()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileContactInfo;
