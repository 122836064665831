import { create } from "zustand";
import { useUserStore } from "../firebase/userFirehoseStore";
type TUser = {
  id: string;
  avatar: string;
  username: string;
  FullName: string;
  ProfilePic: string;
};

type TChatState = {
  chatId: string | null;
  user: TUser | null;
  userInfo: TUser | null;
  isCurrentUserBlocked: boolean;
  isReceiverBlocked: boolean;
  changeChat: (chatId: string, user: any, userInfo: any) => void;
  changeBlock: () => void;
  resetChat: () => void;
};

export const useChatStore = create<TChatState>((set) => ({
  chatId: null,
  user: null,
  userInfo: null,
  isCurrentUserBlocked: false,
  isReceiverBlocked: false,
  changeChat: (chatId: string, user: any, userInfo: any) => {
    const currentUser = useUserStore.getState().currentUser;

    // CHECK IF CURRENT USER IS BLOCKED
    if (user.blocked.includes(currentUser.id)) {
      set({
        chatId,
        user: null,
        userInfo: userInfo,
        isCurrentUserBlocked: true,
        isReceiverBlocked: false,
      });
    }

    // CHECK IF RECEIVER IS BLOCKED
    else if (currentUser.blocked.includes(user.id)) {
      set({
        chatId,
        user: user,
        userInfo: userInfo,
        isCurrentUserBlocked: false,
        isReceiverBlocked: true,
      });
    } else {
      set({
        chatId,
        user,
        userInfo: userInfo,
        isCurrentUserBlocked: false,
        isReceiverBlocked: false,
      });
    }
  },

  changeBlock: () => {
    set((state) => ({ ...state, isReceiverBlocked: !state.isReceiverBlocked }));
  },
  resetChat: () => {
    set({
      chatId: null,
      user: null,
      userInfo: null,
      isCurrentUserBlocked: false,
      isReceiverBlocked: false,
    });
  },
}));
