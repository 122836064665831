import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TRegisterInfo } from "../../../types/register";
import { useRegistrationState } from "./RegistrationProvider";
import * as constant from "../../../utils/constants";
import axiosClient from "../../../services/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { RegistrainHeader } from "./RegistrationHeader";
import { numberInputOnWheelPreventChange } from "../../../utils/helper";
import Loader from "../Loader";
import { TOtpMsg } from "./PersonalForm";
import { useLoader } from "../../Root";
import Captcha from "../../Captcha";

export default function FamilyForm() {
  const [isVerified, setIsVerified] = useState(false);
  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(
    process.env.NODE_ENV === "development",
  );
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOTP] = useState<string>();
  const [otpSent, SetOtpSent] = useState<TOtpMsg | null>();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [err, setErr] = useState<string | null>();
  const { email } = useSelector((state: RootState) => state.register);
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [isNumberEntered, setIsNumberEntered] = useState(true);
  const { onHandleBack, onHandleNext, setRegistrationData, registrationData } =
    useRegistrationState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<TRegisterInfo>({
    defaultValues: registrationData,
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    const verified = !err && getValues("family.guardianContact") > 0;
    setIsVerified(verified);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onResendOTP = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsVerified(false);
    setIsNumberEntered(true);
  };

  const onHandleFormSubmit = async (data: TRegisterInfo) => {
    try {
      if (isVerified) {
        setRegistrationData((prevData) => ({
          ...prevData,
          ...data,
        }));
        onHandleNext();
        return;
      }
      setIsLoading(true);
      const res = await client.post("/verifyOTP", {
        Email: email,
        OTP: otp,
        Type: "Guardian",
      });
      if (true) {
        setIsVerified(true);
        SetOtpSent(null);
        setRegistrationData((prevData) => ({
          ...prevData,
          ...data,
        }));
        onHandleNext();
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyContact = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const number = 91 + getValues("family.guardianContact");

    try {
      setIsLoading(true);
      const res = await client.post("/sendOTP", {
        Email: email,
        Number: number,
        Type: "Guardian",
      });
      if (true) {
        SetOtpSent({ isErr: false, Message: "OTP sent successfully" });
        setIsNumberEntered(false);
        setTimeout(() => {
          SetOtpSent(null);
        }, 5000);
      }
    } catch (err: any) {
      if (err.response) {
        SetOtpSent({ isErr: true, Message: err.response.data?.error });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="familyInformationRegistration">
      <div className="family">
        <RegistrainHeader />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul id="progressbar">
                <li className="active" id="account">
                  <i className="fa fa-heart complete"></i>
                </li>
                <li id="personal">
                  <i className="fa fa-heart"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="detail-title">
              <div className="reg-icon">
                <img
                  src={require("../../../assets/images/family.png")}
                  alt="user"
                />
              </div>
              <div className="prsl-detail-heading">Family Information</div>
            </div>

            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div className="">
                  <div className="prsl-detail">
                    <form
                      id="msform"
                      onSubmit={handleSubmit(onHandleFormSubmit)}
                    >
                      <fieldset>
                        <div className="form-card">
                          <div className="reg-form">
                            <div className="prsl-detail">
                              <div>
                                <select
                                  className="dropdown-menu"
                                  defaultValue=""
                                  {...register("family.familyType", {
                                    required: "Required*",
                                  })}
                                >
                                  <option value="" disabled>
                                    Family Type
                                  </option>
                                  {constant.FAMILYTYPES.map((familytype) => (
                                    <option key={familytype.id}>
                                      {familytype.value}
                                    </option>
                                  ))}
                                </select>
                                {errors.family?.familyType && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.family.familyType.message}
                                  </div>
                                )}
                              </div>
                              <div className="dflex">
                                <div>
                                  <input
                                    {...register("family.fatherStatus", {
                                      required: "Required*",
                                    })}
                                    id="fatherstatus"
                                    type="text"
                                    style={{ textTransform: "capitalize" }}
                                    placeholder="Father Status*"
                                  />
                                  {errors.family?.fatherStatus && (
                                    <div className="error-msg">
                                      <i className="fa fa-exclamation-triangle"></i>
                                      {errors.family?.fatherStatus.message}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <input
                                    {...register("family.motherStatus", {
                                      required: "Required*",
                                    })}
                                    id="motherstatus"
                                    type="text"
                                    style={{ textTransform: "capitalize" }}
                                    placeholder="Mother Status*"
                                  />
                                  {errors.family?.motherStatus && (
                                    <div className="error-msg">
                                      <i className="fa fa-exclamation-triangle"></i>
                                      {errors.family?.motherStatus.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="dflex">
                                <div>
                                  <input
                                    {...register("family.totalBrothers", {
                                      valueAsNumber: false,
                                      required: "Required*",
                                    })}
                                    id="totalbrothers"
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    placeholder="No. of brothers*"
                                  />
                                  {errors.family?.totalBrothers && (
                                    <div className="error-msg">
                                      <i className="fa fa-exclamation-triangle"></i>
                                      {errors.family?.totalBrothers.message}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <input
                                    {...register("family.totalSisters", {
                                      valueAsNumber: false,
                                      required: "Required",
                                    })}
                                    id="totalsisters"
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    placeholder="No. of sisters*"
                                  />
                                  {errors.family?.totalSisters && (
                                    <div className="error-msg">
                                      <i className="fa fa-exclamation-triangle"></i>
                                      {errors.family?.totalSisters.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="Verify_link">
                                <div>
                                  {isNumberEntered && (
                                    <>
                                      <input
                                        {...register("family.guardianContact", {
                                          required: "Required*",
                                          pattern: {
                                            value: /^[6-9]\d{9}$/,
                                            message: "Invalid number",
                                          },
                                          validate: (value) => {
                                            if (
                                              value ===
                                              registrationData.personal
                                                .contactNumber
                                            ) {
                                              setIsVerifyVisible(false);
                                              return "Number already used as personal number";
                                            }
                                            return true;
                                          },
                                        })}
                                        id="guardian"
                                        type="number"
                                        onChange={(e) =>
                                          setIsVerifyVisible(
                                            +e.target.value.length === 10,
                                          )
                                        }
                                        disabled={isVerified}
                                        onWheel={
                                          numberInputOnWheelPreventChange
                                        }
                                        placeholder="Contact number of parent / guardian*"
                                      />
                                      {isVerifyVisible && (
                                        <span>
                                          <button
                                            onClick={verifyContact}
                                            className="btn-trans"
                                            type="button"
                                          >
                                            {isVerified ? "Verified" : "Verify"}
                                          </button>
                                        </span>
                                      )}
                                    </>
                                  )}

                                  {otpSent && (
                                    <div
                                      className={
                                        otpSent.isErr
                                          ? "error-msg"
                                          : "success-msg"
                                      }
                                    >
                                      {otpSent.Message}
                                    </div>
                                  )}
                                  {errors.family?.guardianContact && (
                                    <div className="error-msg">
                                      <i className="fa fa-exclamation-triangle"></i>
                                      {errors.family?.guardianContact.message}
                                    </div>
                                  )}
                                </div>
                                {process.env.NODE_ENV !== "development" && (
                                  <Captcha
                                    setIsCaptchaSuccess={setIsCaptchaSuccess}
                                  />
                                )}
                              </div>
                              <div>
                                {!isVerified && (
                                  <div>
                                    <input
                                      onChange={(e) => setOTP(e.target.value)}
                                      value={otp}
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      placeholder="Enter OTP sent to your parent / guardian number*"
                                    />
                                    {err && (
                                      <div className="error-msg">
                                        <i className="fa fa-exclamation-triangle"></i>
                                        {err}
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div>
                                  {!isNumberEntered && (
                                    <div className="viewBtnDiv">
                                      <button
                                        className="btn-trans"
                                        onClick={onResendOTP}
                                      >
                                        Resend OTP
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="reg-forms">
                          <div className="divider"></div>
                          <div className="btn-flex">
                            <div className="left-btn">
                              {isNumberEntered && (
                                <button
                                  className="btn-trans"
                                  type="button"
                                  onClick={onHandleBack}
                                >
                                  <i className="fa-solid fa-arrow-left"></i>{" "}
                                  Back
                                </button>
                              )}
                            </div>
                            {isCaptchaSuccess && (
                              <button
                                disabled={isSubmitting}
                                type="submit"
                                className="cont-btn commonBtn"
                              >
                                Continue
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
}
