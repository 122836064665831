import { useEffect } from "react";
import axiosClient from "../../services/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const useFetchUserLikesEffect = (
  videos: any[],
  currentVideoIndex: number,
  id: string,
  setLikeClass: (value: string) => void,
  setLikeCount: (value: number) => void,
) => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  useEffect(() => {
    const fetchLikes = async () => {
      const klickId = videos && videos[currentVideoIndex]?._id;
      if (!klickId) return;
      try {
        const res = await client.post(`/klick/userLike`, {
          KlickId: klickId,
          UserId: id,
        });
        if (true) {
          res.data.likeStatus
            ? setLikeClass("fas fa-heart")
            : setLikeClass("far fa-heart");
          setLikeCount(res.data.count);
        }
      } catch (err: any) {
        console.error("Error fetching likes:", err);
      }
    };
    fetchLikes();
  }, [videos, currentVideoIndex, id, setLikeClass, setLikeCount]);
};

export default useFetchUserLikesEffect;
