import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TAuthSlice = {
  token: string;
};

const initialState: TAuthSlice = {
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<TAuthSlice>) => {
      return { ...state, ...action.payload };
    },
    resetAuth: () => initialState,
  },
});

export const { setAuth, resetAuth } = authSlice.actions;
export default authSlice.reducer;
