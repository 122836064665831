import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export type TMusic = {
  isMusicPlaying: boolean;
};
const initialState: TMusic = {
  isMusicPlaying: false,
};
const MusicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setMusicState: (state, action: PayloadAction<TMusic>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMusicState } = MusicSlice.actions;
export default MusicSlice.reducer;
