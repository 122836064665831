import { useEffect } from "react";

const useArrowKeyEffect = (
  handleScroll: (e: KeyboardEvent) => void,
  currentVideoIndex: number,
  videos: any[],
) => {
  useEffect(() => {
    document.addEventListener("keydown", handleScroll);
    return () => {
      document.removeEventListener("keydown", handleScroll);
    };
  }, [currentVideoIndex, videos, handleScroll]);
};

export default useArrowKeyEffect;
