import Modal from "react-modal";
import "./messageBox.css";
import deleteButton from "../../../../assets/images/deleteButton.png";

interface MessageModalProps {
  isOpen: boolean;
  message: string;
  onRequestClose: () => void;
  handleOK: () => void;
}

const MessageBoxModal = ({
  isOpen,
  message,
  onRequestClose,
  handleOK,
}: MessageModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
      portalClassName="modalClass"
    >
      <img src={deleteButton} alt="" />
      <p className="Modal_heading"> {message} </p>
      <div className="Modal_Desc">
        <p> Deleting your profiles will remove all history.</p>{" "}
        <p> you cannot undo this.</p>
      </div>
      <button className="modal-button commonBtn" onClick={handleOK}>
        Delete My Profile
      </button>

      <button className="modal-button cancleBtn" onClick={onRequestClose}>
        Cancel
      </button>
    </Modal>
  );
};

export default MessageBoxModal;
