import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import Timer from "./Modals/Timer";

type TVideoRecorderProps = {
  onStop: (blob: Blob) => void;
  onRequestClose: () => void;
};

const VideoRecorder = ({ onStop, onRequestClose }: TVideoRecorderProps) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [is20SecRecorded, setIs20SecRecorded] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (isRecording) {
      let timer = setTimeout(() => {
        setIs20SecRecorded(true);
      }, 10 * 1000);
      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const startRecording = () => {
    if (!isEnabled) return;
    if (webcamRef.current) {
      setIs20SecRecorded(false);
      const stream = webcamRef.current.video!.srcObject as MediaStream;
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "video/webm;codecs=vp9,opus",
      });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: "video/webm" });
          onStop(blob);
        }
      };

      mediaRecorder.onstop = () => {
        setIsRecording(false);
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <div className="videoRecorderBox">
      <p className="prsl-detail-heading">Give Your 30 Seconds Introduction</p>
      <Webcam
        height="auto"
        width={700}
        audio={true}
        muted={true}
        ref={webcamRef}
        onUserMedia={(e) => setIsEnabled(true)}
        mirrored
      />

      <div className="videoRecorderFooter">
        <div className="videoinerbox">
          {/* <div>For Verification Purpose Only*</div> */}
          {!isRecording && <p>Record your 20 to 30 second introduction</p>}
          {!isRecording && (
            <>
              <button onClick={startRecording}>Start</button>
              <button onClick={onRequestClose}>Close</button>
            </>
          )}
          {isRecording && <Timer seconds={29} onComplete={stopRecording} />}
          {is20SecRecorded && isRecording && (
            <button onClick={stopRecording}>Stop</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoRecorder;
