import { Helmet } from "react-helmet-async";
import "./MySubscription.css";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useEffect, useState } from "react";
import axiosClient from "../../services/axiosInstance";
import { daysToReadableFormat } from "../../utils/helper";
import { randomString } from "../../utils/helper";
import NoSubsciption from "../subscription/NoSubsciption";
import Loader from "../accounts/Loader";
const fmt = require("indian-number-format");

export type TSubscribedPackageInfo = {
  _id: string;
  OrderId: string;
  CouponCode: string;
  OriginalAmount: number;
  Amount: number;
  CreatedAt: string;
  ExpireAt: string;
  Details: {
    Name: string;
    RequestPerDay: number;
    Validity: number;
    Description: [];
  };
};

const MySubscription = () => {
  const [isloading, setIsLoading] = useState(false);
  const { packageName } = useSelector((state: RootState) => state.package);
  const { id } = useSelector((state: RootState) => state.user);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [pkgInfo, setPkgInfo] = useState<TSubscribedPackageInfo>(
    {} as TSubscribedPackageInfo,
  );

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const res = await client.get(`/user/package/${id}`);
        if (true) {
          setPkgInfo(res.data.result[0]);
        }
      } catch (err: any) {
        console.log("error while fetching package info");
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  const downloadInvoice = async () => {
    setIsLoading(true);
    try {
      const res = await client.get(`/payment/transaction/${pkgInfo._id}/pdf`, {
        responseType: "blob",
      });
      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    } catch (err: any) {
      console.error("error while download invoice", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!packageName) return <NoSubsciption />;
  if (isloading) return <Loader />;
  return (
    <div className="mysubscription">
      <Helmet>
        <title>My Subscription | Premmilan</title>
      </Helmet>
      <div className="col-md-7">
        <div className="row">
          <div className="col-md-6">
            <p className="tite"> Payment History </p>
            <div className="notificationCard">
              <ul>
                <li>
                  <p className="NotificationTitle">
                    Paid ₹{fmt.format(pkgInfo?.Amount)} |{" "}
                    {pkgInfo?.Details?.Name} Subscription{" "}
                  </p>
                  {/* <p className="DebitCreditCard"> Debit Card </p> */}
                  <p className="NotificationDate">
                    {" "}
                    {new Date(pkgInfo?.CreatedAt).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                  </p>
                  <p className="DownloadInvoice" onClick={downloadInvoice}>
                    {" "}
                    Download Invoice{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <p className="tite"> My Subscription </p>
            <div className="mySubsciptionBox">
              <div className="plansBx">
                <div className="planHaeading">
                  <p> {pkgInfo?.Details?.Name} </p>
                </div>
                <ul>
                  <li>
                    <div>
                      <img
                        className=""
                        src={require("../../assets/images/tick.png")}
                        alt="tickpng"
                      />
                    </div>
                    <p>
                      <span>
                        {" "}
                        {daysToReadableFormat(pkgInfo?.Details?.Validity)}{" "}
                      </span>
                    </p>
                  </li>
                  {pkgInfo?.Details?.Description.map(
                    (desc: any, key: number) => (
                      <li key={randomString()}>
                        <div>
                          <img
                            className=""
                            src={
                              desc?.Status
                                ? require("../../assets/images/tick.png")
                                : require("../../assets/images/cross.png")
                            }
                            alt="tickpng"
                          />
                        </div>
                        <p>
                          <span>{desc?.Label} </span>
                        </p>
                      </li>
                    ),
                  )}
                  <li>
                    <div>
                      <img
                        className=""
                        src={require("../../assets/images/tick.png")}
                        alt="tickpng"
                      />
                    </div>
                    <p>
                      <span>
                        Send up to {pkgInfo?.Details?.RequestPerDay} request/day{" "}
                      </span>
                    </p>
                  </li>
                  <li>
                    <div></div>
                    <br />
                    <div>
                      <p>
                        Will expired by{" "}
                        {new Date(pkgInfo?.ExpireAt).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          },
                        )}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySubscription;
