import { useForm } from "react-hook-form";
import { TRegisterInfo } from "../../../types/register";
import { useRegistrationState } from "./RegistrationProvider";
import * as constant from "../../../utils/constants";
import axiosClient from "../../../services/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useEffect, useState } from "react";
import { calculateAge } from "../../../utils/helper";
import { RegistrainHeader } from "./RegistrationHeader";
import { useNavigate } from "react-router-dom";
import { numberInputOnWheelPreventChange } from "../../../utils/helper";
import Loader from "../Loader";
import { useLoader } from "../../Root";
import Captcha from "../../Captcha";

export type TOtpMsg = {
  isErr: boolean | null;
  Message: string | null;
};

export default function PersonalForm() {
  const navigate = useNavigate();
  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(
    process.env.NODE_ENV === "development",
  );
  const { email } = useSelector((state: RootState) => state.register);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<string | null>();
  const [otpSent, SetOtpSent] = useState<TOtpMsg | null>();
  const [otp, setOTP] = useState<string>("");
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [isNumberEntered, setIsNumberEntered] = useState(true);

  const { onHandleNext, setRegistrationData, registrationData } =
    useRegistrationState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm<TRegisterInfo>({
    defaultValues: registrationData,
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const [isVerified, setIsVerified] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/profile", { replace: false });
      return;
    }

    if (!email) {
      navigate("/register", { replace: false });
      return;
    }

    const verified = !err && getValues("personal.contactNumber") > 0;
    setIsVerified(verified);
  }, []);

  const onHandleFormSubmit = async (data: TRegisterInfo) => {
    setRegistrationData((prevData) => ({
      ...prevData,
      ...data,
    }));

    try {
      if (isVerified) {
        onHandleNext();
        return;
      }
      setIsLoading(true);
      const res = await client.post("/verifyOTP", {
        Email: email,
        OTP: otp,
        Type: "Self",
      });
      if (true) {
        setIsVerified(true);
        setErr(null);
        SetOtpSent(null);
        onHandleNext();
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onResendOTP = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsVerified(false);
    setIsNumberEntered(true);
  };

  const verifyContact = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (isVerified) return;

    clearErrors("personal.contactNumber");
    const mobNum = getValues("personal.contactNumber");

    const number = 91 + mobNum;
    try {
      setIsLoading(true);
      const res = await client.post("/sendOTP", {
        Email: email,
        Number: number,
        Type: "Self",
      });

      if (true) {
        SetOtpSent({ isErr: false, Message: "OTP sent successfully" });
        setIsNumberEntered(false);
        setTimeout(() => {
          SetOtpSent(null);
        }, 5000);
      }
    } catch (err: any) {
      if (err.response) {
        SetOtpSent({ isErr: true, Message: err.response.data?.error });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="PersonalFormRegistraion">
      <RegistrainHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul id="progressbar">
              <li className="active" id="account">
                <i className="fa fa-heart complete"></i>
              </li>
              <li id="personal">
                <i className="fa fa-heart"></i>
              </li>
              <li id="payment">
                <i className="fa fa-heart"></i>
              </li>
              <li id="confirm">
                <i className="fa fa-heart"></i>
              </li>
              <li id="end">
                <i className="fa fa-heart"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-3"> </div>
          <div className="col-md-6">
            <div className="detail-title">
              <div className="reg-icon">
                <img
                  src={require("../../../assets/images/personal_info.png")}
                  alt="user"
                />
              </div>
              <div className="prsl-detail-heading">Personal Information</div>
            </div>

            <div className="row">
              <div className="col-md-1"> </div>
              <div className="col-md-11">
                <div className="registration">
                  <div className="reg-form">
                    <div className="prsl-detail">
                      <form
                        id="msform"
                        onSubmit={handleSubmit(onHandleFormSubmit)}
                      >
                        <fieldset>
                          <div className="form-card">
                            <div className="dflex">
                              <div>
                                <input
                                  {...register("personal.firstName", {
                                    required: "First name is required",
                                  })}
                                  style={{ textTransform: "capitalize" }}
                                  id="firstname"
                                  type="text"
                                  placeholder="First Name*"
                                />
                                {errors.personal?.firstName && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.firstName.message}
                                  </div>
                                )}
                              </div>
                              <div>
                                <input
                                  {...register("personal.lastName", {
                                    required: "Last name is required",
                                  })}
                                  style={{ textTransform: "capitalize" }}
                                  id="lastname"
                                  type="text"
                                  placeholder="Last Name*"
                                />
                                {errors.personal?.lastName && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.lastName.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="dflex">
                              <div>
                                <select
                                  className="dropdown-menu"
                                  defaultValue=""
                                  {...register("personal.gender", {
                                    required: "Required*",
                                  })}
                                >
                                  <option value="" disabled>
                                    Gender*
                                  </option>
                                  {constant.GENDERS.map((gender) => (
                                    <option
                                      key={gender.id}
                                      value={gender.value}
                                    >
                                      {gender.value}
                                    </option>
                                  ))}
                                </select>
                                {errors.personal?.gender && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.gender.message}
                                  </div>
                                )}
                              </div>
                              <div>
                                <select
                                  className="dropdown-menu"
                                  defaultValue=""
                                  {...register("personal.mothertongue", {
                                    required: "Required*",
                                  })}
                                >
                                  <option value="" disabled>
                                    Mother Tongue*
                                  </option>
                                  {constant.MOTHER_TONGUE.map(
                                    (mothertongue) => (
                                      <option key={mothertongue.id}>
                                        {mothertongue.value}
                                      </option>
                                    ),
                                  )}
                                </select>
                                {errors.personal?.mothertongue && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.mothertongue.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="dob-input">
                              <input
                                {...register("personal.dob", {
                                  required: "Date is not valid",
                                  validate: (value) => {
                                    // First check for the pattern
                                    if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(value))
                                      return "Please enter valid date";

                                    // Parse the date parts to integers
                                    var parts = value.split("-");

                                    var year = parseInt(parts[0], 10);
                                    var month = parseInt(parts[1], 10);
                                    var day = parseInt(parts[2], 10);

                                    // Check the ranges of month and year
                                    if (
                                      year < 1000 ||
                                      year > 3000 ||
                                      month == 0 ||
                                      month > 12
                                    )
                                      return "Year is not valid";

                                    var monthLength = [
                                      31, 28, 31, 30, 31, 30, 31, 31, 30, 31,
                                      30, 31,
                                    ];

                                    // Adjust for leap years
                                    if (
                                      year % 400 == 0 ||
                                      (year % 100 != 0 && year % 4 == 0)
                                    )
                                      monthLength[1] = 29;

                                    // Check the range of the day
                                    if (
                                      !(
                                        day > 0 && day <= monthLength[month - 1]
                                      )
                                    ) {
                                      return "Please select the valid day";
                                    }
                                    const userage = calculateAge(value);
                                    if (userage < 18 || userage > 100)
                                      return "Age must be mininum of 18 years";
                                    return true;
                                  },
                                })}
                                id="dob"
                                type="date"
                                onFocus={(e) =>
                                  e.target.setAttribute(
                                    "data-has-content",
                                    "true",
                                  )
                                }
                                onBlur={(e) =>
                                  !e.target.value &&
                                  e.target.removeAttribute("data-has-content")
                                }
                                placeholder="Date of birth*"
                              />
                              {errors.personal?.dob && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {errors.personal?.dob.message}
                                </div>
                              )}
                            </div>
                            <div className="dflex space">
                              <div>
                                <input
                                  {...register("personal.weight", {
                                    valueAsNumber: false,
                                    required: "Required*",
                                    validate: (value) => {
                                      if (value === ".")
                                        return "Invalid height";
                                      return true;
                                    },
                                  })}
                                  id="weight"
                                  min={0}
                                  type="text"
                                  onWheel={numberInputOnWheelPreventChange}
                                  placeholder="Weight (kg)*"
                                  formNoValidate={true}
                                />
                                {errors.personal?.weight && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.weight.message}
                                  </div>
                                )}
                              </div>

                              <div>
                                <input
                                  {...register("personal.height", {
                                    valueAsNumber: false,
                                    required: "Required*",
                                    validate: (value) => {
                                      if (value === ".")
                                        return "Invalid height";
                                      return true;
                                    },
                                  })}
                                  id="height"
                                  min={0}
                                  type="text"
                                  onWheel={numberInputOnWheelPreventChange}
                                  placeholder="Height (ft'in)*"
                                />
                                {errors.personal?.height && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.height.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <select
                              className="dropdown-menu"
                              defaultValue=""
                              {...register("personal.religion", {
                                required: "Required*",
                              })}
                            >
                              <option value="" disabled>
                                Religion*
                              </option>
                              {constant.RELIGIONS.map((religion) => (
                                <option key={religion.id}>
                                  {religion.value}
                                </option>
                              ))}
                            </select>
                            {errors.personal?.religion && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.personal?.religion.message}
                              </div>
                            )}
                          </div>
                          <div className="dflex">
                            <div>
                              {" "}
                              <select
                                className="dropdown-menu"
                                defaultValue=""
                                {...register("personal.community", {
                                  required: "Required*",
                                })}
                              >
                                <option value="" disabled>
                                  Community*
                                </option>
                                {constant.COMMUNITIES.map((community) => (
                                  <option key={community.id}>
                                    {community.value}
                                  </option>
                                ))}
                              </select>
                              {errors.personal?.community && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {errors.personal?.community.message}
                                </div>
                              )}
                            </div>
                            <div>
                              <select
                                className="dropdown-menu"
                                defaultValue=""
                                {...register("personal.maritalStatus", {
                                  required: "Required*",
                                })}
                              >
                                <option value="" disabled>
                                  Marital Status*
                                </option>
                                {constant.MARITAL_STATUSES.map(
                                  (maritalStatus) => (
                                    <option key={maritalStatus.id}>
                                      {maritalStatus.value}
                                    </option>
                                  ),
                                )}
                              </select>
                              {errors.personal?.maritalStatus && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {errors.personal?.maritalStatus.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="Verify_link">
                            {
                              <div>
                                {isNumberEntered && (
                                  <>
                                    <input
                                      {...register("personal.contactNumber", {
                                        required: "Required*",
                                        pattern: {
                                          value: /^[6-9]\d{9}$/,
                                          message: "Invalid mobile number",
                                        },
                                      })}
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      placeholder="Mobile Number*"
                                      disabled={isVerified}
                                      onChange={(e) =>
                                        setIsVerifyVisible(
                                          +e.target.value.length === 10,
                                        )
                                      }
                                    />
                                    {isVerifyVisible && (
                                      <span>
                                        <button
                                          onClick={verifyContact}
                                          className="btn-trans"
                                          type="button"
                                        >
                                          {isVerified ? "Verified" : "Verify"}
                                        </button>
                                      </span>
                                    )}
                                  </>
                                )}
                                {otpSent && (
                                  <div
                                    className={
                                      otpSent.isErr
                                        ? "error-msg"
                                        : "success-msg"
                                    }
                                  >
                                    {otpSent.Message}
                                  </div>
                                )}
                                {errors.personal?.contactNumber && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {errors.personal?.contactNumber.message}
                                  </div>
                                )}
                              </div>
                            }
                            {process.env.NODE_ENV !== "development" && (
                              <Captcha
                                setIsCaptchaSuccess={setIsCaptchaSuccess}
                              />
                            )}
                          </div>
                          {!isVerified && (
                            <div>
                              <input
                                type="number"
                                onChange={(e) => setOTP(e.target.value)}
                                value={otp}
                                onWheel={numberInputOnWheelPreventChange}
                                placeholder="Enter OTP sent to your number*"
                              />
                              <div>
                                {err && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {err}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div>
                            {!isNumberEntered && (
                              <div className="viewBtnDiv">
                                <button
                                  className="btn-trans"
                                  onClick={onResendOTP}
                                >
                                  Resend OTP
                                </button>
                              </div>
                            )}
                          </div>

                          <div>
                            <select
                              className="dropdown-menu"
                              defaultValue="Indian"
                              {...register("personal.nationality", {
                                required: "Required*",
                              })}
                            >
                              <option value="" disabled>
                                Nationality*
                              </option>
                              {constant.NATIONALITIES.map((nationality) => (
                                <option key={nationality.id}>
                                  {nationality.value}
                                </option>
                              ))}
                            </select>
                            {errors.personal?.nationality && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errors.personal?.nationality.message}
                              </div>
                            )}
                          </div>

                          <div className="divider"></div>
                          <div className="btn-flex alignItemCenter">
                            <div className="left-btn align"></div>
                            {isCaptchaSuccess && (
                              <button
                                disabled={isSubmitting}
                                type="submit"
                                className="cont-btn commonBtn"
                              >
                                Continue
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            )}
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"> </div>
        </div>
      </div>
    </div>
  );
}
