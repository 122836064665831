import { useState } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FacebookIcon, LinkedinIcon, XIcon, WhatsappIcon } from "react-share";

type TVideoShareButtonsProps = {
  videoUrl: string;
};

const VideoShareButtons = ({ videoUrl }: TVideoShareButtonsProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyLink = (e: any) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(videoUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  return (
    <div>
      <div className="shareBox">
        <h2>Share</h2>
        <center>
          <TwitterShareButton
            url={videoUrl}
            className="Demo__some-network__share-button"
          >
            <XIcon className="ShareIcon" size={60} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={videoUrl}>
            <WhatsappIcon className="ShareIcon" size={60} round={true} />
          </WhatsappShareButton>
          <FacebookShareButton url={videoUrl}>
            <FacebookIcon className="ShareIcon" size={60} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={videoUrl}>
            <LinkedinIcon className="ShareIcon" size={60} round={true} />
          </LinkedinShareButton>
        </center>
        <form>
          <input type="text" value={videoUrl} disabled />
          <button className="share-btn" onClick={copyLink}>
            Copy
          </button>
        </form>
        {isCopied && <p className="copied-text">Link copied to clipboard!</p>}
      </div>
    </div>
  );
};

export default VideoShareButtons;
