import { Helmet } from "react-helmet-async";
import HeroSection from "../header/HeroSectionCompo";
import Footer from "../Footer";
import axiosClient from "../../services/axiosInstance";
import BlogPage from "./BlogPage";
import { useEffect, useState } from "react";
import PageNumbers from "./PageNumbers";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

export type TBlogDocs = {
  Title: string;
  Image: string;
  Slug: string;
  CreatedAt: Date;
};

export type TBlogs = {
  docs: TBlogDocs[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
};

const Blogs = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [blogResult, setBlogResult] = useState<TBlogs>();
  const [isLoading, setIsLoading] = useState(false);
  //const { isLoading, setIsLoading } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function getBlogs() {
      try {
        setIsLoading(true);
        const res = await client.get(`/blog?page=${currentPage}`);
        if (true) {
          setBlogResult(res?.data?.result);
        } else {
          setBlogResult({} as TBlogs);
        }
        setIsLoading(false);
      } catch (err: any) {
      } finally {
        setIsLoading(false);
      }
    }
    getBlogs();
  }, [currentPage]);

  return (
    <div>
      <Helmet>
        <title>Blogs | Premmilan</title>
      </Helmet>
      <div className="blogHeroSection">
        <HeroSection imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/1373250-blogs.png" />
      </div>

      <section className="BlogCardSection">
        <div className="imgtextsctnmain">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blogPgHdng">
                  <p> Featured Articles </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {blogResult &&
                blogResult.docs.map((post, index) => (
                  <BlogPage
                    key={index}
                    Image={post.Image}
                    Title={post.Title}
                    CreatedAt={post.CreatedAt}
                    Slug={post.Slug}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
      {blogResult && (
        <PageNumbers blog={blogResult} setCurrentPage={setCurrentPage} />
      )}
      <Footer />
    </div>
  );
};

export default Blogs;
