import Accordion from "react-bootstrap/Accordion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./style.css";
import { NumerologyHeader } from "./NumerologyHeader";
import { useLocation, useNavigate } from "react-router-dom";

export type TNumerologyDetailsInfo = {
  number: number;
  description: string;
};

export type TNumerologyDetailsData = {
  boy: TNumerologyDetailsInfo;
  girl: TNumerologyDetailsInfo;
};

const NumerologyDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="NumerologyDetails">
        <NumerologyHeader />
        <section className="MyProfile">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="back-btn">
                  <button
                    className="btn-trans"
                    type="button"
                    onClick={handleBack}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="profileImgBox">
                  <Swiper className="mySwiper">
                    <SwiperSlide>
                      <div className="profileImgInrBox">
                        <img
                          src="https://premmilan.s3.ap-south-1.amazonaws.com/utils/1665451-NumerologyAccordianImage.png"
                          alt="numerology-cover"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName"> Your Life Path Number </p>
                        </div>
                      </Accordion.Header>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <div className="MyprofileForm">
                              <div className="grid3">
                                <div>
                                  <label> Name </label>
                                  <div> Life Path Number </div>
                                </div>
                                <div>
                                  <label> Number </label>
                                  <div> {state?.boy?.number} </div>
                                </div>
                              </div>

                              <div className="grid1">
                                <div>
                                  <label> Description </label>
                                  <div>{state?.boy?.description}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName">
                            {" "}
                            Your Partner Life Path Number{" "}
                          </p>
                        </div>
                      </Accordion.Header>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <div className="MyprofileForm">
                              <div className="grid3">
                                <div>
                                  <label> Name </label>
                                  <div> Life Path Number </div>
                                </div>
                                <div>
                                  <label> Number </label>
                                  <div> {state?.girl?.number} </div>
                                </div>
                              </div>

                              <div className="grid1">
                                <div>
                                  <label> Description </label>
                                  <div>{state?.girl?.description}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-2"> </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NumerologyDetails;
