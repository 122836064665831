import { Tooltip as ReactTooltip } from "react-tooltip";

const ShowPackageDot = ({ packageName }: any) => {
  const packageTooltip =
    packageName?.toLowerCase() === "premium" ? "Premium" : "Exclusive";

  return (
    <>
      {packageName && (
        <i
          data-tooltip-id="my-tooltip-1"
          className={
            packageName.toLowerCase() === "premium"
              ? "fas fa-star"
              : "fas fa-crown"
          }
          style={{ fontSize: "24px", color: "#f26507" }}
        ></i>
      )}
      <ReactTooltip
        style={{ zIndex: 1000 }}
        id="my-tooltip-1"
        place="bottom"
        content={packageTooltip}
      />
    </>
  );
};

export default ShowPackageDot;
