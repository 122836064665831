import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TFirebaseState = {
  currentUser: any;
};

const initialState: TFirebaseState = {
  currentUser: null,
};

const firebaseSlice = createSlice({
  name: "firebase",
  initialState,
  reducers: {
    setFirebaseUser: (state, action: PayloadAction<TFirebaseState>) => {
      return { ...state, ...action.payload };
    },
    resetFirebase: () => initialState,
  },
});

export const { setFirebaseUser, resetFirebase } = firebaseSlice.actions;
export default firebaseSlice.reducer;
