import Modal from "react-modal";
import VideoRecorder from "../VideoRecorder";

type TVideoRecorderModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onStop: (blob: Blob) => void;
};

const VideoRecorderModal = ({
  isOpen,
  onRequestClose,
  onStop,
}: TVideoRecorderModalProps) => {
  if (!isOpen) return;

  return (
    <div className="VideoRecorderModal">
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        contentLabel="Video Recorder"
      >
        <VideoRecorder onStop={onStop} onRequestClose={onRequestClose} />
      </Modal>
    </div>
  );
};

export default VideoRecorderModal;
