import whiteArrow from "../../assets/images/whiteArrow.svg";
import { Link } from "react-router-dom";

export default function PasswordResetSuccess() {
  return (
    <div>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100">
                <div className="errorPageMsg">
                  <h1> OOPS! </h1>
                  <p>
                    {" "}
                    We're sorry, the page you requested could not be found{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/">
          <p className="ftrText">
            {" "}
            Go Back to home page <img src={whiteArrow}></img>
          </p>
        </Link>
      </div>
    </div>
  );
}
