import { createContext, useContext, useLayoutEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Loader from "./accounts/Loader";

import { useEffect } from "react";
import axiosClient from "../services/axiosInstance";
import { TSetting } from "../types/landing";
import AudioToggle from "./header/AudioToggle";
import Captcha from "./Captcha";

export type TLoader = {
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
};
const LoaderContext = createContext<TLoader>({} as TLoader);

const Root = () => {
  const [isLoading, setIsLoading] = useState(false);
  const client = axiosClient();
  const [setting, setSettings] = useState<TSetting>({} as TSetting);

  useEffect(() => {
    async function fetch() {
      try {
        const resSetting = await client.get("/admin/settings");
        setSettings(resSetting.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
      }
    }
    fetch();
  }, []);

  const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      window.history.scrollRestoration = "manual";
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <Outlet />
        </Wrapper>
      )}
      {/* <Captcha /> */}
      <AudioToggle showToggle={true} music={setting.Music} />
    </LoaderContext.Provider>
  );
};

export default Root;

export function useLoader() {
  return useContext(LoaderContext);
}
