import axiosClient from "../../services/axiosInstance";

const InitLoadVideos = async () => {
  const client = axiosClient(),
    pathSlug = window.location.pathname.split("/")[2],
    videosData = [];
  if (pathSlug) {
    try {
      const res = await client.get(`/klick/${pathSlug}`);
      if (true) {
        videosData.push(...res.data?.result);
      }
    } catch (err: any) {
      return [];
    }
  }
  try {
    const res = await client.get("/klick");
    if (true) {
      const mergeVideos = [...videosData, ...res.data?.result?.docs];
      return mergeVideos;
    }
  } catch (err: any) {
    return [];
  }
};

export default InitLoadVideos;
