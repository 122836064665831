import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";

const UpgradeBtn = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const { packageName } = useSelector((state: RootState) => state.package);
  return (
    <>
      {isLoggedIn ? (
        (!packageName ||
          (packageName && packageName.toLowerCase() !== "exclusive")) && (
          <Link to="/subscription">
            {" "}
            {packageName ? "Join now" : "Buy Subscription"}{" "}
          </Link>
        )
      ) : (
        <Link to="/login"> Login </Link>
      )}
    </>
  );
};
export default UpgradeBtn;
