import { useLocation } from "react-router-dom";
import RegistrationProvider from "./RegistrationProvider";
import RegistrationSteps from "./RegistrationSteps";
import { useLayoutEffect } from "react";
import ScrollTopWrapper from "../../ScrollTopWrapper";

export default function RegistrationWrapper() {
  return (
    <div>
      <ScrollTopWrapper>
        <RegistrationProvider>
          <RegistrationSteps />
        </RegistrationProvider>
      </ScrollTopWrapper>
    </div>
  );
}
