import profileSendRequest from "../../../assets/images/profileSendRequest.png";
import profileNoIcon from "../../../assets/images/profileNoIcon.png";
import SprofileHeart from "../../../assets/images/SprofileHeart.svg";
import SprofileHeartFill from "../../../assets/images/SprofileHeartFill.svg";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import { toast } from "react-toastify";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import user_dummy_photo from "../../../assets/images/user_dummy_photo.png";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import { TUserFavourites } from "./Favourites";
import { TPhotoData } from "../../../types/register";
import { useNavigate } from "react-router-dom";
import { ToWords } from "to-words";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ShowPackageDot from "../components/ShowPackageDot";

type TFavouritesData = {
  data: TUserFavourites;
};

const FavouritesCardCompo = ({ data }: TFavouritesData) => {
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(data.isFavourite);
  const { id } = useSelector((state: RootState) => state.user);
  const { requestPerDay, packageName } = useSelector(
    (state: RootState) => state.package,
  );
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  const getPhoto = (Photos: TPhotoData[]) => {
    const visiblePhoto =
      Photos?.length && Photos[0]["25px"]
        ? Photos[0]["25px"]
        : user_dummy_photo;
    return visiblePhoto;
  };
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const openFullProfile = () => {
    navigate("/profile/profile-details", {
      state: {
        data,
      },
    });
  };

  const handleFavourite = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const payload = { UserId: data._id };
      const res = isFavourite
        ? await client.delete(`/user/favourite/${id}`, {
            data: payload,
          })
        : await client.put(`/user/favourite/${id}`, payload);
      if (true) {
        setIsFavourite(!isFavourite);
      }
    } catch (err: any) {
    } finally {
    }
  };
  const handleRequest = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const payload = {
        UserId: id,
        RequestToUserId: data._id,
        Requests: requestPerDay,
      };
      const res = isRequestSent
        ? await client.delete("/request/cancel", {
            data: { UserId: id, RequestUserId: data._id },
          })
        : await client.put("/request/send", payload);
      if (true) {
        setIsRequestSent(!isRequestSent);
      }
    } catch (err: any) {
      if (err?.response?.status === 429) {
        toast.error(err?.response?.data?.error, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
    }
  };

  return (
    <div>
      <div className="mainTab">
        <div className="profileCard">
          <div className="profileImage">
            <img src={data.Photos} alt="" />
          </div>
          <div className="profileDetail">
            <div className="dflex justifyContentSpaceBetween">
              <div className="aboutDetail">
                <p className="username" onClick={openFullProfile}>
                  {" "}
                  {data.FirstName + " " + data.LastName} &nbsp;
                  {packageName && (
                    <ShowPackageDot packageName={data?.PackageName} />
                  )}
                </p>
                <ul>
                  <li>
                    <img src={SprofileLocation} alt="" /> {data.City},
                    {data.State}
                  </li>
                  <li>
                    <img src={SprofileJobBC} alt="" /> {data.EducationName}
                  </li>
                  <li>
                    <img src={SprofileEducation} alt="" /> {data.WorkTitle} /
                    <span data-tooltip-id="my-tooltip-2">
                      {data.ExactIncome}
                    </span>
                    <ReactTooltip
                      style={{ zIndex: 1000 }}
                      id="my-tooltip-2"
                      place="bottom"
                      content={toWords.convert(data.ExactIncome)}
                    />
                  </li>
                </ul>
              </div>
              <div className="likeAge">
                <p>
                  <img
                    onClick={handleFavourite}
                    src={isFavourite ? SprofileHeartFill : SprofileHeart}
                    alt=""
                  />
                </p>
                <p className="Age"> Age {calculateAge(data.DOB)} </p>
              </div>
            </div>
            <div className="profileCardFtr">
              <p onClick={handleRequest}>
                <img
                  src={isRequestSent ? profileNoIcon : profileSendRequest}
                  alt=""
                />
                {isRequestSent ? "Cancel Request" : "Send Request"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouritesCardCompo;
