import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, isToday } from "date-fns";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import { useNavigate } from "react-router-dom";

export type TNotificationType =
  | "ACCEPTED"
  | "SENT"
  | "VIEWPROFILE"
  | "CONTACTACCEPTED"
  | "CONTACTSENT"
  | "OTHER";
export type TNotification = {
  Type: TNotificationType;
  NotificationFrom: string;
  NotificationTo: string;
  Message: string;
  CreatedAt: Date;
};

// To trigger the event source and to save it in DB

const NotificationBox = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const { id } = useSelector((state: RootState) => state.user);
  const [pageNumber, setPageNumber] = useState(1);
  const [messages, setMessages] = useState<TNotification[]>([]);
  const navigate = useNavigate();
  //const [isLoading, setIsLoading] = useState(false);

  // To format the notification time
  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return isToday(date)
      ? `Today at ${format(date, "h:mm a")}`
      : format(date, "MMMM d, yyyy 'at' h:mm a");
  };

  const sendNotification = async (notificationObj: TNotification) => {
    try {
      await client.post(
        "/notification/send",
        notificationObj
        // { NotificationFrom: id, NotificationTo: id, Message: message, CreatedAt: new Date() },
      );
    } catch (error) {}
  };

  const fetchNotifications = async () => {
    // if (isLoading) return;
    //setIsLoading(true);
    try {
      const res = await client.get(
        `/notification/user/${id}?page=${pageNumber}`
      );
      if (true) {
        if (res?.data?.result?.docs) {
          setMessages(res?.data?.result.docs);
        }
      }
    } catch (err) {
    } finally {
      //setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchNotifications();
  };

  // Capture the events
  useEffect(() => {
    try {
      const eventSource = new EventSource(
        `${process.env.REACT_APP_BACKEND_URL}notification/get`
      );
      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        if (newMessage.NotificationTo !== id) return;
        setMessages((prevMessages) => [newMessage, ...prevMessages]);
      };

      return () => {
        eventSource.close();
      };
    } catch (err) {}
  }, []);

  const handleOpenNotification = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    notification: TNotification
  ) => {
    // console.log("notification type", notification);
    if (notification.Type === "VIEWPROFILE") {
      navigate("/profile/profile-details", {
        state: {
          data: {
            _id: notification.NotificationFrom,
          },
        },
      });
    } else if (notification.Type === "CONTACTACCEPTED") {
      navigate("/profile/contacts", {
        state: {
          requestTab: "ACCEPTED",
        },
      });
    } else if (notification.Type === "ACCEPTED") {
      navigate("/profile/requests", {
        state: {
          requestTab: "ACCEPTED",
        },
      });
    } else if (notification.Type === "CONTACTSENT") {
      navigate("/profile/contacts", {
        state: {
          requestTab: "RECEIVED",
        },
      });
    } else if (notification.Type === "SENT") {
      navigate("/profile/requests", {
        state: {
          requestTab: "RECEIVED",
        },
      });
    } else {
      console.error("Unknown notification type:", notification.Type);
    }
  };

  return (
    <div>
      <div className="notificationSideBox">
        <div className="dflex justifyContentBetween">
          <p className="tite"> Notifications </p>
          {/* <p className="viewAll">
            <a href=""> View All </a>
          </p> */}
        </div>

        <div className="notificationCard">
          <ul>
            {messages && messages.length > 0 ? (
              messages.map((msg, index) => (
                <li key={index} onClick={(e) => handleOpenNotification(e, msg)}>
                  <div>
                    <p className="NotificationTime">
                      {msg.CreatedAt &&
                        formatDateTime(msg.CreatedAt.toString())}
                    </p>
                    <p className="NotificationTitle">{msg.Message} </p>
                  </div>
                </li>
              ))
            ) : (
              <li>
                <div>
                  <p className="NotificationTitle">There is no notification</p>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationBox;
