import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { router } from "./routes/Routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./index.css";
import "./global.css";
import "./global_responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/accounts/Loader";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={<Loader />} persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </HelmetProvider>
    <ToastContainer />
  </React.StrictMode>,
);
