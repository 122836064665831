import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./style.css";
import submitIcon from "../../../assets/images/inputSubmitIcon.svg";

const Setting = () => {
  return (
    <div>
      <Helmet>
        <title>Settings | Premmilan</title>
      </Helmet>
      <div className="mysubscription">
        <div className="row">
          <div className="col-md-9">
            <div className="settingPageBox">
              <p className="commanheading"> Account & Settings </p>
            </div>

            <div className="settingForm">
              <div className="dflex inputBox justifyContentSpaceBetween">
                <label htmlFor="Change Password" className="settingLabel">
                  Change Password
                </label>
                <Link to="/profile/change-password">
                  <img
                    alt="submitIcon"
                    src={submitIcon}
                    className="submitIcon"
                  />
                </Link>
              </div>
              <div className="dflex inputBox my30 justifyContentSpaceBetween">
                <label htmlFor="Blocked Profiles" className="settingLabel">
                  Blocked Profiles
                </label>
                <Link to="/profile/blocked-profiles">
                  <img
                    alt="submitIcon"
                    src={submitIcon}
                    className="submitIcon"
                  />
                </Link>
              </div>
              <div className="dflex inputBox justifyContentSpaceBetween">
                <label htmlFor="Hide/ Delete Profile" className="settingLabel">
                  Hide/ Delete Profile
                </label>
                <Link to="/profile/hide-delete-profile">
                  <img
                    alt="submitIcon"
                    src={submitIcon}
                    className="submitIcon"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="spaceBetweenFooter"></div>
      </div>
    </div>
  );
};

export default Setting;
