import profileSendRequest from "../../../assets/images/profileSendRequest.png";
import profileNoIcon from "../../../assets/images/profileNoIcon.png";
import SprofileHeart from "../../../assets/images/SprofileHeart.svg";
import SprofileHeartFill from "../../../assets/images/SprofileHeartFill.svg";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import { toast } from "react-toastify";
import "./ProfileCardCompo.css";
import { TUserMatches } from "../matches/MatchesPage";

import { useState } from "react";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import { differenceInYears } from "date-fns";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TNotification } from "./NotificationBoxCompo";
import ShowPackageDot from "./ShowPackageDot";
import { ToWords } from "to-words";
import { Tooltip as ReactTooltip } from "react-tooltip";
const commaNumber = require("indian-number-format");

type TMatchesData = {
  data: TUserMatches;
  handleReject: (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    id: string,
  ) => void;
};

const ProfileCardCompo = ({ data, handleReject }: TMatchesData) => {
  const { id, firstName, lastname } = useSelector(
    (state: RootState) => state.user,
  );

  const { packageName, requestPerDay } = useSelector(
    (state: RootState) => state.package,
  );
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [isFavourite, setIsFavourite] = useState(data.isFavourite);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  // const userPhoto = data.Photos[0]["25px"]?.length
  //   ? data.Photos[0]?.["25px"]
  //   : user_dummy_photo;

  const userPhoto = data.Photos;

  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  const handleFavourite = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const payload = { UserId: data._id };
      const res = isFavourite
        ? await client.delete(`/user/favourite/${id}`, {
            data: payload,
          })
        : await client.put(`/user/favourite/${id}`, payload);
      if (true) {
        setIsFavourite(!isFavourite);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const handleRequest = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const payload = {
        UserId: id,
        RequestToUserId: data._id,
        Requests: requestPerDay,
      };
      const res = isRequestSent
        ? await client.delete("/request/cancel", {
            data: { UserId: id, RequestUserId: data._id },
          })
        : await client.put("/request/send", payload);

      if (!isRequestSent) {
        sendNotification({
          Type: "SENT",
          NotificationFrom: id!,
          NotificationTo: data._id,
          Message: `You have received a request from ${firstName} ${lastname}`,
          CreatedAt: new Date(),
        });
      }

      if (true) {
        setIsRequestSent(!isRequestSent);
      }
    } catch (err: any) {
      if (err?.response?.status === 429) {
        toast.error(err?.response?.data?.error, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
    }
  };
  const sendNotification = async (notificationObj: TNotification) => {
    try {
      await client.post(
        "/notification/send",
        notificationObj,
        // { NotificationFrom: id, NotificationTo: id, Message: message, CreatedAt: new Date() },
      );
    } catch (error) {}
  };
  const openFullProfile = () => {
    if (!packageName) return;
    navigate("/profile/profile-details", {
      state: {
        data,
      },
    });

    sendNotification({
      Type: "VIEWPROFILE",
      NotificationFrom: id!,
      NotificationTo: data._id,
      Message: `${firstName} ${lastname} viewed your profile`,
      CreatedAt: new Date(),
    });
  };

  return (
    <div>
      <div className="mainTab">
        <div className="profileCard">
          <div className="profileImage">
            <img src={userPhoto} alt="" />
          </div>
          <div className="profileDetail">
            <div className="dflex justifyContentSpaceBetween">
              <div className="aboutDetail">
                <p className="username" onClick={openFullProfile}>
                  <>
                    {packageName
                      ? data?.FirstName + " " + data?.LastName
                      : "Username"}
                    &nbsp;
                    {packageName && (
                      <ShowPackageDot packageName={data?.PackageName} />
                    )}
                  </>
                </p>
                <ul>
                  <li>
                    <img src={SprofileLocation} alt="" /> {data.City},
                    {data.State}
                  </li>
                  <li>
                    <img src={SprofileJobBC} alt="" /> {data.EducationName}
                  </li>
                  <li>
                    <img src={SprofileEducation} alt="" />
                    <div className="salary">
                      {data.WorkTitle} /
                      <span data-tooltip-id="my-tooltip-2">
                        {" "}{commaNumber.format(data.ExactIncome)}
                      </span>
                    </div>
                    <ReactTooltip
                      style={{ zIndex: 1000 }}
                      id="my-tooltip-2"
                      place="bottom"
                      content={toWords.convert(data.ExactIncome)}
                    />
                  </li>
                </ul>
              </div>
              <div className="likeAge">
                {packageName && (
                  <p>
                    <img
                      onClick={handleFavourite}
                      src={isFavourite ? SprofileHeartFill : SprofileHeart}
                      alt=""
                    />
                  </p>
                )}
                <p className="Age"> Age {calculateAge(data.DOB)} </p>
              </div>
            </div>

            {packageName && (
              <div className="profileCardFtr">
                <p onClick={handleRequest}>
                  <img
                    src={isRequestSent ? profileNoIcon : profileSendRequest}
                    alt=""
                  />
                  {isRequestSent ? "Cancel Request" : "Send Request"}
                </p>
                <p onClick={(e) => handleReject(e, data._id)}>
                  <img src={profileNoIcon} alt="" /> No
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCardCompo;
