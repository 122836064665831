import Accordion from "react-bootstrap/Accordion";
import { TBasicProfileInfo } from "./ProfileBasicInfo";
import checkOkColor from "../../../../src/assets/images/checkOkColor.svg";

const ProfileDocumentInfo = ({ data }: TBasicProfileInfo) => {
  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Document Info </p>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm document-info">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div className="disableinputbox">
                    <div className="dflex">
                      <div className="dummyinput">Aadhar card</div>
                      {data.Aadhar === "true" && (
                        <img src={checkOkColor} alt="" />
                      )}
                    </div>
                  </div>

                  <div className="disableinputbox">
                    <div className="dflex">
                      <div className="dummyinput"> PAN card </div>
                      {data.PAN === "true" && <img src={checkOkColor} alt="" />}
                    </div>
                  </div>

                  <div className="disableinputbox">
                    <div className="dflex">
                      <div className="dummyinput"> Passport </div>
                      {data.Passport === "true" && (
                        <img src={checkOkColor} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="grid2">
                  <div className="disableinputbox mbn18">
                    <div className="dflex">
                      <div className="dummyinput">
                        Offer Letter (If you are doing a job)
                      </div>
                      {data.OfferLetter === "true" && (
                        <img src={checkOkColor} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="grid2">
                  <div className="disableinputbox">
                    <div className="dflex">
                      <div className="dummyinput">
                        Govt. Certificate (If you are doing a business)
                      </div>
                      {data.GovtCert === "true" && (
                        <img src={checkOkColor} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileDocumentInfo;
