import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { TGender } from "../../../types/base";
import ReceivedRequestCardCompo from "./ReceivedContactReqCardCompo";
import SentRequestCardCompo from "./SentContactReqCardCompo";
import AcceptedRequestCardCompo from "./AcceptedContactReqCardCompo";
import "./ContactReq.css";
import NotificationBox from "../components/NotificationBoxCompo";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import NoSubsciption from "../../subscription/NoSubsciption";
import { TRequestTab } from "../requests/Requests";

export type TUserContactRequest = {
  _id: string;
  Username: string;
  FirstName: string;
  LastName: string;
  Photos: string;
  Video: string;
  CreatedAt: Date;
  isFavourite: boolean;
  DOB: Date;
  Gender: TGender;
  ExactIncome: number;
  City: string;
  State: string;
  EducationName: string;
  WorkTitle: string;
  isBlocked: boolean;
  RequestType: string;
  AcceptedAt: string;
  PackageName: string;
};

const ContactRequestPage = () => {
  const { state } = useLocation();

  const { packageName } = useSelector((state: RootState) => state.package);
  const defaultPage =
    state === null || state.requestTab.length === 0
      ? "RECEIVED"
      : state.requestTab;
  const [tabValue, setTabValue] = useState<TRequestTab>(defaultPage);

  const handleTabClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    key: TRequestTab,
  ) => {
    e.preventDefault();
    setTabValue(key);
  };

  const showTabComponent = () => {
    switch (tabValue) {
      case "RECEIVED":
        return <ReceivedRequestCardCompo />;
      case "SENT":
        return <SentRequestCardCompo />;
      case "ACCEPTED":
        return <AcceptedRequestCardCompo />;
      default:
        return null;
    }
  };
  if (!packageName) return <NoSubsciption />;
  return (
    <div>
      <Helmet>
        <title>Contact Requests | Premmilan</title>
      </Helmet>
      <section className="RequestPage">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {/* <p className="tite"> You have received 4 new requests </p> */}
              <div className="rsa">
                <ul>
                  <li
                    className={tabValue === "RECEIVED" ? "active" : ""}
                    onClick={(e) => handleTabClick(e, "RECEIVED")}
                  >
                    Received
                  </li>
                  <li
                    className={tabValue === "SENT" ? "active" : ""}
                    onClick={(e) => handleTabClick(e, "SENT")}
                  >
                    Sent
                  </li>
                  <li
                    className={tabValue === "ACCEPTED" ? "active" : ""}
                    onClick={(e) => handleTabClick(e, "ACCEPTED")}
                  >
                    Accepted
                  </li>
                </ul>
              </div>

              {<div>{showTabComponent()}</div>}
            </div>
            {!isMobile && (
              <div className="col-md-4">
                <NotificationBox />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactRequestPage;
