import ReactPaginate from "react-paginate";

const PageNumbers = ({ blog, setCurrentPage }: any) => {
  return (
    <div className="paginationAlign">
      <ReactPaginate
        containerClassName="pagination"
        activeClassName="active"
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={blog.totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(e) => setCurrentPage(e.selected + 1)}
      />
    </div>
  );
};

export default PageNumbers;
