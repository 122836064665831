import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUserStore } from "../../../state/firebase/userFirehoseStore";
import { handleAdd } from "../chat/AddUser";
import profileNoIcon from "../../../assets/images/profileNoIcon.png";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import profileSendRequest from "../../../assets/images/profileSendRequest.png";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import { TUserContactRequest } from "./ContactRequests";
import { ToWords } from "to-words";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { capitalizeFirstLetterofWord } from "../../../utils/helper";
import ShowPackageDot from "../components/ShowPackageDot";
import ShowRequestType from "./ShowRequestType";

const AcceptedContactReqCardCompo = () => {
  const [data, setData] = useState<TUserContactRequest[]>([]);
  const { currentUser } = useSelector((state: RootState) => state.firebase);
  const { id } = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const fetchedDocumentIds = new Set();
  const { token } = useSelector((state: RootState) => state.auth);
  const { packageName } = useSelector((state: RootState) => state.package);
  const client = axiosClient(token);
  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  // UseEffects
  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchRequests = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await client.post(
        `/number_request/${id}?page=${pageNumber}`,
        {
          Action: "Accepted",
        },
      );
      if (true) {
        const requestsData = res?.data?.result;
        const newRequests = requestsData.filter(
          (x: TUserContactRequest) => !fetchedDocumentIds.has(x._id),
        );
        setData((prevItems) => [...prevItems, ...newRequests]);
        requestsData.forEach((x: TUserContactRequest) => {
          fetchedDocumentIds.add(x._id);
        });
        setPageNumber((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchRequests();
  };

  const handleOpenChat = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    UserId: string,
  ) => {
    await handleAdd(UserId, currentUser);
    navigate("/profile/chat", { replace: true });
  };

  const handleBlockProfile = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    blockUserId: string,
    isUserBlocked: boolean,
  ) => {
    e.preventDefault();
    try {
      const res = isUserBlocked
        ? await client.post("/user/unblock", {
            UserId: id,
            BlockUserId: blockUserId,
          })
        : await client.post("/user/block", {
            UserId: id,
            BlockUserId: blockUserId,
            Reason: "",
          });
      if (true) {
        for (let i = 0; i < data.length; i++) {
          if (data[i]._id === blockUserId) {
            data[i].isBlocked = !data[i].isBlocked;
            setData([...data]);
            break;
          }
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
    }
  };

  const openFullProfile = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    userData: any,
  ) => {
    navigate("/profile/profile-details", {
      state: {
        data: userData,
      },
    });
  };
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  if (isLoading)
    return (
      <>
        <center>
          <p>Loading matches...</p>
        </center>
      </>
    );

  return (
    <div>
      {data.map((x, index) => (
        <div key={index} className="mainTab">
          <div className="profileCard">
            <div className="profileImage">
              <img src={x.Photos} alt="" />
            </div>
            <div className="profileDetail">
              <div className="dflex justifyContentSpaceBetween">
                <div
                  className="aboutDetail"
                  onClick={(e) => openFullProfile(e, x)}
                >
                  <p className="username">
                    {" "}
                    {x.FirstName + " " + x.LastName}{" "}
                    {packageName && (
                      <ShowPackageDot packageName={x?.PackageName} />
                    )}{" "}
                    <ShowRequestType contactType={x.RequestType.toLowerCase()} />
                  </p>
                  <ul>
                    <li>
                      <img src={SprofileLocation} alt="" /> {x.City},{x.State}
                    </li>
                    <li>
                      <img src={SprofileJobBC} alt="" /> {x.EducationName}
                    </li>
                    <li>
                      <img src={SprofileEducation} alt="" /> {x.WorkTitle} /
                      <span data-tooltip-id="my-tooltip-2">
                        {x.ExactIncome}
                      </span>
                      <ReactTooltip
                        style={{ zIndex: 1000 }}
                        id="my-tooltip-2"
                        place="bottom"
                        content={toWords.convert(x.ExactIncome)}
                      />
                      {/* <span>
                        {capitalizeFirstLetterofWord(
                          x.RequestType.toLowerCase(),
                        )}{" "}
                        contact request
                      </span> */}
                    </li>
                  </ul>
                </div>
                <div className="likeAge">
                  <p className="Age"> Age {calculateAge(x.DOB)} </p>
                </div>
              </div>
              <div className="profileCardFtr Sent">
                <p onClick={(e) => handleOpenChat(e, x._id)}>
                  <img src={profileSendRequest} alt="" />
                  Chat
                </p>
                {/* <p onClick={(e) => handleViewProfile(e, x._id)}>
                  <img src={profileNoIcon} alt="" /> View Profile Details
                </p> */}
                <p onClick={(e) => handleBlockProfile(e, x._id, x.isBlocked)}>
                  <img src={profileNoIcon} alt="" />
                  {x.isBlocked ? "Unblock" : "Block"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AcceptedContactReqCardCompo;
