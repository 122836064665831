import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { TResetPassword } from "../../types/auth";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import axiosClient from "../../services/axiosInstance";
import Loader from "./Loader";
import AuthLeftBanner from "../AuthLeftBanner_compo";
import PasswordStrengthBar from "react-password-strength-bar";
import { getAuth, updatePassword } from "firebase/auth";
import { useLoader } from "../Root";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

export default function ResetPassword() {
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<string | null>();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [searchParams] = useSearchParams();
  const UrlToken = searchParams.get("Token");
  const email = searchParams.get("Email");
  const auth = getAuth();
  const user = auth.currentUser;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<TResetPassword>({
    //defaultValues: ,
  });
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);

  const onhandleFormSubmit = async (data: TResetPassword) => {
    try {
      setIsLoading(true);
      const response = await client.post(`auth/change/${email}`, {
        Token: UrlToken,
        Password: data.password,
      });
      if (true) {
        updatePassword(user!, data.password!).then(() => {
        });
        navigate("/reset-password-success");
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 500);
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
      return;
    }
  };

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="login_form">
      <Helmet>
        <title>Reset Password | Premmilan</title>
      </Helmet>
      <AuthLeftBanner
        imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/7071307-login-banner.png"
        altName="Reset Password"
      />
      <div className="login_right_sec">
        <div className="innr_frm">
          <div className="login-logo">
            <Link to="/">
              <img src={require("../../assets/images/logo.png")} alt="logo" />
            </Link>
          </div>
          <div className="login-form">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit(onhandleFormSubmit)}>
              <div className="password-container">
                <input
                  id="password"
                  {...register("password", {
                    required: true,
                    validate: (value) => {
                      if (value && value?.length! < 8)
                        return "Password must be of minimum 8 characters";
                      return true;
                    },
                  })}
                  placeholder="New Password"
                  type={showPassword1 ? "text" : "password"}
                />
                <span
                  className={
                    showPassword1
                      ? "fa fa-fw fa-eye field_icon toggle-password"
                      : "fa fa-fw fa-eye-slash field_icon toggle-password"
                  }
                  onClick={toggleShowPassword1}
                ></span>
                {errors.password && (
                  <div className="error-msg">
                    {errors.password && (
                      <i className="fa fa-exclamation-triangle"></i>
                    )}
                    {errors.password?.message}
                  </div>
                )}
              </div>
              <div className="password-container">
                <input
                  id="confirmpassword"
                  {...register("confirmPassword", {
                    required: "Password is required",
                    validate: (value: string) => {
                      if (watch("password") != value)
                        return "Password must be same!";
                    },
                  })}
                  placeholder="Confirm Password"
                  type={showPassword2 ? "text" : "password"}
                />
                <span
                  className={
                    showPassword2
                      ? "fa fa-fw fa-eye field_icon toggle-password"
                      : "fa fa-fw fa-eye-slash field_icon toggle-password"
                  }
                  onClick={toggleShowPassword2}
                ></span>
              </div>
              <PasswordStrengthBar password={watch("password")!} />
              {errors.confirmPassword && (
                <div className="error-msg">
                  {errors.confirmPassword && (
                    <i className="fa fa-exclamation-triangle"></i>
                  )}
                  {errors.confirmPassword?.message}
                </div>
              )}
              <button
                disabled={isSubmitting}
                type="submit"
                className="loginbtn"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
