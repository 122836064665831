import RightArrow from "../../../assets/images/arrowRightStoriesIcon.png";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import user_dummy_photo from "../../../assets/images/user_dummy_photo.png";
import { RootState } from "../../../state/store";
import axiosClient from "../../../services/axiosInstance";
import { TUserBlocked } from "./Blocked";

type TBlockedData = {
  data: TUserBlocked;
};

const BlockedCardCompo = ({ data }: TBlockedData) => {
  const { id } = useSelector((state: RootState) => state.user);
  const [isBlocked, setIsBlocked] = useState(false);
  // const userPhoto =
  //   data.Photos?.length && data.Photos[0] && data.Photos[0].Url?.length
  //     ? data.Photos[0].Url
  //     : user_dummy_photo;
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  const handleRequest = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const payload = {
        UserId: id,
        BlockUserId: data._id,
      };
      const res = isBlocked
        ? await client.post("/user/block", payload)
        : await client.post("/user/unblock", payload);
      if (true) {
        setIsBlocked(!isBlocked);
      }
    } catch (err: any) {
    } finally {
    }
  };

  return (
    <div>
      <div className="mainTab">
        <div className="profileCard">
          <div className="profileImage">
            <img src={data.Photos} alt="" />
          </div>
          <div className="profileDetail">
            <div className="dflex justifyContentSpaceBetween">
              <div className="aboutDetail">
                <p className="username">
                  {" "}
                  {data.FirstName + " " + data.LastName}{" "}
                </p>
                <ul>
                  <li>
                    <img src={SprofileLocation} alt="" /> {data.City},
                    {data.State}
                  </li>
                  <li>
                    <img src={SprofileJobBC} alt="" /> {data.EducationName}
                  </li>
                  <li>
                    <img src={SprofileEducation} alt="" /> {data.WorkTitle} /
                    {data.ExactIncome}
                  </li>
                </ul>
              </div>
              <div className="likeAge">
                <p className="Age"> Age {calculateAge(data.DOB)} </p>
              </div>
            </div>
            <div className="profileCardFtr blockedCardFtr">
              <p onClick={handleRequest}>
                {isBlocked ? "Block Profile" : "Unblock Profile"}
                <img src={RightArrow} alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedCardCompo;
