import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TRegisterState = {
  email: string;
  password: string;
  profileFor: string;
  partnerPreference: string;
  profileManagedBy: string;
  userId?: string;
};

const initialState: TRegisterState = {
  email: "",
  password: "",
  profileFor: "",
  partnerPreference: "",
  profileManagedBy: "",
  userId: "",
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterData: (state, action: PayloadAction<TRegisterState>) => {
      return { ...state, ...action.payload };
    },
    setRegisterUserId: (state, action: PayloadAction<string>) => {
      return { ...state, userId: action.payload };
    },
    resetRegisger: () => initialState,
  },
});

export const { setRegisterData, resetRegisger, setRegisterUserId } =
  registerSlice.actions;
export default registerSlice.reducer;
