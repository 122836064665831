import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../components/accounts/Login";
import ForgotPassword from "../components/accounts/ForgotPassword";
import RegistrationWrapper from "../components/accounts/registration/RegistrationWrapper";
import Register from "../components/accounts/registration/Register";
import ResetPassword from "../components/accounts/ResetPassword";
import ErrorPage from "../components/accounts/Error";
import LandingPage from "../components/landing/LandingPage";
import SuccessScreen from "../components/SuccessScreen";
import AboutUs from "../components/AboutUs";
import PrivacyPolicy from "../components/PrivacyPolicy";
import BlogDetail from "../components/blogs/BlogDetail";
import PasswordResetSuccess from "../components/accounts/PasswordResetSuccess";
import Klicks from "../components/klickss/Klicks";
import Blogs from "../components/blogs/Blogs";
import Subscription from "../components/subscription/Subscription";
import ContactUs from "../components/ContactUs";
import RegistrationSuccess from "../components/accounts/RegistrationSuccess";
import Root from "../components/Root";
import BlogLayout from "../components/blogs/BlogLayout";
import HappyStories from "../components/stories/Happystories";
import RequiredDocs from "../components/accounts/registration/RequiredDocs";
import MatchesPage from "../components/profile/matches/MatchesPage";
import MyChat from "../components/profile/chat/MyChat";
import Requests from "../components/profile/requests/Requests";
import MySubscription from "../components/profile/MySubscription";
import Settings from "../components/profile/settings/Settings";
import ChangePassword from "../components/profile/settings/ChangePassword";
import Favourites from "../components/profile/favourites/Favourites";
import ProfileTabBar from "../components/profile/components/ProfileTabBarCompo";
import MyProfile from "../components/profile/myprofile/MyProfile";
import Blocked from "../components/profile/settings/Blocked";
import HideDeleteProfile from "../components/profile/settings/HideDeleteProfile";
import ReportUser from "../components/profile/chat/ReportUser";
import PasswordChangedMessage from "../components/profile/settings/PasswordChangedMessage";
import NumerologyDetails from "../components/profile/numerology/NumerologyDetails";
import NumerologyMatch from "../components/profile/numerology/NumerologyMatch";
import KundliMatch from "../components/profile/kundli/KundliMatch";
import KundliDetails from "../components/profile/kundli/KundliDetails";
import PaymentScreen from "../components/profile/payment/Payment";
import PaymentSucessful from "../components/profile/payment/PaymentSuccesful";
import FullProfile from "../components/profile/fullProfile/FullProfile";
import ProtectedRoutes from "./ProtectedRoutes";
import NotificationBox from "../components/profile/components/NotificationBoxCompo";
import MissingRegister from "../components/accounts/registration/MissingRegister";
import MissingDoc from "../components/accounts/registration/MissingDoc";
import ReferralPayment from "../components/profile/payment/ReferralPayment";
import CouponPayment from "../components/profile/payment/CouponPayment";
import TermCondition from "../components/accounts/registration/TermCondition";
import ScrollTopWrapper from "../components/ScrollTopWrapper";
import ExclusiveRoutes from "./ExclusiveRoutes";
import ContactRequestPage from "../components/profile/contactRequest/ContactRequests";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {/* <ScrollTopWrapper> */}
      <Route index element={<LandingPage />} />
      {/* <Route path="terms-and-conditions" element={<TermCondition />} /> */}
      <Route path="login" element={<Login />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="register" element={<Register />} />
      <Route path="refill-form" element={<MissingDoc />} />
      <Route path="registration" element={<RegistrationWrapper />} />
      <Route path="reset_password" element={<ResetPassword />} />
      <Route path="success" element={<SuccessScreen />} />
      <Route path="reset-password-success" element={<PasswordResetSuccess />} />
      {/* <Route path="password-changed" element={<PasswordChangedMessage />} /> */}
      <Route path="registrationsuccess" element={<RegistrationSuccess />} />
      <Route
        path="passwordchangedsuccess"
        element={<PasswordChangedMessage />}
      />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="required-docs" element={<RequiredDocs />} />
      <Route path="klicks" element={<Klicks />} />
      <Route path="klicks/:klickId" element={<Klicks />} />
      <Route path="happy-stories" element={<HappyStories />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />

      {/* Protected routes only open when user is logged-in */}
      <Route element={<ProtectedRoutes />}>
        <Route path="profile" element={<ProfileTabBar />}>
          <Route index path="matches" element={<MatchesPage />} />
          <Route path="myProfile" element={<MyProfile />} />
          {/* <Route element={<ExclusiveRoutes />}> */}
          <Route path="chat" element={<MyChat />} />
          {/* </Route> */}
          <Route path="favourite" element={<Favourites />} />
          <Route path="requests" element={<Requests />} />
          <Route path="contacts" element={<ContactRequestPage />} />
          <Route path="subscription" element={<MySubscription />} />
          <Route path="notifications" element={<NotificationBox />} />
          <Route path="settings" element={<Settings />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="blocked-profiles" element={<Blocked />} />
          <Route path="hide-delete-profile" element={<HideDeleteProfile />} />
          <Route path="report-user" element={<ReportUser />} />
          <Route path="numerology-match" element={<NumerologyMatch />} />
          <Route path="numerology-details" element={<NumerologyDetails />} />
          <Route path="kundli-match" element={<KundliMatch />} />
          <Route path="kundli-details" element={<KundliDetails />} />
          <Route path="profile-details" element={<FullProfile />} />
          <Route path="payment" element={<PaymentScreen />} />
          <Route path="referral-payment" element={<ReferralPayment />} />
          <Route path="coupon-payment" element={<CouponPayment />} />
          <Route path="payment-success" element={<PaymentSucessful />} />
        </Route>
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="blogs" element={<BlogLayout />}>
          <Route index element={<Blogs />} />
          <Route path=":slug" element={<BlogDetail />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ),
);
