import React from "react";
import Modal from "react-responsive-modal";
import "./showImage.css";
import { TPhotoType } from "../MyProfile";

interface ShowImageModalProps {
  isOpen: boolean;
  images: TPhotoType[];
  onClose: () => void;
  onDelete?: (id: string) => void;
}

const ShowImageModal: React.FC<ShowImageModalProps> = ({
  isOpen,
  images,
  onClose,
  onDelete,
}) => {
  console.log("delete image", images.length);
  return (
    <Modal
      classNames={{ modal: "myProfileModal" }}
      open={isOpen}
      onClose={onClose}
      center
    >
      <div className="modal-container">
        {images.map((image, index) => (
          <div className="image-container storesImagesContainer" key={index}>
            <img src={image.image} alt={`Show ${index}`} className="image" />
            {onDelete && images.length > 3 && (
              <button
                className="delete-button"
                onClick={() => onDelete(image.id)}
              >
                x
              </button>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ShowImageModal;
