import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import { TBasicProfileInfo, TProfileMessage } from "./ProfileBasicInfo";
import axiosClient from "../../../services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import * as constant from "../../../utils/constants";
import { TUserState, setBio } from "../../../state/features/loginSlice";

export type TAboutmeProfile = {
  AboutMe: string;
  ProfileManagedBy: string;
};

const ProfileAboutMe = ({ data }: TBasicProfileInfo) => {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useSelector((state: RootState) => state.user);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const [AboutmeInfoData, setAboutmeInfoData] = useState<string>(data?.Aboutme);
  const [nickName, setNickName] = useState(data?.Additional?.NickName);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) setAboutmeInfoData(data.Aboutme);
  }, [data.Aboutme]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateAboutmeInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        Aboutme: AboutmeInfoData,
        Additional: {
          NickName: nickName,
        },
      });
      if (true) {
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        setIsEdit(false);
        const profileDataa: TUserState = {
          bio: AboutmeInfoData,
        };
        dispatch(setBio(profileDataa));
      }
    } catch (err: any) {
      console.log("err", err);
    } finally {
    }
  };

  const validateAboutmeInfo = (): boolean => {
    if (AboutmeInfoData.length === 0) {
      setMsg({ message: "Invalid about me", isError: true });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> About Me</p>
          <div className="dflex alignItemCenter">
            {msg && msg.message && (
              <div className={msg.isError ? "error-msg" : "success-msg"}>
                <p>{msg.message}</p>
              </div>
            )}
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="textAreaBox">
                <textarea
                  disabled={!isEdit}
                  rows={4}
                  cols={80}
                  placeholder="Write about yourself (500 characters max)"
                  value={AboutmeInfoData}
                  onChange={(e) => setAboutmeInfoData(e.target.value as string)}
                >
                  {AboutmeInfoData}
                </textarea>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="grid3">
                <div>
                  <label> Profile managed by </label>
                  <select
                    disabled={!isEdit}
                    className="dropdown-menu"
                    defaultValue={AboutmeInfoData}
                    // onChange={(e) =>
                    //   setAboutmeInfoData((prev) => ({
                    //     ...prev,
                    //     ProfileManagedBy: e.target.value as string,
                    //   }))
                    // }
                  >
                    <option value="" disabled>
                      Profile Managed By
                    </option>
                    {constant.PROFILE_MANAGED_BY?.map((profile) => (
                      <option key={profile.id}>{profile.value}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label> Nickname </label>
                  <input
                    disabled={!isEdit}
                    type="text"
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value!)}
                    placeholder={data?.Username}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileAboutMe;
