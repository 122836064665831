import { useRegistrationState } from "./RegistrationProvider";
import EducationWorkForm from "./EducationWorkForm";
import AddressForm from "./AddressForm";
import PersonalForm from "./PersonalForm";
import FamilyForm from "./FamilyForm";
import AdditionalForm from "./AdditionalForm";

export default function RegistrationSteps() {
  const { stepIndex } = useRegistrationState();
  // const { stepIndex } = { stepIndex: 5 };
  switch (stepIndex) {
    case 1:
      return <PersonalForm />;
    case 2:
      return <EducationWorkForm />;
    case 3:
      return <AddressForm />;
    case 4:
      return <FamilyForm />;
    case 5:
      return <AdditionalForm />;
    default:
      return null;
  }
}
