import loading34icon from "../../assets/images/loading4icon.svg";
import loading4icon_heart from "../../assets/images/loading4icon_heart.svg";

export default function Loader() {
  return (
    <div>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100">
                <img src={loading34icon} className="imgRotate" />
                <img src={loading4icon_heart} className="loading4icon_heart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
