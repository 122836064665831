import React, { useState, useEffect } from "react";

type TTimerProps = {
  seconds: number;
  onComplete: () => void;
};

const Timer = ({ seconds, onComplete }: TTimerProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);

  useEffect(() => {
    if (remainingSeconds > 0) {
      const timer = setTimeout(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      onComplete(); // Perform action after countdown completes
    }
  }, [remainingSeconds, onComplete]);

  const minutes = Math.floor(remainingSeconds / 60);
  const secondsDisplay = remainingSeconds % 60;

  return (
    <div>
      <p>
        00:{minutes}:{secondsDisplay}
      </p>
    </div>
  );
};

export default Timer;
