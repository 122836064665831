import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import axiosClient from "../../../services/axiosInstance";
import { TGender } from "../../../types/base";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import NotificationBox from "../components/NotificationBoxCompo";
import ProfileCardCompo from "../components/ProfileCardCompo";

import { TProfileData } from "../../../types/register";
import { TUserState, setProfileData } from "../../../state/features/loginSlice";

export type TUserMatches = {
  _id: string;
  Username: string;
  FirstName: string;
  LastName: string;
  Photos: string;
  Video: string;
  CreatedAt: Date;
  isFavourite: boolean;
  DOB: Date;
  Gender: TGender;
  ExactIncome: number;
  City: string;
  State: string;
  EducationName: string;
  WorkTitle: string;
  PackageName: string;
};

const MatchesPage = () => {
  const [matches, setMatches] = useState<TUserMatches[]>([]);
  const { id } = useSelector((state: RootState) => state.user);
  const { packageName } = useSelector((state: RootState) => state.package);
  //const [isLoading, setIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const fetchedDocumentIds = new Set();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const dispatch = useDispatch();
  const [data, setData] = useState<TProfileData>({} as TProfileData);

  useEffect(() => {
    async function fetch() {
      setIsLoading(false);
      try {
        const res = await client.get(`/user/${id}`);
        if (true) {
          setData(res.data.result);
          //console.log("rresttt", res.data.result);

          const profileData: TUserState = {
            motherTongue: res.data.result.Personal.Mothertongue,
            nationality: res.data.result.Personal.Nationality,
            gender: res.data.result.Personal.Gender,
            photo: res.data.result.Photos && res.data.result?.Photos[0]["25px"],
            dob: res.data.result.Personal.Dob,
            height: res.data.result.Personal.Height,
            education: res.data.result.EducationWork.Education.EducationName,
            stateName: res.data.result.PermanentAddress.State,
            city: res.data.result.PermanentAddress.City,
            interest: res.data.result.Additional.Interest.toString(),
            job: res.data.result.EducationWork.Work.Occupation,
            bio: res.data.result.Aboutme,
          };

          // console.log("proffff", profileData);
          dispatch(setProfileData(profileData));
        }
      } catch (err: any) {
        console.log("catch", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, [id]);

  // UseEffects
  useEffect(() => {
    fetchMatches();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchMatches = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const currentPageNumber = pageNumber; // Capture the current page number
      console.log("pagenumber before call", currentPageNumber);

      const res = await client.post(
        `/user/show-matches?page=${currentPageNumber}`,
        {
          UserId: id,
        },
      );

      const matchesData = res?.data?.result;
      const newMatches = matchesData.filter(
        (x: TUserMatches) => !fetchedDocumentIds.has(x._id),
      );

      if (newMatches.length > 0) {
        setMatches((prevItems) => [...prevItems, ...newMatches]);
        matchesData.forEach((x: TUserMatches) => {
          fetchedDocumentIds.add(x._id);
        });
        setPageNumber((prevPage) => prevPage + 1); // Increment the page number only if new matches are added
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchMatches();
  };

  const handleReject = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    rejectUserId: string,
  ) => {
    e.preventDefault();
    try {
      const res = await client.post("/user/reject", {
        RejectTo: rejectUserId,
        RejectBy: id,
      });
      if (true) {
        const filteredMatches = matches.filter(
          (x: TUserMatches) => x._id != rejectUserId,
        );
        setMatches(filteredMatches);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const fetchSearchMatches = async () => {
    try {
      if (searchText?.length === 0) return;
      setPageNumber(1);
      setMatches([]);
      const res = await client.post(`/user/search/${searchText}`, {
        UserId: id,
      });
      if (true) {
        const matchesData = res?.data?.result;
        const newMatches = matchesData.filter(
          (x: TUserMatches) => !fetchedDocumentIds.has(x._id),
        );
        setMatches((prevItems) => [...prevItems, ...newMatches]);
        matchesData.forEach((x: TUserMatches) => {
          fetchedDocumentIds.add(x._id);
        });
      }
    } catch (err: any) {
      console.error("error while searching", err);
    } finally {
    }
  };

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);
    if (text.length === 0) {
      resetMatches();
    }
  };

  const resetMatches = async () => {
    setPageNumber(1);
    setMatches([]);
    fetchedDocumentIds.clear();
    await fetchMatches();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      fetchSearchMatches();
      e.preventDefault();
    }
  };

  return (
    <div>
      <Helmet>
        <title>Matches | Premmilan</title>
      </Helmet>
      <section className="MatchesPage">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {packageName.toLowerCase() === "exclusive" && (
                <div className="serachMatches">
                  <div className="password-container">
                    <input
                      id="username"
                      value={searchText}
                      onChange={onSearchTextChange}
                      onKeyDown={(e) => handleKeyDown(e)}
                      placeholder="Enter Username"
                      type="text"
                    />
                    <button
                      type="submit"
                      className="loginbtn"
                      onClick={fetchSearchMatches}
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}
              <br />
              {matches?.length > 0 ? (
                matches.map((match, index) => (
                  <ProfileCardCompo
                    key={index}
                    data={match}
                    handleReject={handleReject}
                  />
                ))
              ) : (
                <p>
                  <center>No Result Found</center>
                </p>
              )}

              {isLoading && (
                <center>
                  <p>Loading matches...</p>
                </center>
              )}
            </div>
            {!isMobile && (
              <div className="col-md-4">
                <NotificationBox />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MatchesPage;
