import React, { useEffect, useState } from "react";
import { TFamilyProfileType } from "../../../types/register";
import { TBasicProfileInfo, TProfileMessage } from "./ProfileBasicInfo";
import * as constant from "../../../utils/constants";
import Accordion from "react-bootstrap/Accordion";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import checkOk from "../../../../src/assets/images/checkOk.svg";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import axiosClient from "../../../services/axiosInstance";

const ProfileFamilyInfo = ({ data }: TBasicProfileInfo) => {
  const [isEdit, setIsEdit] = useState(false);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const { id } = useSelector((state: RootState) => state.user);
  const [familyInfoData, setFamilyInfoData] = useState<TFamilyProfileType>(
    {} as TFamilyProfileType,
  );

  useEffect(() => {
    if (data) setFamilyInfoData(data?.Family);
  }, [data?.Family]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateFamilyInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        Family: familyInfoData,
      });
      if (true) {
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        setIsEdit(false);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const validateFamilyInfo = (): boolean => {
    if (familyInfoData.FatherStatus?.length === 0) {
      setMsg({ message: "Invalid father status", isError: true });
      return false;
    }
    if (familyInfoData.MotherStatus?.length === 0) {
      setMsg({ message: "Invalid mother status", isError: true });
      return false;
    }
    if (familyInfoData.TotalBrothers < 0) {
      setMsg({ message: "Invalid no. of brother", isError: true });
      return false;
    }
    if (familyInfoData.TotalSisters < 0) {
      setMsg({ message: "Invalid no. of sister", isError: true });
      return false;
    }
    const constactRegex = /^[6-9]\d{9}$/;
    if (!constactRegex.test(familyInfoData.GuardianContact.toString())) {
      setMsg({ message: "Invalid contact number", isError: true });
      return false;
    }
    if (familyInfoData.GuardianContact === data.Personal.ContactNumber) {
      setMsg({ message: "Contact already used as personal", isError: true });
      return false;
    }

    return true;
  };

  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Family Info </p>
          <div className="dflex alignItemCenter">
            {msg && msg.message && (
              <div className={msg.isError ? "error-msg" : "success-msg"}>
                <p>{msg.message}</p>
              </div>
            )}
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Family type </label>
                    <select
                      disabled={!isEdit}
                      className="dropdown-menu"
                      defaultValue={familyInfoData?.FamilyType}
                      onChange={(e) =>
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          familyType: +e.target.value,
                        }))
                      }
                    >
                      {constant.FAMILYTYPES?.map((familytype) => (
                        <option key={familytype.id}>{familytype.value}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label> Father's status </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={familyInfoData?.FatherStatus}
                      onChange={(e) => {
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          FatherStatus: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div>
                    <label> Mother's status </label>
                    <input
                      disabled={!isEdit}
                      style={{ textTransform: "capitalize" }}
                      type="text"
                      value={familyInfoData?.MotherStatus}
                      onChange={(e) =>
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          MotherStatus: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> No. of brothers </label>
                    <input
                      disabled={!isEdit}
                      type="number"
                      value={familyInfoData?.TotalBrothers}
                      onChange={(e) =>
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          TotalBrothers: +e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <label> No. of sisters </label>
                    <input
                      disabled={!isEdit}
                      type="number"
                      value={familyInfoData?.TotalSisters}
                      onChange={(e) =>
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          TotalSisters: +e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <div className="flex">
                      <label> Family contact number </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled={!isEdit}
                      type="number"
                      value={familyInfoData?.GuardianContact}
                      onChange={(e) =>
                        setFamilyInfoData((prev) => ({
                          ...prev,
                          GuardianContact: +e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileFamilyInfo;
