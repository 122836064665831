import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import {
  TCurrentAddressProfile,
  TPermanentAddressProfile,
} from "../../../types/register";
import { TBasicProfileInfo, TProfileMessage } from "./ProfileBasicInfo";
import checkOk from "../../../../src/assets/images/checkOk.svg";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../services/axiosInstance";
import { TUserState, setAddressData } from "../../../state/features/loginSlice";

const ProfileAdressInfo = ({ data }: TBasicProfileInfo) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const { id } = useSelector((state: RootState) => state.user);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [currentAddressInfoData, setCurrentAddressInfoData] =
    useState<TCurrentAddressProfile>({} as TCurrentAddressProfile);

  const [permanentAddressInfoData, setPermanentAddressInfoData] =
    useState<TPermanentAddressProfile>({} as TPermanentAddressProfile);

  useEffect(() => {
    if (data) {
      setPermanentAddressInfoData(data.PermanentAddress);
    }
  }, [data.PermanentAddress]);

  useEffect(() => {
    if (data) {
      setCurrentAddressInfoData(data.CurrentAddress);
    }
  }, [data, data.CurrentAddress]);

  useEffect(() => {
    if (currentAddressInfoData?.IsSameAsPermanent) {
      setCurrentAddressInfoData((prev) => ({
        ...prev,
        Address: permanentAddressInfoData.Address,
        City: permanentAddressInfoData.City,
        Pincode: permanentAddressInfoData.Pincode,
        State: permanentAddressInfoData.State,
      }));
    }
  }, [
    permanentAddressInfoData?.Address,
    currentAddressInfoData?.IsSameAsPermanent,
  ]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateAddressInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        CurrentAddress: currentAddressInfoData,
        PermanentAddress: permanentAddressInfoData,
      });
      if (true) {
        const profileData: TUserState = {
          stateName: permanentAddressInfoData.State,
          city: permanentAddressInfoData.City,
        };
        dispatch(setAddressData(profileData));
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        setIsEdit(false);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const validateAddressInfo = (): boolean => {
    if (permanentAddressInfoData.Address.length === 0) {
      setMsg({ message: "Invalid permanent address", isError: true });
      return false;
    }
    if (permanentAddressInfoData.City.length === 0) {
      setMsg({ message: "Invalid permanent city", isError: true });
      return false;
    }
    if (permanentAddressInfoData.State.length === 0) {
      setMsg({ message: "Invalid permanent state", isError: true });
      return false;
    }
    const pincodeRegesx = /^[0-9]{6}$/;
    if (!pincodeRegesx.test(permanentAddressInfoData.Pincode.toString())) {
      setMsg({ message: "Invalid permanent pincode", isError: true });
      return false;
    }

    if (!currentAddressInfoData?.IsSameAsPermanent) {
      if (currentAddressInfoData.Address.length === 0) {
        setMsg({ message: "Invalid current address", isError: true });
        return false;
      }
      if (currentAddressInfoData.City.length === 0) {
        setMsg({ message: "Invalid current city", isError: true });
        return false;
      }
      if (currentAddressInfoData.State.length === 0) {
        setMsg({ message: "Invalid current state", isError: true });
        return false;
      }
      const pincodeRegesx = /^[0-9]{6}$/;
      if (!pincodeRegesx.test(currentAddressInfoData.Pincode.toString())) {
        setMsg({ message: "Invalid current pincode", isError: true });
        return false;
      }
    }
    return true;
  };

  return (
    <div className="addressInfo">
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Address Info </p>
          <div className="dflex alignItemCenter">
            {msg && msg.message && (
              <div className={msg.isError ? "error-msg" : "success-msg"}>
                <p>{msg.message}</p>
              </div>
            )}
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="heading">
            <p className="prsl-detail-heading addpadding">Permanent Address</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid2">
                  <div>
                    <div className="flex">
                      <label>House/Flat No. & Street address</label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={permanentAddressInfoData?.Address}
                      onChange={(e) =>
                        setPermanentAddressInfoData((prev) => ({
                          ...prev,
                          Address: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <div className="flex">
                      <label> Pincode </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled={!isEdit}
                      type="number"
                      value={permanentAddressInfoData?.Pincode}
                      onChange={(e) =>
                        setPermanentAddressInfoData((prev) => ({
                          ...prev,
                          Pincode: +e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <div className="flex">
                      <label> City </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={permanentAddressInfoData?.City}
                      onChange={(e) =>
                        setPermanentAddressInfoData((prev) => ({
                          ...prev,
                          City: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <div className="flex">
                      <label> State </label>
                      <img src={checkOk} alt="" />
                    </div>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={permanentAddressInfoData?.State}
                      onChange={(e) =>
                        setPermanentAddressInfoData((prev) => ({
                          ...prev,
                          State: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="heading">
            <p className="prsl-detail-heading">Current Address</p>
            <div className="chkbox">
              <input
                disabled={!isEdit}
                type="checkbox"
                defaultChecked={currentAddressInfoData?.IsSameAsPermanent}
                onChange={(e) => {
                  setCurrentAddressInfoData((prev) => ({
                    ...prev,
                    IsSameAsPermanent: e.target.checked,
                  }));
                }}
              />
              <label>Same as permanent address</label>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="grid2">
                    <div>
                      <div className="flex">
                        <label>House/Flat No. & Street address</label>
                        <img src={checkOk} alt="" />
                      </div>
                      <input
                        disabled={
                          currentAddressInfoData?.IsSameAsPermanent || !isEdit
                        }
                        type="text"
                        style={{ textTransform: "capitalize" }}
                        value={currentAddressInfoData?.Address}
                        onChange={(e) =>
                          setCurrentAddressInfoData((prev) => ({
                            ...prev,
                            Address: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div>
                      <div className="flex">
                        <label> Pincode </label>
                        <img src={checkOk} alt="" />
                      </div>
                      <input
                        disabled={
                          currentAddressInfoData?.IsSameAsPermanent || !isEdit
                        }
                        type="number"
                        value={currentAddressInfoData?.Pincode}
                        onChange={(e) =>
                          setCurrentAddressInfoData((prev) => ({
                            ...prev,
                            Pincode: +e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="grid3">
                    <div>
                      <div className="flex">
                        <label> City </label>
                        <img src={checkOk} alt="" />
                      </div>
                      <input
                        disabled={
                          currentAddressInfoData?.IsSameAsPermanent || !isEdit
                        }
                        type="text"
                        style={{ textTransform: "capitalize" }}
                        value={currentAddressInfoData?.City}
                        onChange={(e) =>
                          setCurrentAddressInfoData((prev) => ({
                            ...prev,
                            City: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div>
                      <div className="flex">
                        <label> State </label>
                        <img src={checkOk} alt="" />
                      </div>
                      <input
                        disabled={
                          currentAddressInfoData?.IsSameAsPermanent || !isEdit
                        }
                        type="text"
                        style={{ textTransform: "capitalize" }}
                        value={currentAddressInfoData?.State}
                        onChange={(e) =>
                          setCurrentAddressInfoData((prev) => ({
                            ...prev,
                            State: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileAdressInfo;
