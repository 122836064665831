import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useRegistrationState } from "./RegistrationProvider";
import uploadico from "./../../../assets/images/uploadTicn.svg";
import icon from "./../../../assets/images/error.svg";
import axiosClient from "../../../services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import Loader from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import * as constant from "../../../utils/constants";
import ImagePreviewModal from "./Modals/ImagePreviewModal";
import { resetRegisger } from "../../../state/features/registerSlice";
import {
  TDocs,
  TErrMsg,
  TSizeError,
  TViewImages,
} from "../../../types/requiredDoc";
import { validatePhotoSize } from "../../../utils/helper";
import { useLoader } from "../../Root";

const initialDocs: TDocs = {
  passport: [],
  pancard: [],
  letter: [],
  govtcert: [],
};

const InitialErrMsg: TErrMsg = {
  passportErr: false,
  pancardErr: false,
  occupationErr: false,
};

const InitialSizeErrMsg: TSizeError = {
  passportErr: false,
  pancardErr: false,
  occupationErr: false,
};

const InitialIsViewState: TViewImages = {
  isPancardView: false,
  isPassportView: false,
  isCertView: false,
  isLetterView: false,
};

export default function RequiredDocs() {
  const largeSizeErrMsg = "Uploded file size must be between 100KB to 5MB";
  const [isView, setIsView] = useState<TViewImages>(InitialIsViewState);
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<TErrMsg>(InitialErrMsg);
  const [sizeErr, setSizeErr] = useState<TSizeError>(InitialSizeErrMsg);
  const [isBusiness, setIsBusiness] = useState<boolean | null>(null);
  const [images, setImages] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [allDocs, setAllDocs] = useState<TDocs>(initialDocs);
  const { userId } = useSelector((state: RootState) => state.register);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const { token } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TDocs>({
    defaultValues: initialDocs,
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (!isLoggedIn && !userId) {
      // navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isBusiness !== null) {
      if (isBusiness) {
        setAllDocs((prev) => ({
          ...prev,
          letter: [],
        }));
      } else {
        setAllDocs((prev) => ({
          ...prev,
          govtcert: [],
        }));
      }
    }
  }, [isBusiness]);

  useEffect(() => {
    if (images.length === 0) onClosePreviewImageModal();
  }, [images]);

  useEffect(() => {
    if (isView.isPassportView) setImages(allDocs.passport);
    else if (isView.isPancardView) setImages(allDocs.pancard);
    else if (isView.isLetterView) setImages(allDocs.letter);
    else if (isView.isCertView) setImages(allDocs.govtcert);
  }, [isView]);

  function fileListToArray(fileList: FileList) {
    const filesArray = [];
    for (let i = 0; i < fileList.length; i++) {
      filesArray.push(fileList[i]);
    }
    return filesArray;
  }

  const onCompleteDocUpload = () => {
    setAllDocs(initialDocs);
    if (userId) {
      dispatch(resetRegisger());
      navigate("/registrationsuccess", { replace: true });
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 2500);
    } else {
      navigate("/profile", { replace: true });
    }
  };
  const onHandleSkip = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      onCompleteDocUpload();
    } catch (err: any) {
    } finally {
    }
  };

  const onHandleFormSubmit = async (data: TDocs) => {
    if (
      isBusiness === null &&
      (allDocs.letter.length === 0 || allDocs.govtcert.length === 0)
    ) {
      setErr((prev) => ({ ...prev, occupationErr: true }));
      return;
    }

    if (allDocs.pancard.length === 0) {
      setErr((prev) => ({ ...prev, pancardErr: true }));
      return;
    }
    if (allDocs.passport.length === 0) {
      setErr((prev) => ({ ...prev, passportErr: true }));
      return;
    }

    const client = axiosClient(token, "Multipart");
    try {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append("UserId", userId!);
      for (let i = 0; i < allDocs.passport.length; i++)
        formdata.append("Passport", allDocs.passport[i]);
      for (let i = 0; i < allDocs.pancard.length; i++)
        formdata.append("PAN", allDocs.pancard[i]);
      for (let i = 0; i < allDocs.letter.length; i++)
        formdata.append("OfferLetter", allDocs.letter[i]);
      for (let i = 0; i < allDocs.govtcert.length; i++)
        formdata.append("GovtCert", allDocs.govtcert[i]);

      const res = await client.put("/user/upload-docs", formdata);
      if (true) {
        setIsLoading(false);
        dispatch(resetRegisger());
        onCompleteDocUpload();
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const onClosePreviewImageModal = () => {
    if (isView.isPassportView)
      setAllDocs((prev) => ({
        ...prev,
        passport: [...images],
      }));
    else if (isView.isPancardView)
      setAllDocs((prev) => ({
        ...prev,
        pancard: [...images],
      }));
    else if (isView.isLetterView)
      setAllDocs((prev) => ({
        ...prev,
        letter: [...images],
      }));
    else if (isView.isCertView)
      setAllDocs((prev) => ({
        ...prev,
        govtcert: [...images],
      }));
    setIsModalOpen(false);
  };

  const removeImageByIndex = (index: number) => {
    const updatedPreviewImages = images ? [...images!] : [];
    updatedPreviewImages.splice(index, 1);
    setImages(updatedPreviewImages);
  };

  const onRemoveImage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    removeImageByIndex(index);
  };

  const onRemoveAllImages = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setImages([]);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="requiredDocsPage">
      {images?.length && (
        <ImagePreviewModal
          isOpen={isModalOpen}
          onRequestClose={onClosePreviewImageModal}
          images={images}
          onRemove={onRemoveImage}
          onRemoveAll={onRemoveAllImages}
        />
      )}

      <div className="family">
        <div className="registrainHeader">
          <Helmet>
            <title>Required Docs | Premmilan</title>
          </Helmet>
          <div className="container py25">
            <div className="row alignItemCenter">
              <div className="col-md-4">
                <div className="reg-logo">
                  <img
                    src={require("../../../assets/images/Prem-Milan-Logo.png")}
                    alt="logo"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="reg-centr-txt">
                  <h1>Kindly Submit</h1>
                  <p>The required documents</p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="reg-right-txt">
                  <h2>
                    Rishta
                    <br />
                    UmarBhar Ka
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div className="re">
                  <div className="prsl-detail">
                    <form
                      id="msform"
                      onSubmit={handleSubmit(onHandleFormSubmit)}
                    >
                      <fieldset>
                        <div className="form-card">
                          <div className="upltxt_field">
                            <input
                              disabled
                              value={state?.address}
                              type="text"
                              placeholder="House/Flat No. & Street address"
                              className="uploadinput"
                            />
                            <div className="dflex upldicn">
                              <input
                                {...register("passport")}
                                onChange={(e) => {
                                  const files = fileListToArray(
                                    e.target.files!,
                                  );
                                  if (validatePhotoSize(files)) {
                                    setSizeErr((prev) => ({
                                      ...prev,
                                      passportErr: true,
                                    }));
                                    return;
                                  }
                                  setAllDocs((prev) => ({
                                    ...prev,
                                    passport: [...prev.passport, ...files],
                                  }));
                                }}
                                type="file"
                                id="file-5"
                                className="custom-file-input"
                                accept="image/jpeg, image/png, image/jpg"
                                multiple
                              />
                              <label className="custom-file-label">
                                <img src={uploadico} width="30" />
                              </label>
                            </div>
                            <div>
                              <div className="red">
                                {allDocs?.passport?.length ? (
                                  <div className="viewBtnDiv">
                                    <button
                                      className="btn-trans"
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsView({
                                          ...InitialIsViewState,
                                          isPassportView: true,
                                        });
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      View
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="error-msg dflex">
                                      <img src={icon} /> Upload Passport for
                                      verification (Current Address)
                                    </div>
                                    {sizeErr.passportErr && (
                                      <div className="error-msg dflex">
                                        <img src={icon} /> {largeSizeErrMsg}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="upltxt_field">
                            <input
                              disabled
                              value={state?.income}
                              type="text"
                              placeholder="Annual Income"
                              className="uploadinput"
                            />
                            <div className="dflex upldicn">
                              <input
                                {...register("pancard")}
                                onChange={(e) => {
                                  const files = fileListToArray(
                                    e.target.files!,
                                  );
                                  if (validatePhotoSize(files)) {
                                    setSizeErr((prev) => ({
                                      ...prev,
                                      pancardErr: true,
                                    }));
                                    return;
                                  }
                                  setAllDocs((prev) => ({
                                    ...prev,
                                    pancard: [...prev.pancard, ...files],
                                  }));
                                }}
                                type="file"
                                id="file-5"
                                className="custom-file-input"
                                accept="image/jpeg, image/png, image/jpg"
                                multiple
                              />
                              <label className="custom-file-label">
                                <img src={uploadico} width="30" />
                              </label>
                            </div>
                            <div>
                              <div className="red">
                                {allDocs?.pancard?.length ? (
                                  <div className="viewBtnDiv">
                                    <button
                                      className="btn-trans"
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsView({
                                          ...InitialIsViewState,
                                          isPancardView: true,
                                        });
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      View
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="error-msg dflex">
                                      <img src={icon} /> Upload PAN Card for
                                      verification
                                    </div>
                                    {sizeErr.pancardErr && (
                                      <div className="error-msg dflex">
                                        <img src={icon} /> {largeSizeErrMsg}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <select
                              className="dropdown-menu"
                              defaultValue=""
                              onChange={(e) => {
                                setErr((prev) => ({
                                  ...prev,
                                  occupationErr: false,
                                }));
                                setIsBusiness(e.target.value === "Business");
                              }}
                            >
                              <option value="" disabled>
                                Occupation Type*
                              </option>
                              {constant.OCCUPATIONTYPES.map((occupation) => (
                                <option key={occupation.id}>
                                  {occupation.value}
                                </option>
                              ))}
                            </select>
                            {err?.occupationErr && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                Select occupation type
                              </div>
                            )}
                          </div>
                          <div className="upltxt_field">
                            {isBusiness !== null && (
                              <input
                                disabled
                                value={state?.occupation}
                                type="text"
                                placeholder="Occupation"
                                className="uploadinput"
                              />
                            )}
                            <div className="dflex upldicn">
                              {isBusiness !== null && (
                                <div className="dflex justifyContentEnd">
                                  {isBusiness === true ? (
                                    <input
                                      {...register("govtcert")}
                                      onChange={(e) => {
                                        const files = fileListToArray(
                                          e.target.files!,
                                        );
                                        if (validatePhotoSize(files)) {
                                          setSizeErr((prev) => ({
                                            ...prev,
                                            occupationErr: true,
                                          }));
                                          return;
                                        }
                                        setAllDocs((prev) => ({
                                          ...prev,
                                          govtcert: [
                                            ...prev.govtcert,
                                            files[0],
                                          ],
                                        }));
                                      }}
                                      type="file"
                                      id="file-5"
                                      className="custom-file-input"
                                      accept="image/jpeg, image/png, image/jpg"
                                      multiple
                                    />
                                  ) : (
                                    <input
                                      {...register("letter")}
                                      onChange={(e) => {
                                        const files = fileListToArray(
                                          e.target.files!,
                                        );
                                        if (validatePhotoSize(files)) {
                                          setSizeErr((prev) => ({
                                            ...prev,
                                            occupationErr: true,
                                          }));
                                          return;
                                        }
                                        setAllDocs((prev) => ({
                                          ...prev,
                                          letter: [...prev.letter, files[0]],
                                        }));
                                      }}
                                      type="file"
                                      id="file-5"
                                      className="custom-file-input"
                                      accept="image/jpeg, image/png, image/jpg"
                                      multiple
                                    />
                                  )}

                                  {isBusiness !== null && (
                                    <label className="custom-file-label">
                                      <img src={uploadico} width="30" />
                                    </label>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            {isBusiness !== null && (
                              <div className="red">
                                {isBusiness !== null && (
                                  <div className="error-msg dflex">
                                    {isBusiness === true ? (
                                      <div>
                                        {allDocs?.govtcert?.length ? (
                                          <div className="viewBtnDiv">
                                            <button
                                              className="btn-trans"
                                              type="button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setIsView({
                                                  ...InitialIsViewState,
                                                  isCertView: true,
                                                });
                                                setIsModalOpen(true);
                                              }}
                                            >
                                              View
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <p className="dflex">
                                              <img src={icon} />
                                              Upload 1st page of Govt.
                                              certificate
                                            </p>
                                            {sizeErr.occupationErr && (
                                              <div className="error-msg dflex">
                                                <img src={icon} />{" "}
                                                {largeSizeErrMsg}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        {allDocs?.letter?.length ? (
                                          <div className="viewBtnDiv">
                                            <button
                                              className="btn-trans"
                                              type="button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setIsView({
                                                  ...InitialIsViewState,
                                                  isLetterView: true,
                                                });
                                                setIsModalOpen(true);
                                              }}
                                            >
                                              View
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <p className="dflex">
                                              <img src={icon} />
                                              Upload 1st page of Offer Letter
                                            </p>
                                            {sizeErr.occupationErr && (
                                              <div className="error-msg dflex">
                                                <img src={icon} />
                                                {largeSizeErrMsg}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="terms">
                            <p>
                              {" "}
                              Please keep this docs with you during registration
                              process{" "}
                            </p>
                            <ul>
                              <li>
                                Passport
                                <span className="customAestrics"> * </span> |
                                PAN Card{" "}
                                <span className="customAestrics"> * </span>
                              </li>
                              <li>
                                Offer Letter (If you are doing a job)
                                <span className="customAestrics"> * </span>
                              </li>

                              <li>
                                Govt. Certificate (If you are doing a business)
                                <span className="customAestrics"> * </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="reg-forms">
                          <div className="divider"></div>
                          <div className="btn-flex">
                            <div className="left-btn">
                              <button
                                type="button"
                                className="btn-trans"
                                onClick={onHandleSkip}
                              >
                                Skip
                              </button>
                            </div>
                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className="cont-btn commonBtn"
                            >
                              Continue &nbsp;
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
}
