import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TPackageState = {
  expireDate: string;
  packageName: string;
  requestPerDay: number;
  packageId: string;
};

const initialState: TPackageState = {
  expireDate: "",
  packageName: "",
  requestPerDay: 0,
  packageId: "",
};

const PackageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setPackageData: (state, action: PayloadAction<TPackageState>) => {
      return { ...state, ...action.payload };
    },

    resetPackage: () => initialState,
  },
});

export const { setPackageData, resetPackage } = PackageSlice.actions;
export default PackageSlice.reducer;
