const TermCondition = () => {
  return (
    <div>
      <h2>Terms & Conditions</h2>
      <p>
        <strong>THIS AGREEMENT WAS LAST UPDATED ON August 5, 2024</strong>{" "}
      </p>
      <p>
        Please read the following User Agreement. When you sign up for any
        service within premmilan<sup style={{ fontSize: 12 }}>®</sup>.com, all
        of which are hereinafter referred to collectively as the "Website", you
        agree to all of the terms and conditions of this Agreement. Please read
        the following terms and conditions carefully, as they form the agreement
        between us. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MAY
        NOT USE THE WEBSITE, AND SHOULD NOT PROCEED TO REGISTER. BY USING THE
        WEBSITE YOU ARE AGREEING TO BE BOUND BY THIS USER AGREEMENT, INCLUDING
        ALL AMENDMENTS MADE TO DATE.{" "}
      </p>
      <p>
        <br />
        <strong>TERMS AND CONDITIONS OF THE WEBSITE </strong>
      </p>
      <p>
        <strong>User Agreement</strong>
      </p>
      <p>
        This Agreement constitutes your agreement with premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com("premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com") with respect to your use of
        the Website. You must agree to abide by all of the terms and conditions
        contained in this Agreement in order to become or remain an authorized
        user of the Website. As used in this Agreement, "we" and "us" means
        premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com, or any successor or assignee
        of premmilan<sup style={{ fontSize: 12 }}>®</sup>.com .{" "}
      </p>
      <p>
        <strong>Website Agreement </strong>
      </p>
      <p>
        <strong>Right to Use.</strong>
      </p>
      <p>
        You represent, warrant and covenant that you are at least 18 years old.
        This Website is addressed to adults only. By using the Website you agree
        that you are 18 years of age and older. Failure to comply with this
        Agreement may result in legal actions.{" "}
      </p>
      <p>
        <strong>Adult</strong>
      </p>
      <p>
        You represent, warrant and covenant that you are at least 18 years old.
        This Website is addressed to adults only. By using the Website you agree
        that you are 18 years of age and older. Failure to comply with this
        Agreement may result in legal actions.{" "}
      </p>
      <p>
        <strong>Code of Conduct</strong>.{" "}
      </p>
      <p>
        <strong>
          You agree to use the Website in accordance with the following Code of
          Conduct:{" "}
        </strong>{" "}
        <br />
        1) You are solely responsible for any information that you display when
        using the Website or to other members. You will keep all information
        provided to you through the Website as private and confidential and will
        not give such information to anyone without the permission of the person
        who provided it to you;
        <br />
        <br />
        2) You will not use the Website to engage in any form of harassment or
        offensive behavior, including but not limited to the posting of
        communications, pictures or recordings, which contain libelous,
        slanderous, abusive or defamatory statements, or racist, pornographic,
        obscene,&nbsp;or offensive language;
        <br />
        <br />
        3) You will not forward chain letters through the Website;
        <br />
        <br />
        4) You will not use the Website to infringe the privacy rights, property
        rights, or other civil rights of any person;
        <br />
        <br />
        5) You will not post messages, pictures or recordings or use the Website
        in any way which violates, plagiarizes or infringes upon the rights of
        any third party, including but not limited to any copyright or trademark
        law, privacy or other personal or proprietary rights, or is fraudulent
        or otherwise unlawful or violates any law.
        <br />
        &nbsp;Your use of the Website is for your own personal use. You may not
        authorize others to use the Website and you may not transfer your
        accounts with other users.
        <br />
        <br />
        6) You will not use the Website to distribute, promote or otherwise
        publish any material containing any solicitation for funds, advertising
        or solicitation for goods or websites.
        <br />
        <br />
        7) You are aware that the Website may contain explicit adult oriented
        materials provided by consenting users.
      </p>
      <p>
        <strong>Privacy and Use of Information</strong>
      </p>
      <p>
        <strong>You acknowledge that</strong>
        <br />
        <br />
        (1) We cannot ensure the security or privacy of information you provide
        through the Internet and your email messages, and you release us from
        any and all liability in connection with the use of such information by
        other parties;
        <br />
        <br />
        (2) We are not responsible for, and cannot control, the use by others of
        any information which you provide to them and you should use caution in
        selecting the personal information you provide to others through the
        Website.
        <br />
        <br />
        (3) We cannot assume any responsibility for the content of messages sent
        by other users of the Website, and you release us from any and all
        liability &nbsp;in connection with the contents of any communications
        you may receive from other users.
        <br />
        <br />
        (d) You acknowledge that you can not sue premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com or any of its employees for
        any damages done to you through the site. <br />
        We cannot guarantee, and assume no responsibility for verifying, the
        accuracy of the information provided by other users of the Website. You
        may not use the Website for any unlawful purpose.{" "}
      </p>
      <p>
        <strong>Monitoring of Information.</strong>
      </p>
      <p>
        We reserve the right to monitor all advertisements, public postings and
        messages to ensure that they conform to the content guidelines, which
        may be applicable from time to time. We also reserve the right to
        monitor all messages and chats that take place through the site. We are
        not responsible for any offensive or obscene materials that may be
        transmitted or posted by other users (including unauthorized users as
        well as the possibility of "hackers"). We are also not responsible for
        the use of any personal information that the user may choose to post on
        the Website.{" "}
      </p>
      <p>
        <strong>Removal of Information.</strong>
      </p>
      <p>
        While we do not and cannot review every message or other material posted
        or sent by users of the Website, and are not responsible for any content
        of these messages or materials, we reserve the right, but are not
        obligated, to delete, move, or edit messages or materials, that we, in
        our sole discretion, deem to violate the Code of Conduct set out above
        or any applicable content guidelines, or to be otherwise unacceptable.
        You shall remain solely responsible for the content of advertisements,
        public postings, messages and other materials you may upload to the
        Website or users of the Website.{" "}
      </p>
      <p>
        <strong>Termination of Access to Website.</strong>
      </p>
      <p>
        We may, in our sole discretion, terminate or suspend your access to all
        or part of the Website at any time, with or without notice, for any
        reason, including, without limitation, breach of this Agreement. Without
        limiting the generality of the foregoing, any fraudulent, abusive, or
        otherwise illegal activity may be grounds for termination of your access
        to all or part of the Website at our sole discretion, and you may be
        referred to appropriate law enforcement agencies.{" "}
      </p>
      <p>
        <strong>Online Cancellation</strong>
      </p>
      <p>
        Any request for cancellation will be met with written support as fast as
        possible.
        <br />
        You accept that when you cancel your membership with The Website you are
        automatically locked out of the site and your access is immediately
        terminated. You also agree and accept the complete and final loss of
        your profile, mail, and any other membership information that you should
        have. This information cannot be reclaimed or transferred to any third
        party. If you later change you decision you must reregister and pay for
        a new membership. However previous registration information will remain
        lost.
      </p>
      <p>
        <strong>Proprietary Information</strong>.
      </p>
      <p>
        The Website contains information, which is proprietary to us and to our
        users. We assert full copyright protection in the website. Information
        posted by us or other users of the Website may be protected whether or
        not it is identified as proprietary to us or to them. You agree not to
        modify, copy or distribute any such information in any manner whatsoever
        without having first received the express permission of the owner of
        such information.{" "}
      </p>
      <p>
        <strong>No responsibility.</strong>
      </p>
      <p>
        We are not responsible for any incidental, consequential, special,
        punitive, exemplary, direct or indirect damages of any kind whatsoever,
        which may arise out of or relate to your use of the Website, including
        but not limited to lost revenues, profits, business or data, or damages
        resulting from any viruses, worms, "Trojan horses" or other destructive
        software or materials, or communications by you or other users of the
        Website, or any interruption or suspension of the Website, regardless of
        the cause of the interruption or suspension. Any claim against us shall
        be limited to the amount you paid, if any, for use of the Website during
        the contract period less the time used. We may discontinue or change the
        Website or its availability to you at any time, and you may stop using
        the Website at any time.
      </p>
      <p>
        <strong>Security</strong>
      </p>
      <p>
        Your account is private and should not be used by anyone else. You are
        responsible for all usage or activity on the Website by users using your
        password, including but not limited to use of your password by any third
        party.{" "}
      </p>
      <p>
        <strong>Other Links</strong>
      </p>
      <p>
        The Website may from time to time contain links and or banners to other
        sites and resources ("External Links"). We are not responsible for, and
        have no liability as a result of, the availability of External Links or
        their contents
      </p>
      <p>
        <strong>Indemnity</strong>
      </p>
      <p>
        You agree to indemnify us, our officers, directors, employees and
        agents, from any loss or damages, including without limitation
        reasonable legal fees, which we may suffer from your activities on or
        use of the Website, including without limitation any breach by you of
        this Agreement or any charges or complaints made by other parties
        against you. You shall cooperate as fully as reasonably required in the
        defense of any claim. We reserve the right to assume the exclusive
        defense and control of any matter otherwise subject to indemnification
        by you; provided, however, that you shall remain liable for any such
        claim.{" "}
      </p>
      <p>
        <strong>No Warranties</strong>
      </p>
      <p>
        The Website is distributed on an "as is" basis. We do not warrant that
        this Website will be uninterrupted or error-free. There may be delays,
        omissions, and interruptions in the availability of the Website. Where
        permitted by law, you acknowledge that the Website is provided without
        any warranties of any kind whatsoever, either express or implied,
        including but not limited to the implied warranties of merchantability
        and fitness for a particular purpose. You acknowledge that use of the
        Website is at your own risk. We do not represent or endorse the accuracy
        or reliability of any advice, opinion, statement or other information
        displayed, uploaded or distributed through the Website by premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com or any user of the Website or
        any other person or entity. You acknowledge that any reliance upon any
        such opinion, advice, statement or information shall be at your sole
        risk.{" "}
      </p>
      <p>
        <strong>Modifications</strong>
      </p>
      <p>
        We may modify this Agreement from time to time. Notification of changes
        in this Agreement will be posted on the Website or sent via electronic
        mail, as we may determine in our sole discretion. If you do not agree to
        any modifications, you should terminate your use of the Website. Your
        continued use of the Website now, or following the posting of notice of
        any changes in this Agreement, will constitute a binding acceptance by
        you of this Agreement, or any subsequent modifications
      </p>
      <p>
        <strong>Disclosure and Other Communication</strong>
      </p>
      <p>
        We reserve the right to send electronic mail to you, for the purpose of
        informing you of changes or additions to the Website, or of any
        premmilan
        <sup style={{ fontSize: 12 }}>®</sup>.com related products and
        websites. We reserve the right to disclose information about your usage
        of the Website and Demographics in forms that do not reveal your
        personal identity. We reserve the right to disclose all information
        regarding your usage to law enforcement officers on first request.
      </p>
      <p>
        <strong>Complaints</strong>
      </p>
      <p>
        To resolve or report a complaint regarding the Website or members who
        use the Website users should write to{" "}
        <a href="https://premmilan.com/contact-us">our online support</a> .
        Immediate actions will take place in order to help solve the problem.
      </p>
    </div>
  );
};

export default TermCondition;
