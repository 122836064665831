// UploadImageModal.tsx
import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "./uploadImage.css"; // Import your CSS file

interface UploadImageModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onImageUpload: (file: File) => void;
}

const UploadImageModal: React.FC<UploadImageModalProps> = ({
  isOpen,
  onRequestClose,
  onImageUpload,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageURL, SetImageURL] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      SetImageURL(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onImageUpload(selectedFile);
      setSelectedFile(null);
      SetImageURL(null);
      onRequestClose();
    }
  };

  return (
    <div className="myprofileImagesUpload file_names">
      <Modal
        classNames={{ modal: "myProfileUplodimgModal" }}
        open={isOpen}
        onClose={onRequestClose}
        center
      >
        <h2>Upload Image</h2>
        <div className="uploadBox">
          {/* {imageURL && <img src="{imageURL}" height={50} width={50} />} */}
          {selectedFile && (
            <>
              <label style={{ color: "red" }}> Selected Image:</label>
              <label style={{ color: "green" }}> {selectedFile.name}</label>
            </>
          )}
          <label
            htmlFor="et_pb_contact_brand_file_request_0"
            className="et_pb_contact_form_label"
          >
            Choose a file or drag it here.
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            className=""
            accept="image/jpeg, image/png, image/jpg"
          />
          <button onClick={handleUpload} disabled={!selectedFile}>
            Upload
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UploadImageModal;
