import loading1icon from "../../../assets/images/loading1icon.svg";

export default function PasswordChangedMessage() {
  return (
    <div>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100">
                <img alt="loadingIcon" src={loading1icon} />
              </div>
            </div>
          </div>
        </div>
        <p className="ftrText"> Password changed successfully! </p>
      </div>
    </div>
  );
}
