import { TRegisterInfo, TUserInterest } from "../../../types/register";
import { createContext, ReactNode, useContext, useState } from "react";
type TRegistrationContextProp = {
  children: ReactNode;
};

type TRegistrationContext = {
  onHandleNext: () => void;
  onHandleBack: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  stepIndex: number;
  onSetInterest: (interest: TUserInterest[]) => void;
  interestList: TUserInterest[];
  recordedVideo: Blob;
  onSetRecordedVideo: (video: Blob) => void;
  photos: File[];
  onSetPhotos: (photos: File[]) => void;
  registrationData: TRegisterInfo;
  setRegistrationData: React.Dispatch<React.SetStateAction<TRegisterInfo>>;
};

const RegistrationContext = createContext<TRegistrationContext>(
  {} as TRegistrationContext,
);

export default function RegistrationProvider({
  children,
}: TRegistrationContextProp) {
  const [stepIndex, setStepIndex] = useState(1);
  const [recordedVideo, setRecordedVideo] = useState<Blob>(new Blob());
  const [interestList, setInterestList] = useState<TUserInterest[]>([]);
  const [photos, setPhotos] = useState<File[]>([]);
  const [registrationData, setRegistrationData] = useState({} as TRegisterInfo);

  function onHandleNext() {
    setStepIndex((prev) => {
      return prev + 1;
    });
  }

  function onSetInterest(interest: TUserInterest[]) {
    setInterestList(interest);
  }

  function onSetRecordedVideo(video: Blob) {
    setRecordedVideo(video);
  }

  function onSetPhotos(photos: File[]) {
    setPhotos(photos);
  }

  function onHandleBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setStepIndex((prev) => {
      return prev - 1;
    });
  }

  return (
    <RegistrationContext.Provider
      value={{
        photos,
        onSetPhotos,
        interestList,
        onSetInterest,
        recordedVideo,
        onSetRecordedVideo,
        stepIndex,
        registrationData,
        setRegistrationData,
        onHandleNext,
        onHandleBack,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}

export function useRegistrationState() {
  return useContext(RegistrationContext);
}
