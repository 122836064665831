import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export function NumerologyHeader() {
  const navigate = useNavigate();
  return (
    <div className="registrainHeader">
      <Helmet>
        <title>Numerology Matchmaking | Premmilan</title>
      </Helmet>
      <div className="container py25">
        <div className="row alignItemCenter">
          <div className="col-md-3">
            <div className="reg-logo">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/", { replace: false });
                }}
                src={require("../../../assets/images/Prem-Milan-Logo.png")}
                alt="logo"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="reg-centr-txt">
              <h1>Numerology Matchmaking</h1>
              <p>
                Find Your Perfect Equation for Love with Numerology Matchmaking
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="reg-right-txt">
              <h2>
                Rishta
                <br />
                UmarBhar Ka
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
