import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import paymentSuccessful from "../../../assets/images/paymentSuccesful.svg";
import whiteArrow from "../../../assets/images/whiteArrow.svg";

export default function PaymentSuccessful() {
  return (
    <div>
      <Helmet>
        <title>Payment Successful | Premmilan</title>
      </Helmet>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100">
                <div className="errorPageMsg">
                  <img src={paymentSuccessful} alt="paymentSuccessful" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ftrText">
          <p className="ftrTextTitle"> Payment successfully completed </p>
          <Link to="/">
            Go Back to Home page <img src={whiteArrow} alt="backArrow"></img>
          </Link>
        </div>
      </div>
    </div>
  );
}
