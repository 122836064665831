import Accordion from "react-bootstrap/Accordion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./style.css";
import { KundliMatchHeader } from "./KundliMatchHeader";
import { useLocation, useNavigate } from "react-router-dom";

type TKundliKoot = {
  varna: string;
  vasya: string;
  tara: string;
  yoni: string;
  graha_maitri: string;
  gana: string;
  bhakoot: string;
  nadi: string;
};

type TKundliLord = {
  id: number;
  name: string;
  vedic_name: string;
};

type TKundliMessage = {
  type: string;
  description: string;
};

type TKundliGunaMilan = {
  total_points: number;
  maximum_points: number;
};

type TKundliNakshetra = {
  id: number;
  name: string;
  lord: TKundliLord;
  pada: number;
};

type TKundliRasi = {
  id: number;
  name: string;
  lord: TKundliLord;
};

export type TKundliDetails = {
  girl_info: {
    koot: TKundliKoot;
    nakshatra: TKundliNakshetra;
    rasi: TKundliRasi;
  };
  boy_info: {
    koot: TKundliKoot;
    nakshatra: TKundliNakshetra;
    rasi: TKundliRasi;
  };
  message: TKundliMessage;
  guna_milan: TKundliGunaMilan;
};

const KundliDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="NumerologyDetails kundaliDetails">
        <KundliMatchHeader />
        <section className="MyProfile">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="back-btn">
                  <button
                    className="btn-trans"
                    type="button"
                    onClick={handleBack}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="profileImgBox">
                  <Swiper className="mySwiper">
                    <SwiperSlide>
                      <div className="profileImgInrBox">
                        <img
                          src="https://premmilan.s3.ap-south-1.amazonaws.com/utils/9230245-kundaliMatchMaking_coverImage.png"
                          alt="kundli-image"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName"> Your Information </p>
                        </div>
                      </Accordion.Header>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Koot </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> Varna </label>
                                        <div>
                                          {state?.boy_info?.koot?.varna}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Yoni </label>
                                        <div>
                                          {" "}
                                          {state?.boy_info?.koot?.yoni}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Bhakoot </label>
                                        <div>
                                          {state?.boy_info?.koot?.bhakoot}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> Vasya </label>
                                        <div>
                                          {" "}
                                          {state?.boy_info?.koot?.vasya}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Graha Maitri </label>
                                        <div>
                                          {state?.boy_info?.koot?.graha_maitri}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Nadi </label>
                                        <div>
                                          {" "}
                                          {state?.boy_info?.koot?.nadi}{" "}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> Tara </label>
                                        <div>
                                          {" "}
                                          {state?.boy_info?.koot?.tara}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Gana </label>
                                        <div>
                                          {" "}
                                          {state?.boy_info?.koot?.gana}{" "}
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Nakshatra </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {state?.boy_info?.nakshatra?.id}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {state?.boy_info?.nakshatra?.name}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Pada </label>
                                        <div>
                                          {state?.boy_info?.nakshatra?.pada}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="dividerLine"></div>

                                    <div className="acdinNameBox dflex">
                                      <p className="acdinName"> Lord </p>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {state?.boy_info?.nakshatra?.lord?.id}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {
                                            state?.boy_info?.nakshatra?.lord
                                              ?.name
                                          }
                                        </div>
                                      </div>
                                      <div>
                                        <label> Vedic Name </label>
                                        <div>
                                          {
                                            state?.boy_info?.nakshatra?.lord
                                              ?.vedic_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Rasi </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div> {state?.boy_info?.rasi?.id} </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>{state?.boy_info?.rasi?.name}</div>
                                      </div>
                                    </div>

                                    <div className="dividerLine"></div>

                                    <div className="acdinNameBox dflex">
                                      <p className="acdinName"> Lord </p>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {state?.boy_info?.rasi?.lord?.id}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {state?.boy_info?.rasi?.lord?.name}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Vedic Name </label>
                                        <div>
                                          {
                                            state?.boy_info?.rasi?.lord
                                              ?.vedic_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName">
                            {" "}
                            Your Partner Information{" "}
                          </p>
                        </div>
                      </Accordion.Header>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Koot </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> Varna </label>
                                        <div>
                                          {state?.girl_info?.koot?.varna}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Yoni </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.koot?.yoni}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Bhakoot </label>
                                        <div>
                                          {state?.girl_info?.koot?.bhakoot}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> Vasya </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.koot?.vasya}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Graha Maitri </label>
                                        <div>
                                          {state?.girl_info?.koot?.graha_maitri}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Nadi </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.koot?.nadi}{" "}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> Tara </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.koot?.tara}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Gana </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.koot?.gana}{" "}
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Nakshatra </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {state?.girl_info?.nakshatra?.id}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {state?.girl_info?.nakshatra?.name}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Pada </label>
                                        <div>
                                          {state?.girl_info?.nakshatra?.pada}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="dividerLine"></div>

                                    <div className="acdinNameBox dflex">
                                      <p className="acdinName"> Lord </p>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {
                                            state?.girl_info?.nakshatra?.lord
                                              ?.id
                                          }
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {
                                            state?.girl_info?.nakshatra?.lord
                                              ?.name
                                          }
                                        </div>
                                      </div>
                                      <div>
                                        <label> Vedic Name </label>
                                        <div>
                                          {
                                            state?.girl_info?.nakshatra?.lord
                                              ?.vedic_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>

                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div className="acdinNameBox dflex">
                                    <p className="acdinName"> Rasi </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {" "}
                                          {state?.girl_info?.rasi?.id}{" "}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {state?.girl_info?.rasi?.name}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="dividerLine"></div>

                                    <div className="acdinNameBox dflex">
                                      <p className="acdinName"> Lord </p>
                                    </div>

                                    <div className="grid3">
                                      <div>
                                        <label> ID </label>
                                        <div>
                                          {state?.girl_info?.rasi?.lord?.id}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Name </label>
                                        <div>
                                          {state?.girl_info?.rasi?.lord?.name}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Vedic Name </label>
                                        <div>
                                          {
                                            state?.girl_info?.rasi?.lord
                                              ?.vedic_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName"> Message</p>
                        </div>
                      </Accordion.Header>
                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid2Reverse">
                                      <div>
                                        <label> Type </label>
                                        <div>{state?.message?.type}</div>
                                      </div>
                                      <div>
                                        <label> Description </label>
                                        <div>{state?.message?.description}</div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acdinNameBox dflex">
                          <p className="acdinName"> Guna Milan </p>
                        </div>
                      </Accordion.Header>
                      <div className="InnerinsideAccordian">
                        <Accordion.Body>
                          <div className="insideAccordian">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Body>
                                  <div className="MyprofileForm">
                                    <div className="grid2">
                                      <div>
                                        <label> Total Points </label>
                                        <div>
                                          {state?.guna_milan?.total_points}
                                        </div>
                                      </div>
                                      <div>
                                        <label> Maximum Points </label>
                                        <div>
                                          {state?.guna_milan?.maximum_points}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-2"> </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KundliDetails;
