import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import MainHeader from "./header/HeaderWithoutLogin";
import Footer from "./Footer";
import Header from "./header/HeaderWithLogin";
import { useForm } from "react-hook-form";
import axiosClient from "../services/axiosInstance";
import {
  capitalizeFirstLetter,
  numberInputOnWheelPreventChange,
} from "../utils/helper";
import { useEffect, useState } from "react";
import Loader from "./accounts/Loader";
import { useLoader } from "./Root";
import { TSetting } from "../types/landing";

type TContactUs = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

const successMessage = "Thank you for contacting us";
const ContactUs = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  //const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSettings] = useState<TSetting>({} as TSetting);
  const [successMsg, setSuccessMsg] = useState<string | null>();
  const {
    register,
    handleSubmit,
    formState,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<TContactUs>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const resSetting = await client.get("/admin/settings");
        setSettings(resSetting.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState.isSubmitSuccessful, reset]);

  const onhandleFormSubmit = async (data: TContactUs) => {
    try {
      setIsLoading(true);
      const res = await client.post("/contact", capitalizeFirstLetter(data));
      if (true) {
        setSuccessMsg(successMessage);
        const timer = setTimeout(() => {
          setSuccessMsg(null);
        }, 5 * 1000);
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) return <Loader />;

  return (
    <div className="contactPage">
      {isLoggedIn ? <Header /> : <MainHeader />}
      <Helmet>
        <title>Contact Us | Premmilan</title>
      </Helmet>
      <section className="contactPageSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="contactUsPreBox brdrRight">
                <div className="contactUsHeadingBox">
                  <p className="commanheading">Customer Care</p>
                  <p className="commanheading">{setting.Working_Time}</p>
                  <p className="contactSubHeading"> Let’s Get In Touch </p>
                  <ul id="progressbar">
                    <li className="active" id="account">
                      <i className="fa fa-heart complete"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="contactUsPreBox">
                <div className="contactUsHeadingBox">
                  <p className="commanheading">Contact Us</p>
                  <p className="contactSubHeading"> Let’s Get In Touch </p>
                  <ul id="progressbar" className="setheight">
                    <li className="active" id="account">
                      <i className="fa fa-heart complete"></i>
                    </li>
                  </ul>
                </div>
                <p className="contactMailIcntext">
                  <span>
                    <img
                      src={require("../assets/images/openEmailIcon.png")}
                      alt="openEmailIcon"
                    />
                  </span>
                  {setting.Email}
                </p>
                <p className="contactwhatsapplIcntext">
                  <span>
                    <i
                      className="fab fa-whatsapp whatsapp-icon"
                      aria-hidden="true"
                    ></i>
                  </span>
                  {setting.Phone}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 ">
              <div className="contactUsPreBox fillgraidentandcenter">
                <img
                  src={require("../assets/images/whiteLogo.png")}
                  alt="Contact_whiteLogo"
                />
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="contactUsPreBox">
                <div className="contactUsFormBox">
                  <p className="commanheading">Write to Us</p>
                  <p className="contactSubHeading"> Feedback </p>
                  <ul id="progressbar">
                    <li className="active" id="account">
                      <i className="fa fa-heart complete"></i>
                    </li>
                  </ul>

                  <form className="contactform">
                    <div>
                      <div>
                        <input
                          id="name"
                          {...register("name", {
                            required: "Please enter name",
                          })}
                          type="text"
                          placeholder="Name*"
                        />
                        {errors.name && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.name.message}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          id="email"
                          {...register("email", {
                            required: "Email is required",
                            validate: (value) => {
                              const regx =
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return !regx.test(value) ? "invalid email" : true;
                            },
                          })}
                          placeholder="Your email address*"
                          type="text"
                        />
                        {errors.email && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.email.message}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          {...register("phone", {
                            required: "Number is required*",
                            pattern: {
                              value: /^[6-9]\d{9}$/,
                              message: "Invalid mobile number",
                            },
                          })}
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder="Mobile Number*"
                        />
                        {errors.phone && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.phone.message}
                          </div>
                        )}
                      </div>
                      <div>
                        <textarea
                          {...register("message", {
                            required: "Please enter your query",
                          })}
                          rows={5}
                          cols={77}
                          id="expectation"
                          placeholder="Explain*"
                        />
                        {errors.message && (
                          <div className="error-msg">
                            <i className="fa fa-exclamation-triangle"></i>
                            {errors.message.message}
                          </div>
                        )}
                        {successMsg && (
                          <div className="success-msg">{successMsg}</div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={handleSubmit(onhandleFormSubmit)}
                        className="loginbtn"
                        disabled={isSubmitting}
                      >
                        Send
                        <span>
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
