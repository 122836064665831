import { Link } from "react-router-dom";
import cardArrow from "../../assets/images/cardArrow.svg";
import { TBlogDocs, TBlogs } from "./Blogs";

export default function BlogPage({ Slug, Image, CreatedAt, Title }: TBlogDocs) {
  return (
    <div className="col-md-6">
      <div className="BlogCardMainBox">
        <div className="BlogCardBox">
          <div className="card">
            <Link to={`/blogs/${Slug}`}>
              <div className="BlogCardImgBox">
                <img className="" src={Image} alt="imgtextbximg" />
              </div>
            </Link>
            <div className="card-content">
              <Link to={`/blogs/${Slug}`}>
                <p className="card-dscptn">{Title}</p>
              </Link>
              <div className="dflex justifyContentSpaceBetween">
                <p className="date">
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(CreatedAt))}
                </p>
                <Link to={`/blogs/${Slug}`} className="arowImg">
                  <img src={cardArrow} width="30" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
