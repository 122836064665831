import { NumerologyHeader } from "./NumerologyHeader";
import axiosClient from "../../../services/axiosInstance";
import Loader from "../../accounts/Loader";
import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

export type TNumerologyDate = {
  Day: number;
  Month: number;
  Year: number;
};

export type TNumerologyMatchInfo = {
  FirstName: string;
  LastName: string;
  Dob: TNumerologyDate;
  State: string;
  City: string;
};

export type TNumerologyMatchData = {
  myData: TNumerologyMatchInfo;
  partnerData: TNumerologyMatchInfo;
};

const NumerologyMatch = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // Static Data
  const datesArr = Array.from({ length: 31 }, (_, index) => index + 1);
  const monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const yearsArr = Array.from(
    { length: currentYear - 1970 - 15 },
    (_, index) => 1970 + index,
  );

  // First and Last Name useStates
  const [myFirstName, setMyFirstName] = useState("");
  const [myLastName, setMyLastName] = useState("");
  const [partnerFirstName, setPartnerFirstName] = useState("");
  const [partnerLastName, setPartnerLastName] = useState("");

  // date useState
  const [myDate, setMyDate] = useState(1);
  const [myMonth, setMyMonth] = useState(0);
  const [myYear, setMyYear] = useState(1970);

  const [partnerDate, setPartnerDate] = useState(1);
  const [partnerMonth, setPartnerMonth] = useState(0);
  const [partnerYear, setPartnerYear] = useState(1970);

  const matchMaking = async () => {
    setIsLoading(true);
    const submitObj = {
      boyDate: myDate,
      boyMonth: myMonth,
      boyYear: myYear,
      girlDate: partnerDate,
      girlMonth: partnerMonth,
      girlYear: partnerYear,
    };
    try {
      if (!validateMatch()) return;
      const res = await client.post(`/numerology`, submitObj);
      if (true) {
        console.log(res?.data);
        navigate("/profile/numerology-details", {
          state: res.data,
        });
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const validateMatch = (): boolean => {
    if (myFirstName.trim().length === 0) {
      setErr("Please enter your first name");
      setErrorMsg("yourFirstName");
      return false;
    }
    if (myLastName.trim().length === 0) {
      setErr("Please enter your last name");
      setErrorMsg("yourLastName");
      return false;
    }
    if (partnerFirstName.trim().length === 0) {
      setErr("Please enter partner first name");
      setErrorMsg("partnerFirstName");
      return false;
    }
    if (partnerLastName.trim().length === 0) {
      setErr("Please enter partner last name");
      setErrorMsg("partnerLastName");
      return false;
    }
    setErrorMsg("");
    setErr("");
    return true;
  };

  if (isLoading) return <Loader />;

  return (
    <div className="NumerologyDetailsPage">
      <NumerologyHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="innerBox">
              <div className="detail-title">
                <div className="reg-icon"></div>
              </div>
              <div className="prsl-detail">
                <div className="prsl-detail-heading">
                  Enter Your Birth Information
                </div>
                <form id="msform">
                  <fieldset>
                    <div className="form-card">
                      <div className="reg-forms">
                        <div className="dflex">
                          <div>
                            <input
                              id="currentcity"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="First Name"
                              value={myFirstName}
                              onChange={(e) => setMyFirstName(e.target.value)}
                            />
                            {errorMsg === "yourFirstName" && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {err && err}
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              id="currentstate"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Last Name"
                              value={myLastName}
                              onChange={(e) => setMyLastName(e.target.value)}
                            />

                            {errorMsg === "yourLastName" && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {err && err}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="dflex space">
                        <div>
                          <label> Date </label>
                          <select
                            className="dropdown-menu"
                            value={myDate}
                            onChange={(e) => setMyDate(+e.target.value)}
                          >
                            <option value="" disabled>
                              Date
                            </option>
                            {datesArr.map((date) => (
                              <option key={date} value={date}>
                                {date}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label> Month </label>
                          <select
                            className="dropdown-menu"
                            value={myMonth}
                            onChange={(e) => setMyMonth(+e.target.value)}
                          >
                            <option value="" disabled>
                              {" "}
                              Month{" "}
                            </option>
                            {monthsArr.map((month, index) => (
                              <option key={index} value={index}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label> Year </label>
                          <select
                            className="dropdown-menu"
                            value={myYear}
                            onChange={(e) => setMyYear(+e.target.value)}
                          >
                            <option value="" disabled>
                              {" "}
                              Year{" "}
                            </option>
                            {yearsArr.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="innerBox2">
              <div className="detail-title">
                <div className="reg-icon"></div>
              </div>
              <div className="prsl-detail">
                <div className="prsl-detail-heading">
                  Enter Your Partner Birth Information
                </div>
                <form id="msform">
                  <fieldset>
                    <div className="form-card">
                      <div className="reg-forms">
                        <div className="dflex">
                          <div>
                            <input
                              id="currentcity"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="First Name"
                              value={partnerFirstName}
                              onChange={(e) =>
                                setPartnerFirstName(e.target.value)
                              }
                            />

                            {errorMsg === "partnerFirstName" && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {err && err}
                              </div>
                            )}
                          </div>
                          <div>
                            <input
                              id="currentstate"
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Last Name"
                              value={partnerLastName}
                              onChange={(e) =>
                                setPartnerLastName(e.target.value)
                              }
                            />
                            {errorMsg === "partnerLastName" && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {err && err}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="dflex space">
                        <div>
                          <label> Date </label>
                          <select
                            className="dropdown-menu"
                            value={partnerDate}
                            onChange={(e) => setPartnerDate(+e.target.value)}
                          >
                            <option value="" disabled>
                              {" "}
                              Date{" "}
                            </option>
                            {datesArr.map((date) => (
                              <option key={date} value={date}>
                                {date}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label> Month </label>
                          <select
                            className="dropdown-menu"
                            value={partnerMonth}
                            onChange={(e) => setPartnerMonth(+e.target.value)}
                          >
                            <option value="" disabled>
                              {" "}
                              Month{" "}
                            </option>
                            {monthsArr.map((month, index) => (
                              <option key={index} value={index}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label> Year </label>
                          <select
                            className="dropdown-menu"
                            value={partnerYear}
                            onChange={(e) => setPartnerYear(+e.target.value)}
                          >
                            <option value="" disabled>
                              {" "}
                              Year{" "}
                            </option>
                            {yearsArr.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="btn-flex">
              <div className="left-btn">
                <button
                  className="btn-trans"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <i className="fa-solid fa-arrow-left"></i> Back
                </button>
              </div>

              <button
                type="submit"
                className="cont-btn commonBtn"
                onClick={matchMaking}
              >
                Matchmaking
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumerologyMatch;
