import { useEffect } from "react";

const useSpaceKeyEffect = (callback: () => void) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.code === "Space") {
        callback();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [callback]);
};

export default useSpaceKeyEffect;
