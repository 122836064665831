import Accordion from "react-bootstrap/Accordion";
import { TBasicProfileInfo } from "./ProfileBasicInfo";

const ProfileEducationInfo = ({ data }: TBasicProfileInfo) => {
  return (
    <div>
      {" "}
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Education Info </p>
          <div className="dflex alignItemCenter"></div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Highter education / Degree </label>
                    <input
                      disabled
                      type="text"
                      placeholder={
                        data?.EducationWork?.Education?.EducationName
                      }
                    />
                  </div>

                  <div>
                    <label> College / University </label>
                    <input
                      disabled
                      type="text"
                      placeholder={data?.EducationWork?.Education?.CollegeName}
                    ></input>
                  </div>

                  <div>
                    <label> Passing year </label>
                    <input
                      disabled
                      type="number"
                      placeholder={data?.EducationWork?.Education?.PassingYear.toString()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileEducationInfo;
