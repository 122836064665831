import { Tooltip as ReactTooltip } from "react-tooltip";

const ShowRequestType = ({ contactType }: any) => {
  const packageTooltip =
  contactType?.toLowerCase() === "personal" ? "Personal" : "Guardian";

  return (
    <>
      {contactType && (
        <i
          data-tooltip-id="my-tooltip-1"
          className={
            contactType.toLowerCase() === "personal"
              ? "fas fa-user"
              : "fas fa-user-shield"
          }
          style={{ fontSize: "24px", color: "#e12b6f" }}
        ></i>
      )}
      <ReactTooltip
        style={{ zIndex: 1000 }}
        id="my-tooltip-1"
        place="bottom"
        content={packageTooltip}
      />
    </>
  );
};

export default ShowRequestType;
