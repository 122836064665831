import { State, City } from "country-state-city";
import { KundliMatchHeader } from "./KundliMatchHeader";
import axiosClient from "../../../services/axiosInstance";
import Loader from "../../accounts/Loader";
import { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const KundliMatch = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // Static Data
  const datesArr = Array.from({ length: 31 }, (_, index) => index + 1);
  const monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const yearsArr = Array.from(
    { length: currentYear - 1970 - 15 },
    (_, index) => 1970 + index,
  );
  const hoursArr = Array.from({ length: 24 }, (_, index) => index);
  const minutesArr = Array.from({ length: 60 }, (_, index) => index);
  const secondsArr = Array.from({ length: 60 }, (_, index) => index);

  // First and Last Name useStates
  const [boyFirstName, setBoyFirstName] = useState("");
  const [boyLastName, setBoyLastName] = useState("");
  const [girlFirstName, setGirlFirstName] = useState("");
  const [girlLastName, setGirlLastName] = useState("");

  // date useState
  const [boyDate, setBoyDate] = useState(1);
  const [boyMonth, setBoyMonth] = useState(0);
  const [boyYear, setBoyYear] = useState(1970);

  const [girlDate, setGirlDate] = useState(1);
  const [girlMonth, setGirlMonth] = useState(0);
  const [girlYear, setGirlYear] = useState(1970);

  //time useState
  const [boyHour, setBoyHour] = useState(1);
  const [boyMinute, setBoyMinute] = useState(0);
  const [boySecond, setBoySecond] = useState(1970);

  const [girlHour, setGirlHour] = useState(1);
  const [girlMinute, setGirlMinute] = useState(0);
  const [girlSecond, setGirlSecond] = useState(1970);

  // State and City useStates
  const [states, setStates] = useState<any | null>();

  const [citiesBoy, setCitiesBoy] = useState<any | null>();
  const [cityBoy, setCityBoy] = useState<string | null>();
  const [stateBoy, setStateBoy] = useState<string | null>();
  const [latLongBoy, setLatLongBoy] = useState<string | null>();

  const [citiesGirl, setCitiesGirl] = useState<any | null>();
  const [cityGirl, setCityGirl] = useState<string | null>();
  const [stateGirl, setStateGirl] = useState<string | null>();
  const [latLongGirl, setLatLongGirl] = useState<string | null>();

  useEffect(() => {
    const data = State.getStatesOfCountry("IN").map((state: any) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));
    setStates(data);
  }, []);

  const changeStateBoy = (e: any) => {
    const citidata = City.getCitiesOfState("IN", e.target.value).map(
      (city: any) => ({ label: city.name, value: city.id, ...city }),
    );
    setStateBoy(e.target.value);
    setCitiesBoy(citidata);
  };

  const changeStateGirl = (e: any) => {
    const citidata = City.getCitiesOfState("IN", e.target.value).map(
      (city: any) => ({ label: city.name, value: city.id, ...city }),
    );
    setStateGirl(e.target.value);
    setCitiesGirl(citidata);
  };

  const manageBoyCity = (e: any) => {
    setCityBoy(e.target.value);
    const selectedDataAttribute =
      e.target.selectedOptions[0].getAttribute("data-latlong");
    setLatLongBoy(selectedDataAttribute);
  };

  const manageGirlCity = (e: any) => {
    setCityGirl(e.target.value);
    const selectedDataAttribute =
      e.target.selectedOptions[0].getAttribute("data-latlong");
    setLatLongGirl(selectedDataAttribute);
  };

  const matchMaking = async () => {
    setIsLoading(true);
    const submitObj = {
      boy_dob: new Date(
        boyYear,
        boyMonth,
        boyDate,
        boyHour,
        boyMinute,
        boySecond,
      ).getTime(),
      girl_dob: new Date(
        girlYear,
        girlMonth,
        girlDate,
        girlHour,
        girlMinute,
        girlSecond,
      ).getTime(),
      boy_coordinates: latLongBoy,
      girl_coordinates: latLongGirl,
    };

    try {
      if (!validateKundliMatch()) return;
      const res = await client.post(`/kundli`, submitObj);
      if (true) {
        console.log("response", res.data);
        console.log(res?.data);
        navigate("/profile/kundli-details", {
          state: res.data,
        });
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const validateKundliMatch = (): boolean => {
    if (boyFirstName.trim().length === 0) {
      setErr("Please enter your first name");
      setErrorMsg("yourFirstName");
      return false;
    }
    if (boyLastName.trim().length === 0) {
      setErr("Please enter yyour last name");
      setErrorMsg("yourLastName");
      return false;
    }
    if (girlFirstName.trim().length === 0) {
      setErr("Please enter partner first name");
      setErrorMsg("partnerFirstName");
      return false;
    }
    if (girlLastName.trim().length === 0) {
      setErr("Please enter partner last name");
      setErrorMsg("partnerLastName");
      return false;
    }
    if (typeof stateBoy === "undefined") {
      setErr("Please select state");
      setErrorMsg("yourState");
      return false;
    }
    if (typeof cityBoy === "undefined") {
      setErr("Please select city");
      setErrorMsg("yourCity");
      return false;
    }
    if (typeof stateGirl === "undefined") {
      setErr("Please select state");
      setErrorMsg("partnerState");
      return false;
    }
    if (typeof cityGirl === "undefined") {
      setErr("Please select city");
      setErrorMsg("partnerCity");
      return false;
    }
    setErr("");
    return true;
  };

  if (isLoading) return <Loader />;

  return (
    <div className="kundliMatchPage">
      <div className="NumerologyDetailsPage">
        <KundliMatchHeader />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="innerBox">
                <div className="detail-title">
                  <div className="reg-icon"></div>
                </div>
                <div className="prsl-detail">
                  <div className="prsl-detail-heading">
                    Your Personal Information
                  </div>
                  <form id="msform">
                    <fieldset>
                      <div className="form-card">
                        <div className="reg-forms">
                          <div className="dflex">
                            <div>
                              <input
                                id="currentcity"
                                type="text"
                                style={{ textTransform: "capitalize" }}
                                placeholder="First Name"
                                value={boyFirstName}
                                onChange={(e) =>
                                  setBoyFirstName(e.target.value)
                                }
                              />

                              {errorMsg === "yourFirstName" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                            <div>
                              <input
                                id="currentstate"
                                type="text"
                                style={{ textTransform: "capitalize" }}
                                placeholder="Last Name"
                                value={boyLastName}
                                onChange={(e) => setBoyLastName(e.target.value)}
                              />

                              {errorMsg === "yourLastName" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="prsl-detail-heading mb20">
                            Birth information
                          </div>
                          <div className="dflex space">
                            <div>
                              <label> Day </label>
                              <select
                                className="dropdown-menu"
                                value={boyDate}
                                onChange={(e) => setBoyDate(+e.target.value)}
                              >
                                <option value="" disabled>
                                  Date
                                </option>
                                {datesArr.map((date) => (
                                  <option key={date} value={date}>
                                    {date}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label> Month </label>
                              <select
                                className="dropdown-menu"
                                value={boyMonth}
                                onChange={(e) => setBoyMonth(+e.target.value)}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Month{" "}
                                </option>
                                {monthsArr.map((month, index) => (
                                  <option key={index} value={index}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label> Year </label>
                              <select
                                className="dropdown-menu"
                                value={boyYear}
                                onChange={(e) => setBoyYear(+e.target.value)}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Year{" "}
                                </option>
                                {yearsArr.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="dflex space">
                          <div>
                            <label> Hour </label>
                            <select
                              className="dropdown-menu"
                              value={boyHour}
                              onChange={(e) => setBoyHour(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Hour{" "}
                              </option>
                              {hoursArr.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label> Minute </label>
                            <select
                              className="dropdown-menu"
                              value={boyMinute}
                              onChange={(e) => setBoyMinute(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Minute{" "}
                              </option>
                              {minutesArr.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label> Second </label>
                            <select
                              className="dropdown-menu"
                              value={boySecond}
                              onChange={(e) => setBoySecond(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Second{" "}
                              </option>
                              {secondsArr.map((second) => (
                                <option key={second} value={second}>
                                  {second}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="reg-forms">
                          <div className="dflex space">
                            <div>
                              <label> Birth Place State </label>
                              <select
                                className="dropdown-menu"
                                id="BirthPalace"
                                value={stateBoy ? stateBoy : ""}
                                onChange={(e) => changeStateBoy(e)}
                              >
                                <option key="" value="" disabled>
                                  {" "}
                                  Select{" "}
                                </option>
                                {states?.map((values: any) => (
                                  <option
                                    key={(Math.random() + 1)
                                      .toString(36)
                                      .substring(7)}
                                    value={values.isoCode}
                                  >
                                    {values.name}
                                  </option>
                                ))}
                              </select>

                              {errorMsg === "yourState" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                            <div>
                              <label> Birth Place City </label>
                              <select
                                className="dropdown-menu"
                                id="BirthPalace"
                                value={cityBoy ? cityBoy : ""}
                                onChange={(e) => manageBoyCity(e)}
                              >
                                <option key="" value="" disabled>
                                  {" "}
                                  Select{" "}
                                </option>
                                {citiesBoy?.map((values: any) => (
                                  <option
                                    key={(Math.random() + 1)
                                      .toString(36)
                                      .substring(7)}
                                    data-latlong={`${values.latitude},${values.longitude}`}
                                    value={values.isoCode}
                                  >
                                    {values.name}
                                  </option>
                                ))}
                              </select>

                              {errorMsg === "yourCity" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="innerBox2">
                <div className="detail-title">
                  <div className="reg-icon"></div>
                </div>
                <div className="prsl-detail">
                  <div className="prsl-detail-heading">
                    Your Partner Personal Information
                  </div>
                  <form id="msform">
                    <fieldset>
                      <div className="form-card">
                        <div className="reg-forms">
                          <div className="dflex">
                            <div>
                              <input
                                id="currentcity"
                                type="text"
                                placeholder="First Name"
                                value={girlFirstName}
                                style={{ textTransform: "capitalize" }}
                                onChange={(e) =>
                                  setGirlFirstName(e.target.value)
                                }
                              />

                              {errorMsg === "partnerFirstName" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                            <div>
                              <input
                                id="currentstate"
                                type="text"
                                style={{ textTransform: "capitalize" }}
                                placeholder="Last Name"
                                value={girlLastName}
                                onChange={(e) =>
                                  setGirlLastName(e.target.value)
                                }
                              />

                              {errorMsg === "partnerLastName" && (
                                <div className="error-msg">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  {err && err}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="prsl-detail-heading mb20">
                            Birth information
                          </div>
                          <div className="dflex space">
                            <div>
                              <label> Day </label>
                              <select
                                className="dropdown-menu"
                                value={girlDate}
                                onChange={(e) => setGirlDate(+e.target.value)}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Date{" "}
                                </option>
                                {datesArr.map((date) => (
                                  <option key={date} value={date}>
                                    {date}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label> Month </label>
                              <select
                                className="dropdown-menu"
                                value={girlMonth}
                                onChange={(e) => setGirlMonth(+e.target.value)}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Month{" "}
                                </option>
                                {monthsArr.map((month, index) => (
                                  <option key={index} value={index}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label> Year </label>
                              <select
                                className="dropdown-menu"
                                value={girlYear}
                                onChange={(e) => setGirlYear(+e.target.value)}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Year{" "}
                                </option>
                                {yearsArr.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="dflex space">
                          <div>
                            <label> Hour </label>
                            <select
                              className="dropdown-menu"
                              value={girlHour}
                              onChange={(e) => setGirlHour(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Hour{" "}
                              </option>
                              {hoursArr.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label> Minute </label>
                            <select
                              className="dropdown-menu"
                              value={girlMinute}
                              onChange={(e) => setGirlMinute(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Minute{" "}
                              </option>
                              {minutesArr.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label> Second </label>
                            <select
                              className="dropdown-menu"
                              value={girlSecond}
                              onChange={(e) => setGirlSecond(+e.target.value)}
                            >
                              <option value="" disabled>
                                {" "}
                                Second{" "}
                              </option>
                              {secondsArr.map((second) => (
                                <option key={second} value={second}>
                                  {second}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="reg-forms">
                          <div>
                            <div className="dflex space">
                              <div>
                                <label> Birth Place State </label>
                                <select
                                  className="dropdown-menu"
                                  id="BirthPalace"
                                  value={stateGirl ? stateGirl : ""}
                                  onChange={(e) => changeStateGirl(e)}
                                >
                                  <option key="" value="" disabled>
                                    {" "}
                                    Select{" "}
                                  </option>
                                  {states?.map((values: any) => (
                                    <option
                                      key={(Math.random() + 1)
                                        .toString(36)
                                        .substring(7)}
                                      value={values.isoCode}
                                    >
                                      {values.name}
                                    </option>
                                  ))}
                                </select>

                                {errorMsg === "partnerState" && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {err && err}
                                  </div>
                                )}
                              </div>
                              <div>
                                <label> Birth Place City </label>
                                <select
                                  className="dropdown-menu"
                                  id="BirthPalace"
                                  value={cityGirl ? cityGirl : ""}
                                  onChange={(e) => manageGirlCity(e)}
                                >
                                  <option key="" value="" disabled>
                                    {" "}
                                    Select{" "}
                                  </option>
                                  {citiesGirl?.map((values: any) => (
                                    <option
                                      key={(Math.random() + 1)
                                        .toString(36)
                                        .substring(7)}
                                      data-latlong={`${values.latitude},${values.longitude}`}
                                      value={values.isoCode}
                                    >
                                      {values.name}
                                    </option>
                                  ))}
                                </select>

                                {errorMsg === "partnerCity" && (
                                  <div className="error-msg">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    {err && err}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="btn-flex">
                <div className="left-btn">
                  <button
                    className="btn-trans"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    <i className="fa-solid fa-arrow-left"></i> Back
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="cont-btn commonBtn"
                    onClick={matchMaking}
                  >
                    Matchmaking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KundliMatch;
