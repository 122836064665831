import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import profileSendRequest from "../../../assets/images/profileSendRequest.png";
import profileNoIcon from "../../../assets/images/profileNoIcon.png";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import user_dummy_photo from "../../../assets/images/user_dummy_photo.png";
import { TUserRequest } from "./Requests";

import Loader from "../../accounts/Loader";
import { useNavigate } from "react-router-dom";
import { TNotification } from "../components/NotificationBoxCompo";
import { ToWords } from "to-words";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ShowPackageDot from "../components/ShowPackageDot";

const ReceivedRequestCardCompo = () => {
  const [data, setData] = useState<TUserRequest[]>([]);
  const navigate = useNavigate();
  const { id, firstName, lastname } = useSelector(
    (state: RootState) => state.user,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const fetchedDocumentIds = new Set();
  const { token } = useSelector((state: RootState) => state.auth);
  const { packageName } = useSelector((state: RootState) => state.package);
  const client = axiosClient(token);
  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchRequests = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await client.post(`/request/${id}?page=${pageNumber}`, {
        Action: "Received",
      });
      if (true) {
        const requestsData = res?.data?.result;
        const newRequests = requestsData.filter(
          (x: TUserRequest) => !fetchedDocumentIds.has(x._id),
        );
        setData((prevItems) => [...prevItems, ...newRequests]);
        requestsData.forEach((x: TUserRequest) => {
          fetchedDocumentIds.add(x._id);
        });
        setPageNumber((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchRequests();
  };

  const sendNotification = async (notificationObj: TNotification) => {
    try {
      await client.post(
        "/notification/send",
        notificationObj,
        // { NotificationFrom: id, NotificationTo: id, Message: message, CreatedAt: new Date() },
      );
    } catch (error) {}
  };

  const handleAccept = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    acceptUserId: string,
  ) => {
    e.preventDefault();
    try {
      const res = await client.put("/request/accept", {
        RequestByUserId: acceptUserId,
        UserId: id,
      });
      if (true) {
        const filteredMatches = data.filter(
          (x: TUserRequest) => x._id !== acceptUserId,
        );
        sendNotification({
          Type: "ACCEPTED",
          NotificationFrom: id!,
          NotificationTo: acceptUserId,
          Message: `${firstName + " " + lastname} has accepted your request`,
          CreatedAt: new Date(),
        });
        setData(filteredMatches);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const handleReject = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    rejectUserId: string,
  ) => {
    e.preventDefault();
    try {
      const res = await client.delete("/request/cancel", {
        data: {
          UserId: rejectUserId,
          RequestUserId: id,
        },
      });
      if (true) {
        const filteredMatches = data.filter(
          (x: TUserRequest) => x._id !== rejectUserId,
        );
        setData(filteredMatches);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const openFullProfile = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    userData: any,
  ) => {
    return;
    //e.preventDefault();
    console.log("full profile data", userData);
    //console.log("data._id", data._id);
    navigate("/profile/profile-details", {
      state: {
        data: userData,
      },
    });
  };

  if (isLoading)
    return (
      <>
        <center>
          <p>Loading matches...</p>
        </center>
      </>
    );

  return (
    <div>
      {data.map((x, index) => (
        <div key={index} className="mainTab">
          <div className="profileCard">
            <div className="profileImage">
              <img src={x.Photos} alt="" />
            </div>
            <div className="profileDetail">
              <div className="dflex justifyContentSpaceBetween">
                <div
                  className="aboutDetail"
                  onClick={(e) => openFullProfile(e, x)}
                >
                  <p className="username">
                    {" "}
                    {x.FirstName + " " + x.LastName}{" "}
                    {packageName && (
                      <ShowPackageDot packageName={x?.PackageName} />
                    )}
                  </p>
                  <ul>
                    <li>
                      <img src={SprofileLocation} alt="" /> {x.City},{x.State}
                    </li>
                    <li>
                      <img src={SprofileJobBC} alt="" /> {x.EducationName}
                    </li>
                    <li>
                      <img src={SprofileEducation} alt="" /> {x.WorkTitle} /
                      <span data-tooltip-id="my-tooltip-2">
                        {x.ExactIncome}
                      </span>
                      <ReactTooltip
                        style={{ zIndex: 1000 }}
                        id="my-tooltip-2"
                        place="bottom"
                        content={toWords.convert(x.ExactIncome)}
                      />
                    </li>
                  </ul>
                </div>
                <div className="likeAge">
                  <p className="Age"> Age {calculateAge(x.DOB)} </p>
                </div>
              </div>
              <div className="profileCardFtr Sent">
                <p onClick={(e) => handleAccept(e, x._id)}>
                  <img src={profileSendRequest} alt="" />
                  Accept
                </p>
                <p onClick={(e) => handleReject(e, x._id)}>
                  <img src={profileNoIcon} alt="" /> Reject
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReceivedRequestCardCompo;
