import { useEffect } from "react";

const useWheelScrollEffect = (
  handleScroll: (e: WheelEvent) => void,
  currentVideoIndex: number,
  videos: any[],
) => {
  useEffect(() => {
    const videoPlayer = document.getElementById("video-player");
    videoPlayer?.addEventListener("wheel", handleScroll);

    return () => {
      videoPlayer?.removeEventListener("wheel", handleScroll);
    };
  }, [currentVideoIndex, videos, handleScroll]);
};

export default useWheelScrollEffect;
