import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axiosClient from "../../services/axiosInstance";
import { TSetting } from "../../types/landing";
import AudioToggle from "./AudioToggle";

function HeaderWithLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const handleClick = () => setClick(!click);
  const client = axiosClient();
  const [setting, setSettings] = useState<TSetting>({} as TSetting);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const resSetting = await client.get("/admin/settings");
        setSettings(resSetting.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 30) {
        // You can adjust this threshold as needed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isLoading) return;

  return (
    <div className={isNavbarFixed ? "headerFixedonScroll" : ""}>
      {!window.location.pathname.includes("/klicks") && (
        <AudioToggle showToggle={false} />
      )}
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headerCompo">
                <nav className="navbar">
                  <div className="nav-container">
                    <NavLink to="/" className="nav-logo">
                      {setting && (
                        <img
                          className="side_panel_head_profile_img"
                          src={setting.Logo}
                        />
                      )}
                    </NavLink>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                      <li className="nav-item">
                        <NavLink
                          to="/about-us"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/subscription"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          Subscription
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/klicks"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          Klicks
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/blogs"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          Web Blog
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/happy-stories"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          Happy Stories
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/contact-us"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/profile"
                          className="nav-links"
                          onClick={handleClick}
                        >
                          My Account
                        </NavLink>
                      </li>
                    </ul>
                    <div className="nav-icon" onClick={handleClick}>
                      {click ? (
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="rgba(255, 255, 255, 0)"
                              d="M0 0h24v24H0z"
                            />
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 17h18M3 12h18M3 7h18"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="rgba(255, 255, 255, 0)"
                              d="M0 0h24v24H0z"
                            />
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 17h18M3 12h18M3 7h18"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderWithLogin;
