import UpgradeBtn from "../Upgrade_Btn";

const NoSubsciption = () => {
  return (
    <div className="ctaboxmain">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ctaboxtext">
              <h5 className="commanheading fontColorWhite">
                Go Exclusive to make your happy story faster
              </h5>
              <p>
                Grant us the opportunity to better serve you in finding a more
                suitable partner by upgrading to a membership.
              </p>
              <UpgradeBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSubsciption;
