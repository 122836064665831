import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Footer from "../Footer";
import HeroSection from "../header/HeroSectionCompo";
import MainHeader from "../header/HeaderWithoutLogin";
import Header from "../header/HeaderWithLogin";
import axiosClient from "../../services/axiosInstance";
import { THomePage } from "../../types/landing";
import Loader from "../accounts/Loader";
const LandingSection2 = React.lazy(() => import("./LandingSection2"));
const LandingSection3 = React.lazy(() => import("./LandingSection3"));
const LandingSection4 = React.lazy(() => import("./LandingSection4"));
const LandingSection5 = React.lazy(() => import("./LandingSection5"));
const LandingSection6 = React.lazy(() => import("./LandingSection6"));
const LandingSection7 = React.lazy(() => import("./LandingSection7"));
const LandingSection8 = React.lazy(() => import("./LandingSection8"));

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [homeData, setHomeData] = useState<THomePage>({} as THomePage);
  const client = axiosClient();

  useEffect(() => {
    async function fetch() {
      try {
        const resHome = await client.get("/admin/homepage");
        setHomeData(resHome.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
      }
    }
    fetch();
  }, []);

  return (
    <div>
      <div className="LandingPageSection homepage">
        {isLoggedIn ? <Header /> : <MainHeader />}
        {homeData && (
          <HeroSection
            imgName={homeData.Banner}
            Title1={homeData.Title1}
            Title2={homeData.Title2}
          />
        )}
        <Suspense fallback={<Loader />}>
          <LandingSection2 />
          <LandingSection3 />
          <LandingSection4 />
          <LandingSection5 />
          <LandingSection6 />
          <LandingSection7 />
          <LandingSection8 />
        </Suspense>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
