import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { TForgotPassword } from "../../types/auth";
import { useForm } from "react-hook-form";
import axiosClient from "../../services/axiosInstance";
import Loader from "./Loader";
import AuthLeftBanner from "../AuthLeftBanner_compo";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TForgotPassword>({
    //defaultValues: ,
  });
  const [err, setErr] = useState<string | null>();
  // const { isLoading, setIsLoading } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const navigate = useNavigate();

  const onhandleSubmit = async (data: TForgotPassword) => {
    try {
      setIsLoading(true);
      const response = await client.post(`/auth/token/${data.email}`);
      setIsLoading(false);
      if (true) {
        navigate("/success", {
          replace: true,
          state: {
            message: `We have sent you the reset password link to your email`,
          },
        });
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
      return;
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="forgot-password dflex">
      <Helmet>
        <title>Forgot Password | Premmilan</title>
      </Helmet>
      <AuthLeftBanner
        imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/7071307-login-banner.png"
        altName="Forgot Password"
      />

      <div className="login_right_sec">
        <div className="innr_frm">
          <div className="login-logo">
            <Link to="/">
              <img src={require("../../assets/images/logo.png")} alt="logo" />
            </Link>
          </div>
          <div className="forgot-password-form">
            <h1>Forgot Password</h1>
            <p>
              No worries! Just enter your email and we'll send you a reset
              password link.
            </p>
            <form onSubmit={handleSubmit(onhandleSubmit)}>
              <div>
                <input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address",
                    },
                  })}
                  id="email"
                  placeholder="Email address*"
                  type="text"
                />
                {errors.email && (
                  <div className="error-msg">
                    <i className="fa fa-exclamation-triangle"></i>
                    {errors.email.message}
                  </div>
                )}
                {err && (
                  <div className="error-msg">
                    <i className="fa fa-exclamation-triangle"></i>
                    {err}
                  </div>
                )}
              </div>
              <button
                disabled={isSubmitting}
                type="submit"
                className="loginbtn"
              >
                Send Reset Link
              </button>
            </form>
            <div className="login-regstr">
              <Link to="/login">
                <span className="color">
                  <i className="fa-solid fa-arrow-left"></i> Back to Login
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
