import { RootState } from "../../state/store";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "../header/HeaderWithLogin";
import MainHeader from "../header/HeaderWithoutLogin";

const BlogLayout = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  return (
    <div className="InnerDetailBlogPage">
      {isLoggedIn ? <Header /> : <MainHeader />}
      <Outlet />
    </div>
  );
};

export default BlogLayout;
