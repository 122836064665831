import { jwtDecode } from "jwt-decode";
import { RootState } from "../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { TJwtToken } from "../components/accounts/Login";
import { resetAuth } from "../state/features/authSlice";
import { Logout } from "../state/features/loginSlice";
import { resetFirebase } from "../state/features/firebaseSlice";
import { resetPackage } from "../state/features/packageSlice";

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const { token } = useSelector((state: RootState) => state.auth);
  const { expireDate } = useSelector((state: RootState) => state.package);

  const onLogout = () => {
    dispatch(resetAuth());
    dispatch(Logout());
    dispatch(resetPackage());
    dispatch(resetFirebase());
  };

  if (token) {
    const decodedToken = jwtDecode<TJwtToken>(token);
    if (decodedToken.exp < new Date().getTime()) {
      onLogout();
    }
  }
  if (expireDate) {
    if (new Date(expireDate).getTime() < new Date().getTime()) {
      onLogout();
    }
  }

  if (isLoggedIn) return <Outlet />;
  return <div>{<Navigate to="/login" replace />}</div>;
};

export default ProtectedRoutes;
