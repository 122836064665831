import { useEffect, useState } from "react";
import "./chatList.css";
import { useUserStore } from "../../../state/firebase/userFirehoseStore";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";
import axiosClient from "../../../services/axiosInstance";
import { useChatStore } from "../../../state/firebase/chatFirehoseStore";
import chatSearchIcon from "../../../../src/assets/images/chatSearchIcon.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

type TChat = {
  chatId: string;
  user: {
    username: string;
    blocked: string[];
    avatar?: string;
  };
  userInfo: {
    FullName: string;
    ProfilePic: string;
  };
  lastMessage: string;
  isSeen: boolean;
  updatedAt: number;
  receiverId: string;
};

type TUser = string;
interface ChildProps {
  setIsLoading: (value: boolean | ((prev: boolean) => boolean)) => void;
  setUserCount: (value: number | ((prev: number) => number)) => void;
}

const ChatList: React.FC<ChildProps> = ({ setIsLoading, setUserCount }) => {
  const [chats, setChats] = useState<TChat[]>([]);
  const [input, setInput] = useState<string>("");
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);

  const { currentUser } = useUserStore();
  const { chatId, changeChat } = useChatStore();

  useEffect(() => {
    if (!currentUser) return;
    const unSub = onSnapshot(
      doc(db, "userchats", currentUser.id),
      async (res) => {
        const items = res?.data && res.data()?.chats;
        if (items) {
          const promises = items.map(async (item: TChat) => {
            const userDocRef = doc(db, "users", item.receiverId);
            const userDocSnap = await getDoc(userDocRef);
            const user = userDocSnap.data();
            return { ...item, user };
          });

          const chatData = await Promise.all(promises);
          const usernameArr: TUser[] = [];
          chatData.map((obj) => {
            if (obj.user) usernameArr.push(obj.user.username);
          });
          try {
            const res = await client.post("/chat/getUsers", {
              Users: usernameArr,
            });
            if (true) {
              chatData.map((obj, key) => {
                chatData[key].userInfo = res.data?.result[key];
              });
            }
          } catch (err) {}
          setChats(
            chatData.sort((a: TChat, b: TChat) => b.updatedAt - a.updatedAt),
          );
          //console.log("chats ", chats);
          // setUserCount(usernameArr.length);
        }
      },
    );

    return () => {
      unSub();
    };
  }, [currentUser]);

  const filteredChats = chats.filter((c) =>
    c.userInfo?.FullName.toLowerCase().includes(input.toLowerCase()),
  );

  useEffect(() => {
    setUserCount(filteredChats?.length);
  }, [filteredChats?.length]);

  const handleSelect = async (chat: TChat): Promise<void> => {
    setIsLoading(true);
    const userChats = chats.map((item) => {
      const { user, ...rest } = item;
      return rest;
    });
    const chatIndex = userChats.findIndex(
      (item) => item.chatId === chat.chatId,
    );

    userChats[chatIndex].isSeen = true;
    const userChatsRef = doc(db, "userchats", currentUser.id);

    try {
      try {
        await updateDoc(userChatsRef, {
          chats: userChats,
        });
      } catch (err: any) {
        console.log(err);
      }
      changeChat(chat.chatId, chat.user, chat.userInfo);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="chatList">
      <div className="search">
        <div className="searchBar">
          <img src={chatSearchIcon} alt="" />
          <input
            type="text"
            placeholder="Search your chat"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </div>
      {filteredChats.map((chat) => (
        <div className="item" key={chat.chatId}>
          <div
            className="userChatbox"
            onClick={() => handleSelect(chat)}
            style={{
              backgroundColor: chat?.isSeen ? "transparent" : "#FFEBF1",
            }}
          >
            <img
              src={
                chat.user.blocked.includes(currentUser.id)
                  ? chat?.userInfo?.ProfilePic
                  : chat.user.avatar || chat?.userInfo?.ProfilePic
              }
              alt=""
            />
            <div className="texts">
              <span>{chat?.userInfo?.FullName}</span>
              <p> {chat.lastMessage}</p>
            </div>
          </div>
          <div className="divider"></div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;
