import React from "react";
import Modal from "react-modal";
import "../../../../assets/css/ImagePreviewModal.css";
import TermCondition from "../TermCondition";

type TermsConditionModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const TermsConditionModal: React.FC<TermsConditionModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal
      className={"acnlk"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Terms and Conditions"
    >
      <div className="modal-content CustomModal">
        <button className="close-button" onClick={onRequestClose}>
          Close
        </button>
        <div className="terms-container">
          <TermCondition />
        </div>
      </div>
    </Modal>
  );
};

export default TermsConditionModal;
