import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import profileNoIcon from "../../../assets/images/profileNoIcon.png";
import profileSendRequest from "../../../assets/images/profileSendRequest.png";
import SprofileLocation from "../../../assets/images/SprofileLocation.svg";
import SprofileEducation from "../../../assets/images/SprofileEducationIcon.svg";
import SprofileJobBC from "../../../assets/images/SprofileCardJobBC.svg";
import axiosClient from "../../../services/axiosInstance";
import { RootState } from "../../../state/store";
import user_dummy_photo from "../../../assets/images/user_dummy_photo.png";

import Loader from "../../accounts/Loader";
import { useNavigate } from "react-router-dom";
import { TUserRequest } from "./Requests";
import { TNotification } from "../components/NotificationBoxCompo";
import { ToWords } from "to-words";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ShowPackageDot from "../components/ShowPackageDot";

const SentRequestCardCompo = () => {
  const [data, setData] = useState<TUserRequest[]>([]);
  const [isRequestCanceled, setIsRequestCanceled] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const { id, firstName, lastname } = useSelector(
    (state: RootState) => state.user,
  );
  const { requestPerDay, packageName } = useSelector(
    (state: RootState) => state.package,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const fetchedDocumentIds = new Set();
  const navigate = useNavigate();
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const calculateAge = (dob: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, new Date(dob));
  };

  // UseEffects
  useEffect(() => {
    fetchRequests();
  }, []);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchRequests = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const res = await client.post(`/request/${id}?page=${pageNumber}`, {
        Action: "Sent",
      });
      if (true) {
        const requestsData = res?.data?.result;
        const newRequests = requestsData.filter(
          (x: TUserRequest) => !fetchedDocumentIds.has(x._id),
        );
        setData((prevItems) => [...prevItems, ...newRequests]);
        requestsData.forEach((x: TUserRequest) => {
          fetchedDocumentIds.add(x._id);
        });
        setPageNumber((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    fetchRequests();
  };

  useEffect(() => {
    console.log("senddata", data);
  }, [data]);

  const sendNotification = async (notificationObj: TNotification) => {
    try {
      await client.post(
        "/notification/send",
        notificationObj,
        // { NotificationFrom: id, NotificationTo: id, Message: message, CreatedAt: new Date() },
      );
    } catch (error) {}
  };

  const handleRequests = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    requestToUserId: string,
  ) => {
    e.preventDefault();
    if (isRequestSent) return;
    try {
      const payload = {
        UserId: id,
        RequestToUserId: requestToUserId,
        Requests: requestPerDay,
      };
      const res = await client.put("/request/send", payload);
      if (true) {
        console.log("send request response", res);
        setIsRequestSent(true);
        setTimeout(() => {
          setIsRequestSent(false);
        }, 2500);
      }
      if (true) {
        sendNotification({
          Type: "SENT",
          NotificationFrom: id!,
          NotificationTo: requestToUserId,
          Message: `You have received a request from ${firstName} ${lastname}`,
          CreatedAt: new Date(),
        });
      }
    } catch (err: any) {
      if (err?.response?.status === 429) {
        toast.error(err?.response?.data?.error, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
    }
  };

  const handleCancelRequests = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    requestToUserId: string,
  ) => {
    if (isRequestCanceled) return;
    try {
      const res = await client.delete("/request/cancel", {
        data: { UserId: id, RequestUserId: requestToUserId },
      });
      if (true) {
        console.log("cancel request response", res);
        setIsRequestCanceled(true);
        setTimeout(() => {
          setIsRequestCanceled(false);
        }, 2500);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const openFullProfile = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    userData: any,
  ) => {
    //e.preventDefault();
    return;
    navigate("/profile/profile-details", {
      state: {
        data: userData,
      },
    });
  };

  if (isLoading)
    return (
      <>
        <center>
          <p>Loading matches...</p>
        </center>
      </>
    );

  return (
    <div>
      {data.map((x, index) => (
        <div key={index} className="mainTab">
          <div className="profileCard">
            <div className="profileImage">
              <img src={x.Photos} alt="" />
            </div>
            <div className="profileDetail">
              <div className="dflex justifyContentSpaceBetween">
                <div
                  className="aboutDetail"
                  onClick={(e) => openFullProfile(e, x)}
                >
                  <p className="username">
                    {" "}
                    {x.FirstName + " " + x.LastName}{" "}
                    {packageName && (
                      <ShowPackageDot packageName={x?.PackageName} />
                    )}{" "}
                  </p>
                  <ul>
                    <li>
                      <img src={SprofileLocation} alt="" /> {x.City},{x.State}
                    </li>
                    <li>
                      <img src={SprofileJobBC} alt="" /> {x.EducationName}
                    </li>
                    <li>
                      <img src={SprofileEducation} alt="" /> {x.WorkTitle} /
                      <span data-tooltip-id="my-tooltip-2">
                        {x.ExactIncome}
                      </span>
                      <ReactTooltip
                        style={{ zIndex: 1000 }}
                        id="my-tooltip-2"
                        place="bottom"
                        content={toWords.convert(x.ExactIncome)}
                      />
                    </li>
                  </ul>
                </div>
                <div className="likeAge">
                  <p className="Age"> Age {calculateAge(x.DOB)} </p>
                </div>
              </div>
              <div className="profileCardFtr ">
                <p onClick={(e) => handleRequests(e, x._id)}>
                  <img src={profileSendRequest} alt="" />
                  {isRequestSent ? "Request Sent" : "Send Request Again"}
                </p>
                <p onClick={(e) => handleCancelRequests(e, x._id)}>
                  <img src={profileNoIcon} alt="" />
                  {isRequestCanceled ? "Request Canceled" : "Cancel Request"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SentRequestCardCompo;
