import React, { useState, useRef, useCallback } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

type TVideoPreviewModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestDelete: () => void;
  videoBlob: Blob | null;
};

const VideoPreviewModal = ({
  isOpen,
  onRequestClose,
  onRequestDelete,
  videoBlob,
}: TVideoPreviewModalProps) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isReady, setIsReady] = useState(false);

  const onReady = useCallback(() => {
    if (playerRef.current && !isReady) {
      const timeToStart = 10 * 60; // 10 minutes
      playerRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  if (!isOpen || !videoBlob) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Video Preview"
    >
      <div className="videoPerviewModal">
        <div className="videoRecorderBox videoRecorderFooter">
          <p className="prsl-detail-heading">Video Preview:</p>
          {
            <center>
              <ReactPlayer
                ref={playerRef}
                onReady={onReady}
                controls={true}
                width="50%"
                height="50%"
                playsinline
                url={URL.createObjectURL(videoBlob)}
              />
            </center>
          }
          <div className="btnbox">
            <button onClick={onRequestClose}>Close</button>
            <button onClick={onRequestDelete}>Delete</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoPreviewModal;
