import { db } from "../../../services/firebase";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const handleAdd = async (receiverId: string, currentUser: any) => {
  const chatRef = collection(db, "chats"),
    userChatsRef = collection(db, "userchats"),
    usersRef = collection(db, "users");

  try {
    let q1 = query(usersRef, where("username", "==", receiverId)),
      querySnapshot1 = await getDocs(q1),
      receiverRefId = querySnapshot1.docs[0].data().id;

    // console.log("receiverRefId", receiverRefId);
    // console.log("currentUser.id", currentUser.user.uid);

    const userChatsRef1 = doc(db, "userchats", currentUser.user.uid),
      docSnap = await getDoc(userChatsRef1),
      userChats = docSnap.data()?.chats,
      user =
        userChats &&
        userChats.findIndex((item: any) => item.receiverId === receiverRefId);

    if (!user || user === -1) {
      const newChatRef = doc(chatRef);

      await setDoc(newChatRef, {
        createdAt: serverTimestamp(),
        messages: [],
      });

      await updateDoc(doc(userChatsRef, receiverRefId), {
        chats: arrayUnion({
          chatId: newChatRef.id,
          lastMessage: "",
          receiverId: currentUser.user.uid,
          updatedAt: Date.now(),
        }),
      });

      await updateDoc(doc(userChatsRef, currentUser.user.uid), {
        chats: arrayUnion({
          chatId: newChatRef.id,
          lastMessage: "",
          receiverId: receiverRefId,
          updatedAt: Date.now(),
        }),
      });
    }
  } catch (err) {
    console.log(err);
  }
};
