import { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setMusicState } from "../../state/features/musicSlice";

const AudioToggle = ({ showToggle, music }: any) => {
  const { isMusicPlaying } = useSelector((state: RootState) => state.music);
  const [isPlaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch();

  const toggleMusic = () => {
    setIsPlaying(!isPlaying);
    dispatch(setMusicState({ isMusicPlaying: !isPlaying }));
  };

  // useEffect(() => {
  //   //alert(window.location.pathname);
  //   const paths = window.location.pathname.split("/");
  //   if (paths[paths.length - 1] !== "myprofile") {
  //     setIsPlaying(!isPlaying);
  //   }
  // }, []);

  return (
    <div className="AudioToggleButton">
      <div>
        {showToggle && (
          <>
            <input
              type="checkbox"
              id="checkboxInput"
              defaultChecked={isPlaying}
              onClick={toggleMusic}
            />
            <label
              htmlFor="checkboxInput"
              className="toggleSwitch"
              title={isPlaying ? "Stop music" : "Play music"}
            >
              <div className="mute-speaker">
                <svg viewBox="0 0 75 75">
                  <path
                    d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"
                    style={{
                      stroke: "#fff",
                      strokeWidth: 5,
                      strokeLinejoin: "round",
                      fill: "#fff",
                    }}
                  />
                  <path
                    d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6"
                    style={{
                      fill: "none",
                      stroke: "#fff",
                      strokeWidth: 5,
                      strokeLinecap: "round",
                    }}
                  />
                </svg>
              </div>

              <div className="speaker">
                <svg viewBox="0 0 75 75" stroke="#fff" strokeWidth={5}>
                  <path
                    d="m39,14-17,15H6V48H22l17,15z"
                    fill="#fff"
                    strokeLinejoin="round"
                  />
                  <path
                    d="m49,26 20,24m0-24-20,24"
                    fill="#fff"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </label>
          </>
        )}
      </div>
      {isPlaying && (
        <ReactAudioPlayer src={music} autoPlay volume={0.9} loop={true} />
      )}
    </div>
  );
};

export default AudioToggle;
