import { useSelector } from "react-redux";
import axiosClient from "../../services/axiosInstance";
import { Dispatch, SetStateAction } from "react";
import { RootState } from "../../state/store";

export const fetchMoreVideos = async (
  currentVideoIndex: number,
  videos: any,
  videoPage: number,
  setVideos: Dispatch<SetStateAction<any>>,
  setVideoPage: Dispatch<SetStateAction<number>>,
) => {
  const client = axiosClient();

  if (currentVideoIndex + 2 >= videos.length) {
    try {
      const res = await client.get(`/klick?page=${videoPage}`);
      if (res.data?.result?.docs.length === 0) return;

      const newVideos = [...videos, ...res.data?.result?.docs];
      setVideos(newVideos);
      setVideoPage(videoPage + 1);
    } catch (error) {
      console.error("Error fetching more videos:", error);
    }
  }
};
