import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PencilLine from "../../../../src/assets/images/Pencil-Line.png";
import { TWorkProfile } from "../../../types/register";
import { TBasicProfileInfo, TProfileMessage } from "./ProfileBasicInfo";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../services/axiosInstance";
import { TUserState, setJob } from "../../../state/features/loginSlice";

const ProfileCareerInfo = ({ data }: TBasicProfileInfo) => {
  const [isEdit, setIsEdit] = useState(false);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const [msg, setMsg] = useState({} as TProfileMessage);
  const { id } = useSelector((state: RootState) => state.user);
  const [careerInfoData, setCareerInfoData] = useState<TWorkProfile>(
    {} as TWorkProfile,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) setCareerInfoData(data.EducationWork?.Work);
  }, [data.EducationWork?.Work]);

  const onEdit = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEdit(true);
  };

  const onHandleSubmit = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      if (!validateCareerInfo()) return;
      const res = await client.put("/user", {
        UserId: id,
        EducationWork: {
          Education: data.EducationWork.Education,
          Work: careerInfoData,
        },
      });
      if (true) {
        setMsg({ message: "Data saved successfully", isError: false });
        setTimeout(() => {
          setMsg({ message: "" });
        }, 3500);
        setIsEdit(false);
        const profileDataa: TUserState = {
          job: data.EducationWork.Work.Occupation,
        };
        dispatch(setJob(profileDataa));
      }
    } catch (err: any) {
    } finally {
    }
  };

  const validateCareerInfo = (): boolean => {
    if (careerInfoData.Occupation.length === 0) {
      setMsg({ message: "Invalid occupation", isError: true });
      return false;
    }
    if (careerInfoData.CompanyName?.length === 0) {
      setMsg({ message: "Invalid company name", isError: true });
      return false;
    }
    if (careerInfoData.WorkTitle?.length === 0) {
      setMsg({ message: "Invalid work title", isError: true });
      return false;
    }
    if (careerInfoData.ExactIncome <= 1000) {
      setMsg({ message: "Invalid income", isError: true });
      return false;
    }
    return true;
  };

  return (
    <div>
      <Accordion.Header>
        <div className="acdinNameBox dflex">
          <p className="acdinName"> Career Info </p>
          <div className="dflex alignItemCenter">
            {msg && msg.message && (
              <div className={msg.isError ? "error-msg" : "success-msg"}>
                <p>{msg.message}</p>
              </div>
            )}
            {!isEdit ? (
              <>
                <img src={PencilLine} alt="editIcon" />
                <p className="acdinedit" onClick={(e) => onEdit(e)}>
                  Edit
                </p>
              </>
            ) : (
              <p className="acdinedit" onClick={(e) => onHandleSubmit(e)}>
                Save
              </p>
            )}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <form className="MyprofileForm">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Occupation </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={careerInfoData?.Occupation}
                      onChange={(e) =>
                        setCareerInfoData((prev) => ({
                          ...prev,
                          Occupation: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <label> Company name </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={careerInfoData?.CompanyName}
                      onChange={(e) =>
                        setCareerInfoData((prev) => ({
                          ...prev,
                          CompanyName: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <label> Work title </label>
                    <input
                      disabled={!isEdit}
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      value={careerInfoData?.WorkTitle}
                      onChange={(e) =>
                        setCareerInfoData((prev) => ({
                          ...prev,
                          WorkTitle: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="grid3">
                  <div>
                    <label> Annual income </label>
                    <input
                      disabled={!isEdit}
                      type="number"
                      value={careerInfoData?.ExactIncome}
                      onChange={(e) =>
                        setCareerInfoData((prev) => ({
                          ...prev,
                          ExactIncome: +e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Accordion.Body>
    </div>
  );
};

export default ProfileCareerInfo;
