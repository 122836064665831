import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { RootState } from "../state/store";
import Footer from "./Footer";
import HeroSection from "./header/HeroSectionCompo";
import Header from "./header/HeaderWithLogin";
import MainHeader from "./header/HeaderWithoutLogin";
import { useEffect, useState } from "react";
import axiosClient from "../services/axiosInstance";
import Loader from "./accounts/Loader";
import { Link } from "react-router-dom";

export type TAboutUs = {
  Banner: string;
  Description_1: string;
  Description_2: string;
  Heading_1: string;
  Heading_2: string;
  Image_1: string;
  Image_2: string;
  Title: string;
  Title1: string;
  Title2: string;
  _id: string;
};

const AboutUs = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [about, setAbout] = useState<TAboutUs>({} as TAboutUs);
  const [isLoading, setIsLoading] = useState(false);
  const client = axiosClient();

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const res = await client.get("/admin/about");
        setAbout(res.data.result);
      } catch (err: any) {
        console.error("error in about us", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="AboutUsPage">
      <div className="LandingPageSection">
        {isLoggedIn ? <Header /> : <MainHeader />}
        <Helmet>
          <title>About Us | Premmilan</title>
        </Helmet>
        <HeroSection
          imgName={about?.Banner}
          Title1={about?.Title1}
          Title2={about?.Title2}
        />
      </div>

      <section className="aboutus">
        <div className="imgtextsctnmain">
          <div className="dflex sprHeading">
            <div className="container">
              <div className="row alignItemCenter">
                <div className="col-md-12">
                  <ul className="headerstyle">
                    <li className="headerstylefLi">
                      <p className="commanheading">{about?.Heading_1}</p>{" "}
                    </li>
                    <li className="headerstyleSLi">
                      <p className="headingLineStyle"> </p>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="imgtextbxtxt">
                  <p className="commandescription">{about?.Description_1}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="imgtextbximg">
                  <img className="" src={about?.Image_1} alt="imgtextbximg" />
                </div>
              </div>
            </div>
          </div>

          <div className="dflex sprHeading">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul className="headerstyle rowReverse">
                    <li className="headerstylefLi">
                      <p className="commanheading"> {about?.Heading_2} </p>{" "}
                    </li>
                    <li className="headerstyleSLi">
                      <p className="headingLineStyle"> </p>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="founderCeo">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="imgtextbximg">
                    <img className="" src={about?.Image_2} alt="imgtextbximg" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="imgtextbxtxt pl45">
                    <div className="commandescription">  
                      <p> {about?.Description_2}</p>
                      <Link className="button" to="/privacy-policy">Privacy Policy</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
