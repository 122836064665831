import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

type TMyComponentProps = {
  imgName: string;
  Title1?: string;
  Title2?: string;
};

const HeroSection = ({ imgName, Title1, Title2 }: TMyComponentProps) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  return (
    <div>
      <div className="heroSection">
        <section className="masthead">
          <div className="masthead__bg">
            <img className="side_panel_head_profile_img" src={imgName} />
          </div>
          <div className="masthead__p">
            <p> {Title1}</p>
            <p> {Title2} </p>
            <div className="heroRegistraionButton">
              {!isLoggedIn && <Link to="/register">Register</Link>}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroSection;
