import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { onAuthStateChanged } from "firebase/auth";
import Chat from "./Chat";
import ChatList from "./ChatList";
import "./myChat.css";
import { auth } from "../../../services/firebase";
import { useUserStore } from "../../../state/firebase/userFirehoseStore";
import { useChatStore } from "../../../state/firebase/chatFirehoseStore";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import NoSubsciption from "../../subscription/NoSubsciption";
import Loader from "../../accounts/Loader";
const fmt = require("indian-number-format");

const MyChat = () => {
  const { packageName } = useSelector((state: RootState) => state.package);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userCount, setUserCount] = useState<number>(0);
  const { fetchUserInfo } = useUserStore();
  const { chatId } = useChatStore();

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserInfo(user?.uid);
      }
    });

    return () => {
      unSub();
    };
  }, []);

  if (isLoading) return <Loader />;
  if (!packageName) return <NoSubsciption />;
  if (packageName.toLowerCase() !== "exclusive") return <NoSubsciption />;

  return (
    <div className="chatPage">
      <Helmet>
        <title>My Chat | Premmilan</title>
      </Helmet>
      <div className="chat-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="totalChats">
                {" "}
                {`Total ${fmt.format(userCount)} Chats`}{" "}
              </div>
            </div>
            <div className="col-md-4">
              <div className="list">
                <ChatList
                  setIsLoading={setIsLoading}
                  setUserCount={setUserCount}
                />
              </div>
            </div>
            <div className="col-md-8">
              {chatId && (
                <Chat isLoading={isLoading} setIsLoading={setIsLoading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyChat;
