//https://javascript.plainenglish.io/axios-a-simple-and-effective-way-to-make-api-calls-in-react-with-typescript-f0b1e7eebdc5

import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";

export type TContentType = null | "Multipart" | "EncodedURL";

function getContentType(ContentType: TContentType) {
  switch (ContentType) {
    case "Multipart":
      return "multipart/form-data";
    case "EncodedURL":
      return "application/x-www-form-urlencoded";
    default:
      return "application/json";
  }
}

const axiosClient = (
  token: string | null = null,
  ContentType: TContentType | null = null,
): AxiosInstance => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        "Content-Type": getContentType(ContentType),
      }
    : {
        "Content-Type": getContentType(ContentType),
      };

  // - baseURL: The base URL for the requests, which is the Dog API in this case
  // - headers: The headers object that you defined earlier
  // - timeout: The maximum time in milliseconds that the request can take
  // - withCredentials: A boolean that indicates whether to send cookies with the request
  const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers,
    timeout: 60000,
    withCredentials: true,
  });

  client.interceptors.request.use((config: any) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    config.headers = config.headers || {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      try {
        const { response } = error;
        if (response?.status === 401) {
          localStorage.removeItem("ACCESS_TOKEN");
        }
      } catch (e) {
        console.error(e);
      }
      throw error;
    },
  );

  return client;
};

export default axiosClient;
