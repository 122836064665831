import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../../../../assets/css/ImagePreviewModal.css";

type TPreviewImageModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  images: File[] | null;
  onRemove?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => void;
  onRemoveAll?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ImagePreviewModal = ({
  isOpen,
  onRequestClose,
  images,
  onRemove,
  onRemoveAll,
}: TPreviewImageModalProps) => {
  if (images?.length === 0) {
    onRequestClose();
    return;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Image Preview"
    >
      <div className="modal-content CustomModal">
        <button className="close-button" onClick={onRequestClose}>
          Close
        </button>
        <div className="image-container">
          {images &&
            Array.from(images)?.map((imageUrl, index) => (
              <div key={index} className="image-preview-container">
                {onRemove && (
                  <button
                    className="remove-button"
                    onClick={(e) => onRemove && onRemove(e, index)}
                  >
                    X
                  </button>
                )}
                <img
                  src={URL.createObjectURL(imageUrl)}
                  alt={`Preview ${index}`}
                />
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
