import React from "react";

type TMyComponentProps = {
  imgName: string;
  altName: string;
};

const AuthLeftBanner = ({ imgName, altName }: TMyComponentProps) => {
  return (
    <div className="login_left_sec">
      <img src={imgName} alt={altName} />
    </div>
  );
};

export default AuthLeftBanner;
