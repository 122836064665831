import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../../services/axiosInstance";
import BackArrow from "../../../assets/images/cardArrow.svg";
import { REPORT_USER_TITLE } from "../../../utils/constants";
import { RootState } from "../../../state/store";
import Loader from "../../accounts/Loader";

const ReportUser = () => {
  const client = axiosClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(REPORT_USER_TITLE[0]);
  const [description, setDescription] = useState("");
  const { id } = useSelector((state: RootState) => state.user);
  const [reportUser, setReportUser] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const data = location.state;
    if (!data?.id) return;
    setReportUser(data.id);
  }, []);

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onSubmit = async () => {
    if (!description) {
      setErrorMsg("Please write your report in detail");
      return false;
    }
    setIsLoading(true);
    try {
      const res = await client.post(`/user/report`, {
        UserId: id,
        ReportUserId: reportUser,
        Title: title,
        Description: description,
      });
      if (true) {
        setDescription("");
        setTitle("");
      }
    } catch (err: any) {
      console.log("error:", err);
    } finally {
      setIsLoading(false);
      setErrorMsg("");
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="hidedeletepage ReportUserPage">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div>
              <div className="setting-hide-delete">
                <Helmet>
                  <title>Report User | Premmilan</title>
                </Helmet>

                <div className="hideDeleteProfile" onClick={() => navigate(-1)}>
                  <img src={BackArrow} width="30" />
                  <div className="sign-in commanheading">
                    Report this person
                  </div>
                </div>

                <div className="div">
                  <div className="box1">
                    <div className="group-2">
                      <p className="text">
                        We want to protect our community and make sure you feel
                        safe. Don’t worry, your feedback is anonymous and they
                        wouldn't know that you’ve blocked or reported them.
                      </p>
                    </div>
                    <div className="group-4">
                      <div className="dflex justifyContentSpaceBetween alignItemCenter">
                        <div className="group-5">
                          {REPORT_USER_TITLE.map((titleText, index) => {
                            return (
                              <div>
                                <label>
                                  <input
                                    key={index}
                                    className="hideRadio"
                                    type="radio"
                                    name="week"
                                    value={titleText}
                                    onChange={onRadioChange}
                                    defaultChecked={index === 0}
                                  />
                                  <span>{titleText}</span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dividerLine"></div>

                  <div className="box2">
                    <div className="frame-3">
                      <div className="group-3 dflex justifyContentSpaceBetween alignItemCenter">
                        <div className="text-2">
                          <p className="smalltHeading"> Write your report </p>
                          <p className="p">
                            The more details you can give, the better we can
                            understand what’s happened.
                          </p>

                          <div className="textAreaBox">
                            <textarea
                              rows={4}
                              cols={80}
                              value={description}
                              placeholder="Tell us what happened... "
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                            {errorMsg && (
                              <div className="error-msg">
                                <i className="fa fa-exclamation-triangle"></i>
                                {errorMsg}
                              </div>
                            )}
                          </div>

                          <div className="button" onClick={onSubmit}>
                            Submit Report
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportUser;
