import { useLocation } from "react-router-dom";
import loading1icon from "  ../assets/images/loading1icon.svg";
import { Link } from "react-router-dom";

const SuccessScreen = () => {
  const { state } = useLocation();

  return (
    <div>
      <div className="fullbodyLoaderBgColor">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="justifyContentCenter dflex alignItemCenter vh100 mailIcon">
                {/* <img src={loading1icon} /> */}
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <p className="ftrText">
          {state && state.message}
          <Link to="/login">
            Continue <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SuccessScreen;
