import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/loginSlice";
import registerReducer from "./features/registerSlice";
import packageReducer from "./features/packageSlice";
import authReducer from "./features/authSlice";
import firebaseReducer from "./features/firebaseSlice";
import musicReducer from "./features/musicSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const combReducers = combineReducers({
  user: userReducer,
  register: registerReducer,
  package: packageReducer,
  auth: authReducer,
  firebase: firebaseReducer,
  music: musicReducer,
});

const persistedReducer = persistReducer(persistConfig, combReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development", // For disabling the redux on production
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
