import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import NotificationBox, {
  TNotification,
  TNotificationType,
} from "../components/NotificationBoxCompo";
import profileChatIcon from "../../../../src/assets/images/profileChatIcon.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./style.css";
import { useEffect, useState } from "react";
import { TProfileData, TRegisterInfo } from "../../../types/register";
import Loader from "../../accounts/Loader";
import axiosClient from "../../../services/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { calculateAge, randomString } from "../../../utils/helper";
import openArrow from "../../../assets/images/openArrowTT.png";
import { useLocation, useNavigate } from "react-router-dom";
import { TPhotoType } from "../myprofile/MyProfile";
import { handleAdd } from "../chat/AddUser";
import ShowPackageDot from "../components/ShowPackageDot";
import PackageInfoBox from "../../package/PackageInfoBox";
const commaNumber = require("indian-number-format");

interface Notification {
  Type: TNotificationType;
  NotificationFrom: string;
  NotificationTo: string;
  Message: string;
  CreatedAt: Date;
}

type TSliderPhotoType = {
  url: string;
};

const FullProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);
  const [chatStatus, setChatStatus] = useState(false);
  const [isPerContactRequested, setIsPerContactRequested] = useState(false);
  const [isGuaContactRequested, setIsGuaContactRequested] = useState(false);
  const [photos, setPhotos] = useState([] as TPhotoType[]);
  const [sliderPhotos, setsliderPhotos] = useState([] as TSliderPhotoType[]);
  const [data, setData] = useState<TProfileData>({} as TProfileData);
  const navigate = useNavigate();
  const {
    id,
    firstName,
    lastname,
    gender,
    motherTongue,
    nationality,
    dob,
    photo,
    stateName,
    city,
    height,
    education,
    job,
    bio,
    interest,
  } = useSelector((state: RootState) => state.user);
  const { state } = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.firebase);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const { packageName } = useSelector((state: RootState) => state.package);
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const isContactRequestVisible =
    packageName?.toUpperCase() === "PREMIUM" &&
      data?.PackageName?.toUpperCase() === "EXCLUSIVE"
      ? false
      : true;
  const [debouncedNotification, setDebouncedNotification] =
    useState<Notification | null>(null);

  const sendNotification = async (notificationObj: TNotification) => {
    try {
      await client.post(
        "/notification/send",
        notificationObj
        // { NotificationFrom: id, NotificationTo: id, Message: message, CreatedAt: new Date() },
      );
    } catch (error) { }
  };
  useEffect(() => {
    if (debouncedNotification) {
      sendNotification(debouncedNotification);
    }
  }, [debouncedNotification, sendNotification]);

  useEffect(() => {
    if (!packageName) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (data?.Photos) {
      const sliderArr: { url: string }[] = [];
      setPhotos(
        data?.Photos?.map((photo) => {
          sliderArr.push({ url: photo["75px"] });
          return {
            image: photo["50px"],
            id: photo._id,
          };
        })
      );
      setsliderPhotos(sliderArr);
    }
  }, [data && data?.Photos]);

  // close the slider on esc button code
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsPreviewModal(false);
      }
    };

    if (isPreviewModal) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPreviewModal]);

  useEffect(() => {
    const notification: Notification = {
      Type: "VIEWPROFILE",
      NotificationFrom: id!,
      NotificationTo: state?.data._id,
      Message: `${firstName} ${lastname} has viewed your profile`,
      CreatedAt: new Date(),
    };

    const timeoutId = setTimeout(() => {
      setDebouncedNotification(notification);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [id, state, firstName, lastname]);

  useEffect(() => {
    if (data?.Photos) {
      setPhotos(
        data?.Photos?.map((photo) => {
          return {
            image: photo["50px"],
            id: photo._id,
          };
        })
      );
    }
  }, [data && data?.Photos]);

  useEffect(() => {
    async function fetch() {
      //if (isLoading) return;
      console.log("profile id", state.data?._id);
      try {
        const res = await client.post(`/user/other/${state?.data._id}`, {
          RequestBy: id,
        });
        setData(res.data.result);
        setIsGuaContactRequested(
          res.data.result.GuardianRequest === "PENDING",
        );
        setIsPerContactRequested(
          res.data.result.PersonalRequest === "PENDING",
        );
      } catch (err: any) {
        console.error("Error while opening profile", err);
      } finally {
        //setIsLoading(false);
      }
    }

    async function checkBlockStatatus() {
      try {
        const checkRes = await client.post("/user/block/check", {
          UserId: id,
          OtherUserId: state?.data._id,
        });
        setIsBlocked(checkRes.data.result);
      } catch (err: any) {
        console.log(err);
      } finally {
      }
    }
    checkBlockStatatus();
    fetch();
  }, [state]);

  // Check the request accepted status
  useEffect(() => {
    async function checkAcceptedRequest() {
      //if (isLoading) return;
      try {
        const res = await client.post(`/request/accepted`, {
          UserId: id,
          RequestByUserId: state?.data._id,
        });
        setChatStatus(res.data.result);

      } catch (err: any) {
      } finally {
        setIsLoading(false);
      }
    }
    checkAcceptedRequest();
  }, []);

  const onOpenNumerology = () => {
    navigate("/profile/numerology-match", {});
  };

  const onOpenKundli = () => {
    navigate("/profile/kundli-match", { replace: false });
  };

  const handleBlockProfile = async () => {
    try {
      const res = await client.post(
        `/user/${isBlocked ? "unblock" : "block"}`,
        {
          UserId: id,
          BlockUserId: state?.data._id,
        }
      );
      setIsBlocked(!isBlocked);
    } catch (err: any) {
      console.log(err);
    } finally {
    }
  };

  const openChat = async () => {
    handleAdd(state?.data._id, currentUser);
    navigate("/profile/chat", { replace: false });
  };

  const onRequestContact = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    contactType: string
  ) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await client.put("/number_request/send", {
        RequestToUserId: data._id,
        UserId: id,
        Type: contactType,
      });
      if (contactType === "PERSONAL") setIsPerContactRequested(true);
      else setIsGuaContactRequested(true);
    } catch (err: any) {
      console.log("error while sending contact request", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="fullProfilePage">
      {isPreviewModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="profileSliderImage"
            style={{ position: "relative", width: "40%", maxWidth: "800px" }}
          >
            <Carousel
              showThumbs={false}
              infiniteLoop
              useKeyboardArrows
              autoPlay
              stopOnHover
              showArrows
              // dynamicHeight
              width="100%"
            >
              {sliderPhotos.map((image, index) => {
                return (
                  <div>
                    <img src={image.url} alt={`Slide ${index}`} />
                  </div>
                );
              })}
            </Carousel>
            <button
              onClick={() => setIsPreviewModal(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                color: "white",
                border: "none",
                cursor: "pointer",
                zIndex: 1001,
              }}
            >
              <span className="fa fa-times" style={{ fontSize: "24px" }}></span>
            </button>
          </div>
        </div>
      )}

      <Helmet>
        <title>Matches Profile | Premmilan</title>
      </Helmet>
      <section className="MyProfile">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="profileImgBox">
                <p className="tite">
                  {data?.Personal?.FirstName} {data?.Personal?.LastName} Details
                </p>

                <Swiper
                  className="mySwiper"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
                >
                  {photos &&
                    photos.map((photo, index) => (
                      <SwiperSlide key={randomString()}>
                        <div className="profileImgInrBox" key={index}>
                          <div className="ProfileOverlyDetail">
                            <div
                              className="contentNumber"
                              onClick={() => setIsPreviewModal(true)}
                            >
                              <span>{data?.Photos?.length} Photos</span>
                            </div>
                            <div className="dflex nameContent justifyContentBetween">
                              <div className="nameNAge">
                                <p className="userName">
                                  {data?.Personal?.FirstName}{" "}
                                  {data?.Personal?.LastName}{" "}
                                  {packageName && (
                                    <ShowPackageDot
                                      packageName={data?.PackageName}
                                    />
                                  )}
                                </p>
                                <p className="userAge">
                                  Age {calculateAge(data?.Personal?.Dob)}
                                </p>
                                <p>
                                  <button
                                    className="profileBlockButton"
                                    onClick={handleBlockProfile}
                                  >
                                    {isBlocked ? "Unblock" : "Block"}
                                  </button>
                                </p>
                              </div>
                              {chatStatus && (
                                <div className="addphotos_" onClick={openChat}>
                                  <img src={profileChatIcon} alt="" />
                                </div>
                              )}
                            </div>
                          </div>
                          <img src={photo.image} alt="" />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>

                <div className="MyprofileForm">
                  <div className="grid1">
                    <div>
                      <label> About </label>
                      <div>{data.Aboutme}</div>
                      <p className="mt10"> </p>
                      <label>
                        Profile managed by&nbsp;
                        {data.ProfileManagedBy
                          ? data.ProfileManagedBy
                          : "myself"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="MyprofileForm">
                  <div className="grid3">
                    <div>
                      <label> Date of birth </label>
                      <div>
                        {new Date(data.Personal?.Dob).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </div>
                    </div>
                    <div>
                      <label> Mother tongue </label>
                      <div> {data.Personal?.Mothertongue} </div>
                    </div>
                    <div>
                      <label> Nationality </label>
                      <div> {data.Personal?.Nationality} </div>
                    </div>
                  </div>

                  <div className="grid3">
                    <div>
                      <label> Weight (KG) </label>
                      <div> {data.Personal?.Weight} </div>
                    </div>
                    <div>
                      <label> Height (Inch) </label>
                      <div> {data.Personal?.Height} </div>
                    </div>
                    <div>
                      <label> Marital status </label>
                      <div> {data.Personal?.MaritalStatus} </div>
                    </div>
                  </div>

                  <div className="grid3">
                    <div>
                      <label> Religion </label>
                      <div> {data.Personal?.Religion} </div>
                    </div>
                    <div>
                      <label> Community </label>
                      <div> {data.Personal?.Community} </div>
                    </div>
                    <div></div>
                  </div>
                </div>

                <div className="MyprofileForm">
                  <div className="grid3">
                    <div>
                      <label> Highter education / Degree </label>
                      <div> {data.EducationWork?.Education.EducationName} </div>
                    </div>
                    <div>
                      <label> College / University </label>
                      <div> {data.EducationWork?.Education.CollegeName} </div>
                    </div>
                    <div>
                      <label> Passing year </label>
                      <div> {data.EducationWork?.Education.PassingYear} </div>
                    </div>
                  </div>
                </div>

                <div className="MyprofileForm">
                  <div className="grid3">
                    <div>
                      <label> Occupation </label>
                      <div> {data.EducationWork?.Work.Occupation} </div>
                    </div>
                    <div>
                      <label> Company name </label>
                      <div> {data.EducationWork?.Work.CompanyName} </div>
                    </div>
                    <div>
                      <label> Work title </label>
                      <div> {data.EducationWork?.Work.WorkTitle} </div>
                    </div>
                  </div>
                  <div className="grid3">
                    <div>
                      <label> Annual Income </label>
                      <div>
                        {" "}
                        {commaNumber.format(
                          data.EducationWork?.Work.ExactIncome
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="MyprofileForm">
                  <div className="grid3">
                    <div>
                      <label> Family type </label>
                      <div> {data.Family?.FamilyType} </div>
                    </div>
                    <div>
                      <label> Father’s status </label>
                      <div> {data.Family?.FatherStatus} </div>
                    </div>
                    <div>
                      <label> Mother’s status </label>
                      <div> {data.Family?.MotherStatus} </div>
                    </div>
                  </div>
                  <div className="grid3">
                    <div>
                      <label> No. of brothers </label>
                      <div> {data.Family?.TotalBrothers} </div>
                    </div>
                    <div>
                      <label> No. of sisters </label>
                      <div> {data.Family?.TotalSisters} </div>
                    </div>
                  </div>
                </div>

                {packageName && isContactRequestVisible && (
                  <div className="MyprofileForm">
                    <div className="grid3">
                      <>
                        <div>
                          <label> Personal contact </label>
                          <div>
                            <button
                              disabled={isPerContactRequested}
                              className="profileBlockButton"
                              onClick={(e) => onRequestContact(e, "PERSONAL")}
                            >
                              {data.PersonalRequest !== "ACCEPTED"
                                ? isPerContactRequested
                                  ? "Requested"
                                  : "Request"
                                : data.Personal.ContactNumber.toString()}
                            </button>{" "}
                          </div>
                        </div>
                        <div>
                          <label> Guardian contact </label>
                          <div>
                            <button
                              disabled={isGuaContactRequested}
                              className="profileBlockButton"
                              onClick={(e) => onRequestContact(e, "GUARDIAN")}
                            >
                              {data.GuardianRequest !== "ACCEPTED"
                                ? isGuaContactRequested
                                  ? "Requested"
                                  : "Request"
                                : data.Family.GuardianContact.toString()}
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                <div className="MyprofileForm">
                  <div className="grid1">
                    <div>
                      <label> Interest </label>
                      <div>
                        {data.Additional?.Interest?.map(
                          (interst) => interst + ", "
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="MyprofileForm_">
                  <div className="MyprofileForm">
                    <div className="barline dflex">
                      <p className="acdinName redText"> Expectations </p>
                      {/* <div> {data.Additional?.Expectation} </div> */}
                    </div>
                    <div className="grid1">
                      <div className="innerExtraPadding">
                        <div> {data.Additional?.Expectation} </div>
                      </div>
                    </div>
                  </div>

                  <div className="redirectBar">
                    <div
                      className="acdinNameBox dflex"
                      onClick={onOpenNumerology}
                    >
                      <p className="acdinName redText">
                        Numerology Matchmaking
                      </p>
                      <img src={openArrow} alt="" />
                    </div>
                  </div>

                  <div className="redirectBar">
                    <div className="acdinNameBox dflex" onClick={onOpenKundli}>
                      <p className="acdinName redText"> Kundli Matchmaking </p>
                      <img src={openArrow} alt="" />
                    </div>
                  </div>

                  <div className="MyprofileForm">
                    <div className="acdinNameBox">
                      <p className="acdinName redText bigHeading ">
                        Who is {data.Personal?.Gender === "Male" ? "he" : "she"}{" "}
                        looking for...
                      </p>
                      <p className="acdinSubName">
                        These are{" "}
                        {data.Personal?.Gender === "Male" ? "his" : "her"}{" "}
                        desired partner qualities
                      </p>
                      <div className="dividerLine"></div>
                    </div>
                    <div className="grid3">
                      <div className="box">
                        <div className="profileImgAndText">
                          <div className="imgBox">
                            <img
                              src={data?.Photos && data?.Photos[0]["25px"]}
                              alt=""
                            />
                          </div>
                          <div>
                            <h5>
                              {data.Personal?.Gender === "Male" ? "His" : "Her"}{" "}
                              Preferences
                            </h5>
                          </div>
                        </div>

                        <div className="MyprofileForm_ cusom">
                          <div className="grid1">
                            <div className="my20">
                              <label> Bio</label>
                              <div> {data.Aboutme}</div>
                            </div>

                            <div className="my20">
                              <label> Education </label>
                              <div>
                                {data.EducationWork?.Education?.EducationName}
                              </div>
                            </div>

                            <div className="my20">
                              <label> Interest </label>
                              <div>
                                {data.Additional?.Interest?.map(
                                  (interest) => interest + ", "
                                )}
                              </div>
                            </div>

                            <div className="my20">
                              <label> Job </label>
                              <div>{data.EducationWork?.Work?.Occupation}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="redText saysMatchPrecentange">
                        <div className="profileImgAndText">
                          <div className="imgBox">
                            <p> </p>
                          </div>
                        </div>
                      </div>
                      <div className="box">
                        <div className="profileImgAndText">
                          <div className="imgBox">
                            <img src={photo} alt="" />
                          </div>
                          <div>
                            <h5>Your Preferences</h5>
                          </div>
                        </div>
                        <div className="MyprofileForm_ cusom">
                          <div className="grid1">
                            <div className="my20">
                              <label> Bio </label>
                              <div> {bio}</div>
                            </div>

                            <div className="my20">
                              <label> Education </label>
                              <div> {education}</div>
                            </div>

                            <div className="my20">
                              <label> Interest </label>
                              <div> {interest}</div>
                            </div>

                            <div className="my20">
                              <label> Job </label>
                              <div> {job}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {!isMobile && <NotificationBox />}

              <div className="goExclusive">
                <PackageInfoBox />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FullProfile;
