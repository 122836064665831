import { useEffect, useState } from "react";
import axiosClient from "../services/axiosInstance";
import { TSetting } from "../types/landing";
import AudioToggle from "./header/AudioToggle";

const Footer = () => {
  const client = axiosClient();
  const [setting, setSettings] = useState<TSetting>({} as TSetting);

  useEffect(() => {
    async function fetch() {
      try {
        const resSetting = await client.get("/admin/settings");
        setSettings(resSetting.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
      }
    }
    fetch();
  }, []);
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ftrinrbx">
              <div className="ftrSclLinks">
                <ul>
                  <li>
                    <a href={setting?.Youtube}>
                      <img
                        className="ftrSclIcn"
                        src={require("../assets/images/youtube.png")}
                        alt="youtube_social"
                      />
                    </a>
                  </li>
                  <li>
                    <a href={setting?.Instagram}>
                      <img
                        className="ftrSclIcn"
                        src={require("../assets/images/insta_.png")}
                        alt="insta_social"
                      />
                    </a>
                  </li>
                  <li>
                    <a href={setting?.Facebook}>
                      <img
                        className="ftrSclIcn"
                        src={require("../assets/images/fb.png")}
                        alt="fb_social"
                      />
                    </a>
                  </li>
                  <li>
                    <a href={setting?.Twitter}>
                      <img
                        className="ftrSclIcn"
                        src={require("../assets/images/x.png")}
                        alt="x_social"
                      />
                    </a>
                  </li>
                  <li>
                    {/* <AudioToggle showToggle={true} music={setting.Music} /> */}
                  </li>
                </ul>
              </div>

              <p className="copyright">
                Copyright @ {new Date().getFullYear()} PremMilan. All rights
                reserved.
              </p>

              <p className="Passionately">
                {/* Passionately created by OLIX Group. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
