import axiosClient from "../../../services/axiosInstance";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Loader from "../../accounts/Loader";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../state/store";
import arrowRightPaymentPage from "../../../../src/assets/images/arrowRightPaymentPage.svg";
import BackArrow from "../../../../src/assets/images/cardArrow.svg";
import { TSubscription } from "../../subscription/Subscription";
import "./style.css";
import {
  TPackageState,
  setPackageData,
} from "../../../state/features/packageSlice";

export type TPayment = {
  ReferralName: string;
  ReferralNumber: string;
  CouponCode?: string;
  DiscountAmount: number;
  OriginalAmount: number;
  Amount: number;
};

const initialValue: TPayment = {
  ReferralName: "",
  ReferralNumber: "",
  CouponCode: "",
  DiscountAmount: 0,
  OriginalAmount: 0,
  Amount: 0,
};

const CouponPayment = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const navigate = useNavigate();
  const [isLoading] = useState(false);
  const { id, name } = useSelector((state: RootState) => state.user);
  const { packageId } = useSelector((state: RootState) => state.package);
  const { state } = useLocation();
  const [couponErr, setCouponErr] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [couponMsg, setCouponMsg] = useState<string | null>(null);
  const [packageInfo, setPackageInfo] = useState<TSubscription>(
    {} as TSubscription
  );
  const [payment, setPayment] = useState<TPayment>(initialValue);

  useEffect(() => {
    if (true) {
      setPackageInfo(state?.packageInfo);
    }
  }, [state]);

  useEffect(() => {
    if (!packageInfo) {
      navigate("/subscription");
    }
  }, [navigate, packageInfo]);

  useEffect(() => {
    if (couponMsg && couponMsg.length > 0) setCouponErr(null);
    if (couponErr && couponErr.length > 0) setCouponMsg(null);
  }, [couponErr, couponMsg]);

  const onValidateCouponCode = async () => {
    if (!payment.CouponCode) return;
    try {
      const res = await client.post("/payment/validate-coupon", {
        Code: payment.CouponCode,
        PackageId: packageInfo?._id,
      });
      if (true) {
        const amount =
          res.data.result.Type === "PERCENTAGE"
            ? Math.ceil(
                (packageInfo?.Amount! * res.data.result.DiscountAmount) / 100
              )
            : res.data.result.DiscountAmount;

        setPayment((prev) => ({
          ...prev,
          DiscountAmount: amount,
          Amount: packageInfo?.Amount! - amount,
        }));
        setCouponMsg("Coupon applied");
      }
    } catch (err: any) {
      setPayment((prev) => ({
        ...prev,
        DiscountAmount: 0,
      }));
      setCouponErr("Invalid coupon");
    } finally {
    }
  };

  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePaymentVerify = async (data: any) => {
    const reScript = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!reScript) {
      alert("Razropay failed to load!!");
      return;
    }

    const Razorpay = (window as any).Razorpay;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: data.currency,
      name: "Premmilan",
      description: "Payment Subscription",
      order_id: data.id,
      handler: async (response: any) => {
        console.log("response", response);
        try {
          const res = await client.post("payment/verify", {
            Razorpay_order_id: response.razorpay_order_id,
            Razorpay_payment_id: response.razorpay_payment_id,
            Razorpay_signature: response.razorpay_signature,
            UserId: id,
            PackageId: packageInfo?._id,
            Amount: packageInfo?.Amount! - payment.DiscountAmount,
            CouponCode: payment.CouponCode,
            ReferralName: "",
            ReferralNumber: "",
            PaymentType: "COUPON",
            OriginalAmount: packageInfo?.Amount,
            DiscountAmount: payment.DiscountAmount,
          });

          if (true) {
            const clientWithToken = axiosClient(token);
            const packageRes = await clientWithToken.get(`/user/package/${id}`);
            console.log("package purchaged", packageRes);
            const pkg: TPackageState = {
              expireDate: packageRes.data.result[0].ExpireAt,
              packageName: packageRes.data.result[0].Details.Name,
              requestPerDay: packageRes.data.result[0].Details.RequestPerDay,
              packageId: packageRes.data.result[0].PackageId,
            };
            dispatch(setPackageData(pkg));
            navigate("/profile/payment-success", { replace: true });
          }
        } catch (error: any) {
          console.log("failure", error);
        }
      },
      theme: {
        color: "#ff6929",
      },
      // image: "../../assets/images/logo.png"
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  };

  const onHandlePayment = async () => {
    try {
      if (!onValidateCouponCode()) return;
      const res = await client.post("payment/order", {
        Amount: +(packageInfo?.Amount! - payment.DiscountAmount),
      });
      if (true) {
        handlePaymentVerify(res.data.data);
      }
    } catch (err: any) {
    } finally {
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="NumerologyDetailsPage paymentPage">
      <Helmet>
        <title>Payment | Premmilan</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="backarrowbar" onClick={() => navigate(-1)}>
              <img alt="BackArrow" src={BackArrow} width="30" />
            </div>
          </div>

          <div className="col-md-12">
            <div className="innerBox">
              <div className="detail-title">
                <div className="reg-icon"></div>
              </div>
              <div className="prsl-detail">
                <div className="paymentBlockHeading">
                  <p> Order Summary </p>
                </div>
                <form id="msform">
                  <fieldset>
                    <div className="form-card order-summary">
                      <div className="reg-forms exclcvPrizeBox">
                        <div className="dflex justifyContentSpaceBetween alignItemCenter">
                          <div>
                            <span>
                              <p className="Blabel">{packageInfo?.Name}</p>
                              <p className="desclabel">
                                {" "}
                                {packageInfo?.Validity / 365} Year Validity{" "}
                              </p>
                            </span>
                          </div>
                          <div>
                            <span className="Prize textrightalign">
                              <p className="Blabel">₹{packageInfo?.Amount}</p>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div>
                          <div className="positionRelativeBox">
                            <img
                              src={arrowRightPaymentPage}
                              alt=""
                              onClick={onValidateCouponCode}
                            />
                            <input
                              type="text"
                              value={payment.CouponCode?.toUpperCase()}
                              onChange={(e) => {
                                setCouponErr(null);
                                setCouponMsg(null);
                                setPayment((prev) => ({
                                  ...prev,
                                  CouponCode: e.target.value as string,
                                }));
                              }}
                              placeholder="Coupon code"
                            />
                          </div>
                          {couponErr && (
                            <div className="error-msg">
                              <i className="fa fa-exclamation-triangle"></i>
                              {couponErr}
                            </div>
                          )}
                          {couponMsg && (
                            <div className="success-msg">{couponMsg}</div>
                          )}
                        </div>
                      </div>

                      <div className="divider dividerGap"></div>
                      <div className="dflex justifyContentSpaceBetween alignItemCenter">
                        <div>
                          <span>
                            <p className="Blabel">Subtotal</p>
                          </span>
                          <span>
                            <p className="Blabel coupounCode">Discount</p>
                          </span>
                        </div>
                        <div>
                          <span className="Prize textrightalign">
                            <p className="Blabel">₹{packageInfo?.Amount}</p>
                            <p className="Blabel">₹{payment.DiscountAmount}</p>
                          </span>
                        </div>
                      </div>

                      <div className="divider dividerGap"></div>

                      <div className="dflex justifyContentSpaceBetween alignItemCenter">
                        <div>
                          <span>
                            <p className="Blabel totalText">Total</p>
                            <p className="desclabel"> Inclusive tax 18% GST </p>
                          </span>
                        </div>
                        <div>
                          <span className="FinalPrize textrightalign">
                            <p>
                              ₹{packageInfo?.Amount! - payment.DiscountAmount}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="button" onClick={onHandlePayment}>
                      Pay
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponPayment;
