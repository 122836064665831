import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../services/axiosInstance";
import { TSetting } from "../../types/landing";
import AudioToggle from "./AudioToggle";

const HeaderWithoutLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const client = axiosClient();
  const [setting, setSettings] = useState<TSetting>({} as TSetting);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const resSetting = await client.get("/admin/settings");
        setSettings(resSetting.data.result);
      } catch (err: any) {
        console.log("error while fetching data", err);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        // You can adjust this threshold as needed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isLoading) return;

  return (
    <div className="mainHeader">
      {!window.location.pathname.includes("/klicks") && (
        <AudioToggle showToggle={false} />
      )}
      <header id="navbar-fixed" className={isNavbarFixed ? "scrolled" : ""}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg minus-x">
                <div className="container-fluid">
                  <Link to="/" className="navbar-brand">
                    <img
                      className="side_panel_head_profile_img"
                      src={setting?.Logo}
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <div className="mobileHidden"> </div>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 mobileHidden"></ul>
                    <div className="d-flex">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0 alignItemCenter">
                        <li className="nav-item">
                          <Link
                            to="/about-us"
                            className="nav-link"
                            aria-current="page"
                          >
                            About Us
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/happy-stories" className="nav-link">
                            Happy Stories
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/klicks" className="nav-link">
                            Klicks
                          </Link>
                        </li>
                        {/* <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            English
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a className="dropdown-item" href="#">
                                Hindi
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Punjabi
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Gujrati
                              </a>
                            </li>
                          </ul>
                        </li> */}
                        <li className="nav-item">
                          <Link to="/login" className="nav-link loggradientbtn">
                            Login
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderWithoutLogin;
