import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { TChangePassword } from "../../../types/auth";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import axiosClient from "../../../services/axiosInstance";
import logo from "../../../assets/images/logo.png";
import Loader from "../../accounts/Loader";
import AuthLeftBanner from "../../AuthLeftBanner_compo";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import PasswordStrengthBar from "react-password-strength-bar";
import { getAuth, updatePassword } from "firebase/auth";

type TShowPassword = {
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
};

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<string | null>();
  const auth = getAuth();
  const user = auth.currentUser;
  const [showPassword, setShowPassword] = useState<TShowPassword>({
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const { id } = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<TChangePassword>({
    defaultValues: {
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
    },
  });
  const { token } = useSelector((state: RootState) => state.auth);
  const client = axiosClient(token);
  const passwordWatch = watch("newPassword");

  const onhandleFormSubmit = async (data: TChangePassword) => {
    try {
      setIsLoading(true);
      setErr(null);
      const response = await client.post(`/auth/change-password`, {
        Id: id,
        OldPassword: data.oldPassword,
        Password: data.newPassword,
      });
      if (true) {
        updatePassword(user!, data.newPassword!).then(() => {
          // Password updated successfully
          console.log("Password updated successfully");
        });
        navigate("/passwordchangedsuccess", { replace: false });
        setTimeout(() => {
          navigate("/profile/settings", { replace: false });
        }, 2000);
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="login_form changePasswordForm">
      <Helmet>
        <title>Change Password | Premmilan</title>
      </Helmet>
      <AuthLeftBanner
        imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/7071307-login-banner.png"
        altName="Change Password"
      />
      <div className="login_right_sec">
        <div className="innr_frm">
          <div className="login-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="login-form">
            <p className="change-password">
              <h1>Change Password</h1>
            </p>
            <form onSubmit={handleSubmit(onhandleFormSubmit)}>
              <div className="password-container">
                <input
                  id="oldpassword"
                  {...register("oldPassword", {
                    required: "Enter your current password",
                  })}
                  placeholder="Current Password"
                  type={showPassword?.showCurrentPassword ? "text" : "password"}
                />
                <span
                  className={
                    showPassword?.showCurrentPassword
                      ? "fa fa-fw fa-eye field_icon toggle-password"
                      : "fa fa-fw fa-eye-slash field_icon toggle-password"
                  }
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      showCurrentPassword: !prev.showCurrentPassword,
                    }));
                  }}
                ></span>
              </div>
              {errors.oldPassword?.message && (
                <div className="error-msg">
                  <i className="fa fa-exclamation-triangle"></i>
                  {errors.oldPassword?.message}
                </div>
              )}
              <div className="password-container">
                <input
                  id="newpassword"
                  {...register("newPassword", {
                    required: true,
                    validate: (value) => {
                      if (value && value?.length! < 8)
                        return "Password must be of minimum 8 characters";
                      return true;
                    },
                  })}
                  placeholder="New Password"
                  type={showPassword?.showNewPassword ? "text" : "password"}
                />

                <span
                  className={
                    showPassword?.showNewPassword
                      ? "fa fa-fw fa-eye field_icon toggle-password"
                      : "fa fa-fw fa-eye-slash field_icon toggle-password"
                  }
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      showNewPassword: !prev.showNewPassword,
                    }));
                  }}
                ></span>
              </div>
              {errors.newPassword?.message && (
                <div className="error-msg">
                  <i className="fa fa-exclamation-triangle"></i>
                  {errors.newPassword?.message}
                </div>
              )}
              <div className="password-container">
                <input
                  id="confirm-password"
                  {...register("confirmPassword", {
                    required: "Password is required",
                    validate: (value: string | null) => {
                      if (passwordWatch !== null && passwordWatch !== value)
                        return "Password must be same!";
                    },
                  })}
                  placeholder="Confirm Password"
                  type={showPassword?.showConfirmPassword ? "text" : "password"}
                />
                <span
                  className={
                    showPassword?.showConfirmPassword
                      ? "fa fa-fw fa-eye field_icon toggle-password"
                      : "fa fa-fw fa-eye-slash field_icon toggle-password"
                  }
                  onClick={() => {
                    setShowPassword((prev) => ({
                      ...prev,
                      showConfirmPassword: !prev.showConfirmPassword,
                    }));
                  }}
                ></span>
              </div>
              {errors.confirmPassword?.message && (
                <div className="error-msg">
                  <i className="fa fa-exclamation-triangle"></i>
                  {errors.confirmPassword?.message}
                </div>
              )}
              {err && (
                <div className="error-msg">
                  <i className="fa fa-exclamation-triangle"></i>
                  {err}
                </div>
              )}
              {err && (
                <div className="error-msg">
                  <i className="fa fa-exclamation-triangle"></i>
                  {err}
                </div>
              )}
              {passwordWatch && (
                <PasswordStrengthBar password={passwordWatch!} />
              )}
              <button
                disabled={isSubmitting}
                type="submit"
                className="loginbtn"
              >
                Set New Password
              </button>
            </form>
            <div className="login-regstr">
              <Link to="/profile/settings">
                <span className="color">
                  <i className="fa-solid fa-arrow-left"></i> Back to Settings
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
