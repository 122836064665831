import { useState } from "react";
import { Helmet } from "react-helmet-async";
import axiosClient from "../../services/axiosInstance";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { TUserLogin } from "../../types/auth";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setUser, TUserState } from "../../state/features/loginSlice";
import {
  TPackageState,
  setPackageData,
} from "../../state/features/packageSlice";
import { RootState } from "../../state/store";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterofWord,
} from "../../utils/helper";
import Loader from "./Loader";
import AuthLeftBanner from "../AuthLeftBanner_compo";
import { useCookies } from "react-cookie";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";
import { setAuth } from "../../state/features/authSlice";

import { publicIpv4 } from "public-ip";
import { setFirebaseUser } from "../../state/features/firebaseSlice";

export type TJwtToken = {
  exp: number;
  sub: string;
};

const Login = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["pmtkn"]);
  const client = axiosClient();

  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TUserLogin>({
    // defaultValues: loginData,
  });

  const handleChatLogin = async (email: string, password: string) => {
    try {
      const currentUser = await signInWithEmailAndPassword(
        auth,
        email.toLowerCase(),
        password,
      );
      dispatch(setFirebaseUser({ currentUser }));
    } catch (err) {}
  };

  const onhandleFormSubmit = async (data: TUserLogin) => {
    setIsLoading(true);
    const ipv4 = (await publicIpv4()) || "";
    const userData = { ...data, ipAddress: ipv4 };

    try {
      setErr("");
      const res = await client.post(
        "/auth/login",
        capitalizeFirstLetter(userData),
      );

      if (true) {
        const token = res.data.jwt;
        await handleChatLogin(data.email, data.password);
        dispatch(setAuth({ token }));

        const user: TUserState = {
          id: res.data.Id,
          name: userData.email,
          isLoggedIn: true,
          status: res.data.Status,
          firstName: capitalizeFirstLetterofWord(res.data.FirstName),
          lastname: capitalizeFirstLetterofWord(res.data.LastName),
        };
        dispatch(setUser(user));
        const clientWithToken = axiosClient(token);
        try {
          const packageRes = await clientWithToken.get(
            `/user/package/${res.data.Id}`,
          );
          if (true) {
            const pkg: TPackageState = {
              expireDate: packageRes.data.result[0].ExpireAt,
              packageName: packageRes.data.result[0].Details.Name,
              requestPerDay: packageRes.data.result[0].Details.RequestPerDay,
              packageId: packageRes.data.result[0].PackageId,
            };
            dispatch(setPackageData(pkg));
          }
        } catch (err: any) {
          console.log("error while fetching package info");
        }
        navigate("/profile", { replace: true });
      }
    } catch (err: any) {
      if (err.response) {
        setErr(err.response?.data?.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="loginPage">
      <Helmet>
        <title>Login | Premmilan</title>
      </Helmet>
      {isLoggedIn && <Navigate to="/profile" />}
      <div className="login_form main-login">
        <AuthLeftBanner
          imgName="https://premmilan.s3.ap-south-1.amazonaws.com/utils/7071307-login-banner.png"
          altName="login"
        />
        <div className="login_right_sec">
          <div className="innr_frm">
            <div className="login-logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="login-form">
              <h1>Login</h1>
              <form onSubmit={handleSubmit(onhandleFormSubmit)}>
                <input
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                  })}
                  placeholder="Email address"
                  type="email"
                  autoFocus
                />
                {errors.email && (
                  <div className="error-msg">
                    <i className="fa fa-exclamation-triangle"></i>
                    {errors.email.message}
                  </div>
                )}
                <div>
                  <div className="password-container">
                    <input
                      id="password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                    />

                    <span
                      className={
                        showPassword
                          ? "fa fa-fw fa-eye field_icon toggle-password"
                          : "fa fa-fw fa-eye-slash field_icon toggle-password"
                      }
                      onClick={toggleShowPassword}
                    ></span>
                  </div>
                  {errors.password && (
                    <div className="error-msg">
                      <i className="fa fa-exclamation-triangle"></i>
                      {errors.password.message}
                    </div>
                  )}
                  {err && (
                    <div className="error-msg">
                      <i className="fa fa-exclamation-triangle"></i>
                      {err}
                    </div>
                  )}
                </div>

                <Link to="/forgotpassword">Forgot Password?</Link>
                <button
                  disabled={isSubmitting}
                  className="loginbtn"
                  type="submit"
                >
                  Login
                </button>
              </form>
            </div>
          </div>

          <div className="divider"></div>
          <div className="login-regstr">
            <p>
              New User?&nbsp;
              <Link to="/register">
                <span className="color">Register Free</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
